import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ProcedureHeader from './procedure/ProcedureHeader';
import ProcedureDescription from './procedure/ProcedureDescription';
// import ProcedureSteps from './procedure/ProcedureSteps';
import ProcedureFindings from './procedure/ProcedureFindings';
import ProcedureConclusion from './procedure/ProcedureConclusion';
// import ProcedureAssessment from './procedure/ProcedureAssessment';
import './ProcedureDetailsLeft.css';

export const ProcedureDetailsLeft = ({ 
    procedure, 
    findings,
    conclusion,
    assessment,
    onUpdateName,
    onUpdateDescription,
    onUpdateSteps,
    onUpdateResults,
    onUpdateConclusion,
    onUpdateAssessment,
    onCopilotClick,
    updating
}) => {
    const [editingFields, setEditingFields] = useState({
        name: false,
        description: false,
        steps: false,
        results: false,
        conclusion: false,
        assessment: false
    });

    const [editedValues, setEditedValues] = useState({
        name: procedure.name,
        description: procedure.description,
        steps: procedure.steps,
        results: procedure.results || '',
        conclusion: procedure.conclusion || '',
        assessment: procedure.assessment || ''
    });

    // Keeping all existing state management functions
    const startEditing = (field) => {
        setEditingFields(prev => ({
            ...prev,
            [field]: true
        }));
    };

    const cancelEditing = (field) => {
        setEditingFields(prev => ({
            ...prev,
            [field]: false
        }));
        setEditedValues(prev => ({
            ...prev,
            [field]: procedure[field] || ''
        }));
    };

    const handleSave = async (field) => {
        let success = false;
        const newValue = editedValues[field];
        
        switch(field) {
            case 'name':
                success = await onUpdateName(newValue);
                break;
            case 'description':
                success = await onUpdateDescription(newValue);
                break;
            case 'steps':
                success = await onUpdateSteps(newValue);
                break;
            case 'results':
                success = await onUpdateResults(newValue);
                break;
            case 'conclusion':
                success = await onUpdateConclusion(newValue);
                break;
            case 'assessment':
                success = await onUpdateAssessment(newValue);
                break;
            default:
                break;
        }

        if (success) {
            setEditingFields(prev => ({
                ...prev,
                [field]: false
            }));
        }
    };

    const handleChange = (field, value) => {
        setEditedValues(prev => ({
            ...prev,
            [field]: value
        }));
    };
    // eslint-disable-next-line
    {/*
    const handleStepChange = (index, value) => {
        const newSteps = [...editedValues.steps];
        newSteps[index] = value;
        handleChange('steps', newSteps);
    };
    */}

    const handleCopyResults = () => {
        navigator.clipboard.writeText(procedure.results || 'No results available yet.')
            .then(() => alert('Results copied to clipboard!'))
            .catch(err => {
                console.error('Failed to copy results:', err);
                alert('Failed to copy results. Please try again.');
            });
    };

    return (
        <div className="procedure-details p-4">
            <ProcedureHeader 
                procedure={procedure}
                isEditing={editingFields.name}
                editedName={editedValues.name}
                onStartEditing={() => startEditing('name')}
                onSave={() => handleSave('name')}
                onCancel={() => cancelEditing('name')}
                onChange={(e) => handleChange('name', e.target.value)}
                onCopilotClick={onCopilotClick}
                updating={updating}
            />

            <Tabs defaultActiveKey="overview" className="mb-4 mt-4">
                <Tab eventKey="overview" title="Overview" className="pt-1">
                    {procedure.assertions && procedure.assertions.length > 0 && (
                        <div className="assertions-section mb-4">
                            <div className="assertions-container d-flex flex-wrap gap-2">
                                {procedure.assertions.map((assertion) => (
                                    <span key={assertion.id} className="assertion-badge">
                                        <i className="bi bi-tag"></i>
                                        {assertion.title}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}

                    <ProcedureDescription 
                        description={procedure.description}
                        isEditing={editingFields.description}
                        editedDescription={editedValues.description}
                        onStartEditing={() => startEditing('description')}
                        onSave={() => handleSave('description')}
                        onCancel={() => cancelEditing('description')}
                        onChange={(e) => handleChange('description', e.target.value)}
                        updating={updating}
                    />
                    {/*
                    <ProcedureSteps 
                        steps={procedure.steps}
                        isEditing={editingFields.steps}
                        editedSteps={editedValues.steps}
                        onStartEditing={() => startEditing('steps')}
                        onSave={() => handleSave('steps')}
                        onCancel={() => cancelEditing('steps')}
                        onStepChange={handleStepChange}
                        updating={updating}
                    />
                    */}
                    {procedure.sampling && procedure.sampling.applicable && (
                        <section className="sampling-section mt-4">
                            <h2 className="section-title">Sampling Information</h2>
                            <div className="card">
                                <div className="card-body">
                                    <p><strong>Method:</strong> {procedure.sampling.method}</p>
                                    {procedure.sampling.sampleSize && (
                                        <p><strong>Sample Size:</strong> {procedure.sampling.sampleSize}</p>
                                    )}
                                    {procedure.sampling.selectionCriteria && (
                                        <p><strong>Selection Criteria:</strong> {procedure.sampling.selectionCriteria}</p>
                                    )}
                                </div>
                            </div>
                        </section>
                    )}
                </Tab>

                <Tab eventKey="findings" title="Results & Assessment" className="pt-1">
                    <div className="findings-container">
                        {/*
                        <div className="findings-header mb-4">
                            <ProcedureAssessment 
                                assessment={assessment}
                                onUpdateAssessment={onUpdateAssessment}
                                status={procedure.status}
                                updating={updating}
                            />
                        </div>
                        */}

                        <div className="findings-content">
                            <ProcedureFindings 
                                results={findings}
                                isEditing={editingFields.results}
                                editedResults={editedValues.results}
                                onStartEditing={() => startEditing('results')}
                                onSave={() => handleSave('results')}
                                onCancel={() => cancelEditing('results')}
                                onChange={(e) => handleChange('results', e.target.value)}
                                onCopy={handleCopyResults}
                                status={procedure.status}
                                updating={updating}
                            />

                            <ProcedureConclusion 
                                conclusion={conclusion}
                                isEditing={editingFields.conclusion}
                                editedConclusion={editedValues.conclusion}
                                onStartEditing={() => startEditing('conclusion')}
                                onSave={() => handleSave('conclusion')}
                                onCancel={() => cancelEditing('conclusion')}
                                onChange={(e) => handleChange('conclusion', e.target.value)}
                                status={procedure.status}
                                updating={updating}
                            />
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
};

export default ProcedureDetailsLeft;