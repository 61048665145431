import React, { useState, useRef, useEffect, useContext } from 'react';
import Discussion from './Discussion';
import Search from './Search';
import GenericModal from '../../general/GenericModal';
import InitChat from './InitChat';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';
import { useUser } from '../../general/UserProvider';
import { useFolders } from '../../hooks/documents/useFolders';
import { DataRoomContext } from '../DisplayDataRoom';
import { useFetchDiscussion } from '../../hooks/assistant/useFetchDiscussion';
import { useFetchQuotaRemaining } from '../../hooks/assistant/useFetchQuotaRemaining';
import './Assistant.css';


function Assistant({ sessionId, updateSessionId }) {
    const { token } = useUser();
    const emptyDiscussionContent = [];
    const dataroom_id = useContext(DataRoomContext);
    const { discussion } = useFetchDiscussion(token, dataroom_id, sessionId);
    const { quotaRemaining, updateQuotaRemaining } = useFetchQuotaRemaining(token, dataroom_id);
    const sseRef = useRef(null); 
    const [isLoading, setIsLoading] = useState(false);
    const [socialUrl, setSocialUrl] = useState({
        slack_url: '',
        teams_url: ''
    });
    const { folders } = useFolders(token, dataroom_id, true, null, 'note', 'Create');

    const [modalProperties, setModalProperties] = useState({
        title: "Error",
        content: "Oops, I can see an error.",
        isOpen: false,
        isFullscreen: false,
        isNoPadding: false,
        isCentered: true
    });

    // eslint-disable-next-line no-unused-vars
    const [_currentMessageContent, setCurrentMessageContent] = useState('');
    const [discussionContent, setDiscussionContent] = useState(emptyDiscussionContent);


    const handleResetChat = () => {
        setDiscussionContent(emptyDiscussionContent);
        updateSessionId();
    };

    useEffect(() => {
        if (discussion.length > 0) {
            setDiscussionContent(discussion);
        }
    }, [discussion]);

    useEffect(() => {
        return () => {
            if (sseRef.current) {
                sseRef.current.close();
            }
        };
    }, []);

    useEffect(() => {
        if (!token) return;
        
        api.get(API_ROUTES.SOCIAL_BY_DATAROOM(dataroom_id))
            .then(response => {
                setSocialUrl(response.data); 
            })
            .catch(error => {
                console.error("There was an error fetching the data:", error);
            });
    }, [dataroom_id, token]); 

    const handleAddToDiscussion = async (content, folderId) => {
        setIsLoading(true); 
    
        const initialMessages = [
            {
                side: 'bubbleRight',
                text: content,
                sources: [],
                isLoaded: false
            },
            {
                side: 'bubbleLeft',
                text: `<div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>`,
                sources: [],
            }
        ];
        setDiscussionContent(prevContent => [...prevContent, ...initialMessages]);
        setCurrentMessageContent('');
        updateQuotaRemaining();
    
        const createSourceObject = (data) => {
            return {
                sourceFileBaseName: data.original_filename || "default",
                sourcePDFURL: data.presigned_url || "default",
                sourceSection: data.section_title || "default",
                sourceSectionURL: data.original_filename,
                sourceSectionNumber: data.section_number || "default",
                sourceDate: data.date || "default",
                sourceFileType: data.file_type || "default",
                sourceNbSections: data.nb_sections || "default",
                sourceURL: data.URL || "default"
            };
        };
    
        const sseUrl = `${process.env.REACT_APP_API_URL}/prompt_stream?dataroom_id=${dataroom_id}&folderId=${folderId}&prompt=${encodeURIComponent(content)}&sessionId=${sessionId}&token=${encodeURIComponent(token)}`;

        if (sseRef.current) {
            sseRef.current.close(); 
        }
    
        sseRef.current = new EventSource(sseUrl);
        sseRef.current.onmessage = function(event) {
            const result = JSON.parse(event.data);
    
            if (result.source_info) {
                setDiscussionContent(prevDiscussionContent => {
                    const updatedDiscussionContent = [...prevDiscussionContent];
                    updatedDiscussionContent[updatedDiscussionContent.length - 1].sources.push(createSourceObject(result.source_info));
                    return updatedDiscussionContent;
                });
                return;
            }
    
            if (result.content === "END_OF_STREAM") {
                setDiscussionContent(prevContent => {
                    const updatedContent = [...prevContent];
                    updatedContent[updatedContent.length - 1].isLoaded = true;
                    return updatedContent;
                });
                sseRef.current.close(); 
                setIsLoading(false);
                return;
            }
    
            if (result.error) {
                setDiscussionContent(prevContent => {
                    const updatedContent = [...prevContent];
                    updatedContent[updatedContent.length - 1].text += `\n${result.error}`;
                    return updatedContent;
                });
                return;
            }
    
            if (result.content) {
                setCurrentMessageContent(prevContent => {
                    const updatedMessageContent = prevContent + result.content;
                    setDiscussionContent(prevDiscussionContent => {
                        const updatedDiscussionContent = [...prevDiscussionContent];
                        updatedDiscussionContent[updatedDiscussionContent.length - 1].text = updatedMessageContent;
                        return updatedDiscussionContent;
                    });
                    return updatedMessageContent;
                });
                
            }
        };
    
        sseRef.current.onerror = function(error) {
            console.error("SSE error:", error);
            if (sseRef.current) {
                sseRef.current.close();
            }
            setDiscussionContent(prevContent => {
                const updatedContent = [...prevContent];
                updatedContent[updatedContent.length - 1].text = "Oops, an error occurred. Please contact an admin.";
                updatedContent[updatedContent.length - 1].isLoaded = false; 
                return updatedContent;
            });
            setIsLoading(false);
        };
    };
    

    return (
        <div className="assistant-wrapper">
            <div className="assistant-content">
                {discussionContent && discussionContent.length > 0 ? (
                    <Discussion 
                        content={discussionContent} 
                        social_url={socialUrl}    
                        folders={folders} 
                        setModalProperties={setModalProperties}
                    />
                ) : (
                    <InitChat />
                )}
            </div>
            <Search 
                onAddToDiscussion={handleAddToDiscussion} 
                isLoading={isLoading}
                onResetChat={handleResetChat} 
                quota={quotaRemaining}
            />
            <GenericModal
                title={modalProperties.title}
                show={modalProperties.isOpen}
                onHide={() => setModalProperties(prevProps => ({ ...prevProps, isOpen: false }))}
                fullscreen={modalProperties.isFullscreen}
                centered={modalProperties.isCentered}
                no_padding={modalProperties.isNoPadding}
            >
                {modalProperties.content}
            </GenericModal>
        </div>
    );
}

export default Assistant;
