import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Container, Alert } from 'react-bootstrap';

const DocAttributeEditModal = ({ 
  show, 
  handleClose, 
  attribute, 
  documentTypes,
  onSave, 
  isProcessing, 
  error 
}) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    data_type: 'STRING',
    document_type_id: ''
  });

  useEffect(() => {
    if (attribute) {
      setFormData({
        name: attribute.name || '',
        description: attribute.description || '',
        data_type: attribute.data_type || 'STRING',
        document_type_id: attribute.document_types[0]?.id || ''
      });
    } else {
      setFormData({
        name: '',
        description: '',
        data_type: 'STRING',
        document_type_id: ''
      });
    }
  }, [attribute]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const isFormValid = () => {
    return formData.name.trim() !== '' && 
           formData.description.trim() !== '' && 
           formData.data_type.trim() !== '' &&
           formData.document_type_id !== '';
  };

  const dataTypes = ['STRING', 'NUMBER', 'DATE', 'BOOLEAN'];

  return (
    <Modal show={show} onHide={handleClose} size="lg" className="edit-modal" centered>
      <Modal.Header>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs="auto">
              <Button variant="link" onClick={handleClose} className="p-0 text-dark">
                <i className="bi bi-x-lg"></i>
              </Button>
            </Col>
            <Col>
              <Modal.Title>
                {attribute ? 'Edit Document Attribute' : 'Create Document Attribute'}
              </Modal.Title>
            </Col>
            <Col xs="auto">
              <Button 
                variant="primary" 
                onClick={handleSubmit} 
                disabled={isProcessing || !isFormValid()}
              >
                {isProcessing ? 'Processing...' : (attribute ? 'Save Changes' : 'Create Attribute')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <h2 className="title-h2 font-title mx-3">Attribute Details</h2>
            <div className="px-4">
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="e.g., Contract Number"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="description"
                  as="textarea"
                  rows={3}
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Describe the purpose of this attribute"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Data Type</Form.Label>
                <Form.Select
                  name="data_type"
                  value={formData.data_type}
                  onChange={handleChange}
                  required
                >
                  {dataTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Associated Document Type</Form.Label>
                <Form.Select
                  name="document_type_id"
                  value={formData.document_type_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a document type</option>
                  {documentTypes.map(type => (
                    <option key={type.id} value={type.id}>{type.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default DocAttributeEditModal;