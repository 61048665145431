// workflow/overview/auditDocumentsConfig.js

export const documentDependencies = {
    balanceSheet: {
      requiredDocs: ['fec'],
      description: "The balance sheet can be generated from the current year's FEC",
      periodConstraints: "currentYear"
    },
    incomeStatement: {
      requiredDocs: ['fec'],
      description: "The income statement requires the FEC from both current and previous year for comparison",
      periodConstraints: "currentAndPreviousYear"
    },
    subsidiaryLedgers: {
      requiredDocs: ['fec'],
      description: "Subsidiary ledgers can be directly extracted from the FEC",
      periodConstraints: "currentYear"
    }
  };
  
  export const documentCategories = [
    {
      title: "Audit Planning Documents",
      description: "Essential documents for audit planning and confirmation",
      documents: [
        {
          name: "Engagement Plan",
          key: 'auditPlan',
          folder: 18,
          description: "Complete audit strategy and approach"
        },
        {
          name: "Engagement Letter",
          key: 'engagementLetter',
          folder: 18,
          description: "Engagement letter from the auditor to the client"
        }
      ]
    },
    {
      title: "Financial Documents",
      description: "Core financial records and statements",
      documents: [
        {
          name: "FEC File",
          key: 'fec',
          folder: 17,
          description: "Fichier des Écritures Comptables - Standard accounting entries format"
        },
        {
          name: "Balance Sheet",
          key: 'balanceSheet',
          folder: 16,
          description: "Statement of financial position",
          canGenerate: true
        },
        {
          name: "Subsidiary Ledgers",
          key: 'subsidiaryLedgers',
          folder: 16,
          description: "Detailed auxiliary account balances",
          canGenerate: true
        },
        {
          name: "Income Statement",
          key: 'incomeStatement',
          folder: 16,
          description: "Statement of profit and loss",
          canGenerate: true
        }
      ]
    }
  ];