import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddDataroomControl = (token) => {
    const [adding, setAdding] = useState(false);
    const [addError, setAddError] = useState(null);

    const addControl = async (dataroomId, controlData) => {
        setAdding(true);
        setAddError(null);

        try {
            const response = await api.post(
                API_ROUTES.ADMIN_ADD_DATAROOM_CONTROL(dataroomId),
                controlData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setAdding(false);
            return response.data.control;
        } catch (error) {
            setAddError(error.response?.data?.message || 'An error occurred while adding the control');
            setAdding(false);
            throw error;
        }
    };

    return { addControl, adding, addError };
};

export const useUpdateDataroomControl = (token) => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const updateControl = async (dataroomId, controlId, controlData) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            const response = await api.put(
                API_ROUTES.ADMIN_UPDATE_DATAROOM_CONTROL(dataroomId, controlId),
                controlData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setUpdating(false);
            return response.data.control;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the control');
            setUpdating(false);
            throw error;
        }
    };

    return { updateControl, updating, updateError };
};

export const useDeleteDataroomControl = (token) => {
    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const deleteControl = async (dataroomId, controlId) => {
        setDeleting(true);
        setDeleteError(null);

        try {
            await api.delete(
                API_ROUTES.ADMIN_DELETE_DATAROOM_CONTROL(dataroomId, controlId),
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setDeleting(false);
        } catch (error) {
            setDeleteError(error.response?.data?.message || 'An error occurred while deleting the control');
            setDeleting(false);
            throw error;
        }
    };

    return { deleteControl, deleting, deleteError };
};

export const useFetchDataroomControls = (token, dataroomId) => {
    const [controls, setControls] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchControls = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await api.get(
                API_ROUTES.ADMIN_DATAROOM_CONTROLS(dataroomId),
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setControls(response.data);
            setLoading(false);
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while fetching controls');
            setLoading(false);
        }
    }, [token, dataroomId]);

    useEffect(() => {
        fetchControls();
    }, [fetchControls]);

    return { controls, setControls, loading, error, refetch: fetchControls };
};

export const useInitializeDataroomControls = (token) => {
    const [initializing, setInitializing] = useState(false);
    const [initializeError, setInitializeError] = useState(null);

    const initializeControls = async (dataroomId) => {
        setInitializing(true);
        setInitializeError(null);

        try {
            const response = await api.post(
                API_ROUTES.ADMIN_INITIALIZE_DATAROOM_CONTROLS(dataroomId),
                {},
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setInitializing(false);
            return response.data;
        } catch (error) {
            setInitializeError(error.response?.data?.message || 'An error occurred while initializing controls');
            setInitializing(false);
            throw error;
        }
    };

    return { initializeControls, initializing, initializeError };
};