import React, { useContext, useState } from 'react';
import { Table } from 'react-bootstrap';
import LayoutPageHeader from '../../general/LayoutPageHeader';
import { DataRoomContext } from '../DisplayDataRoom';
import { useFetchControlPoints } from '../../hooks/controlpoints/useFetchControlPoints';
import { useUser } from '../../general/UserProvider';
import TreeView from './TreeView';
import ModalContent from './ModalContent';
import CustomFilesViewer from '../../general/CustomFilesViewer';
import GenericModal from '../../general/GenericModal';
import './Controlpoints.css';

function Workflow() {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);
    const { controlPoints, loading, error } = useFetchControlPoints(token, dataroom_id);

    const [detailsModalProperties, setDetailsModalProperties] = useState({
        title: "",
        content: null,
        isOpen: false,
        isFullscreen: false,
        isNoPadding: false,
        isCentered: true
    });

    const [fileModalProperties, setFileModalProperties] = useState({
        title: "",
        content: null,
        isOpen: false,
        isFullscreen: true,
        isNoPadding: true,
        isCentered: false
    });

    const handleContentClick = (point) => {
        const handleFileOpen = (fileUrl, page) => {
          setFileModalProperties({
            title: `File Viewer`,
            content: <CustomFilesViewer fileurls={[fileUrl]} initialPage={page} />,
            isOpen: true,
            isFullscreen: true,
            isNoPadding: true,
            isCentered: false
          });
        };
    
        setDetailsModalProperties({
          title: (
            <>
              <i className="bi bi-info-circle me-2"></i>
              Control Point Details
            </>
          ),
          content: <ModalContent point={point} onFileOpen={handleFileOpen} />,
          isOpen: true,
          isFullscreen: false,
          isNoPadding: false,
          isCentered: true
        });
      };

    const closeDetailsModal = () => setDetailsModalProperties(prev => ({ ...prev, isOpen: false }));
    const closeFileModal = () => setFileModalProperties(prev => ({ ...prev, isOpen: false }));

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center mt-3">
                    <div className="loading-spinner">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            );
        }

        if (error) return <div className="text-center mt-3 text-danger">Error loading data: {error}</div>;
        if (!controlPoints || controlPoints.length === 0) return <div className="text-center mt-3">No control points available.</div>;

        return (
            <div className="tree-container">
                <Table responsive className="tree-table">
                    <tbody>
                        <TreeView 
                            data={controlPoints} 
                            onContentClick={handleContentClick}
                        />
                    </tbody>
                </Table>
            </div>
        );
    };

    return (
        <LayoutPageHeader title="Control Points">
            {renderContent()}

            {/* Modal for Details */}
            <GenericModal 
                show={detailsModalProperties.isOpen}
                onHide={closeDetailsModal}
                title={detailsModalProperties.title}
                fullscreen={detailsModalProperties.isFullscreen}
                centered={detailsModalProperties.isCentered}
                no_padding={detailsModalProperties.isNoPadding}
            >
                {detailsModalProperties.content}
            </GenericModal>

            {/* Modal for File Viewer */}
            <GenericModal 
                show={fileModalProperties.isOpen}
                onHide={closeFileModal}
                title={fileModalProperties.title}
                fullscreen={fileModalProperties.isFullscreen}
                centered={fileModalProperties.isCentered}
                no_padding={fileModalProperties.isNoPadding}
            >
                {fileModalProperties.content}
            </GenericModal>
        </LayoutPageHeader>
    );
}

export default Workflow;