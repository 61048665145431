import React from 'react';
import './LayoutPageHeader.css';

function LayoutPageHeader({ title, actions, children }) {
  return (
    <div className="page-wrapper">
      <div className="page-header">
        <h1 className="page-title">{title}</h1>
        {actions && <div className="page-actions">{actions}</div>}
      </div>
      <div className="page-content">
        {children}
      </div>
    </div>
  );
}

export default LayoutPageHeader;