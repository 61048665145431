import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Modal, Button, Form, Spinner, Alert, Container, Row, Col, Table, Badge } from 'react-bootstrap';
import { 
  useFetchDataroomCycles,
  useUpdateDataroomCycle,
  useDeleteDataroomCycle
} from '../../../../hooks/admin/dataroomCyclesHooks';

const getStatusVariant = (status) => {
  switch (status) {
    case 'Not Started': return 'secondary';
    case 'In Progress': return 'warning';
    case 'Completed': return 'success';
    default: return 'info';
  }
};

const DataroomCycleEditModal = ({ show, handleClose, dataroom, token }) => {
  const { cycles, loading, error, refetch: fetchCycles } = useFetchDataroomCycles(token, dataroom?.dataroom_id);
  const { updateCycle, updating: updatingCycle } = useUpdateDataroomCycle(token);
  const { deleteCycle, deleting: deletingCycle } = useDeleteDataroomCycle(token);

  const [selectedCycle, setSelectedCycle] = useState(null);
  const [filter, setFilter] = useState('');
  const [editForm, setEditForm] = useState({ 
    status: '', 
    auditor_id: '', 
    supervisor_id: '',
    risks: []
  });

  const statusOptions = useMemo(() => [
    { value: 'Not Started', label: 'Not Started' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Completed', label: 'Completed' },
  ], []);

  useEffect(() => {
    if (show && dataroom?.dataroom_id) {
      fetchCycles();
    }
  }, [show, dataroom?.dataroom_id, fetchCycles]);

  const handleCycleSelect = useCallback((cycle) => {
    setSelectedCycle(cycle);
    setEditForm({
      status: cycle.status,
      auditor_id: cycle.auditor_id || '',
      supervisor_id: cycle.supervisor_id || '',
      risks: cycle.risks || []
    });
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setEditForm(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleRisksChange = useCallback((e) => {
    const risksText = e.target.value;
    try {
      const risksArray = risksText.split('\n').filter(risk => risk.trim() !== '');
      setEditForm(prev => ({ ...prev, risks: risksArray }));
    } catch (error) {
      console.error("Error parsing risks:", error);
    }
  }, []);

  const handleUpdateCycle = useCallback(async () => {
    try {
      if (selectedCycle) {
        await updateCycle(dataroom.dataroom_id, selectedCycle.id, editForm);
        fetchCycles();
        setSelectedCycle(null);
        setEditForm({ 
          status: '', 
          auditor_id: '', 
          supervisor_id: '',
          risks: []
        });
      }
    } catch (error) {
      console.error("Error updating cycle:", error);
    }
  }, [selectedCycle, editForm, updateCycle, fetchCycles, dataroom?.dataroom_id]);

  const handleDeleteCycle = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this cycle?')) {
      try {
        await deleteCycle(dataroom.dataroom_id, selectedCycle.id);
        fetchCycles();
        setSelectedCycle(null);
        setEditForm({ 
          status: '', 
          auditor_id: '', 
          supervisor_id: '',
          risks: []
        });
      } catch (error) {
        console.error("Error deleting cycle:", error);
      }
    }
  }, [selectedCycle, deleteCycle, fetchCycles, dataroom?.dataroom_id]);

  const filteredCycles = useMemo(() => 
    cycles.filter(cycle => 
      cycle.cycle_template?.name?.toLowerCase().includes(filter.toLowerCase()) ?? true
    ),
    [cycles, filter]
  );

  const isProcessing = loading || updatingCycle || deletingCycle;

  return (
    <Modal show={show} onHide={handleClose} fullscreen={true} className="edit-modal modal-fullscreen">
      <Modal.Header closeButton>
        <Modal.Title>Manage Cycles - {dataroom?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          <Row>
            <Col md={5}>
              <h5 className="mb-3">Cycles</h5>
              <Form.Control
                type="text"
                placeholder="Search cycles..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="mb-3"
              />
              <div style={{maxHeight: '70vh', overflowY: 'auto'}}>
                <Table hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Risks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredCycles.map(cycle => (
                      <tr 
                        key={cycle.id} 
                        onClick={() => handleCycleSelect(cycle)}
                        className={selectedCycle?.id === cycle.id ? 'table-primary' : ''}
                        style={{cursor: 'pointer'}}
                      >
                        <td>{cycle.cycle_template?.name || 'N/A'}</td>
                        <td><Badge bg={getStatusVariant(cycle.status)}>{cycle.status}</Badge></td>
                        <td>
                          <Badge bg={cycle.risks?.length > 0 ? 'warning' : 'success'}>
                            {cycle.risks?.length || 0} risks
                          </Badge>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col md={7}>
              <h5 className="mb-3">Cycle Management</h5>
              {selectedCycle ? (
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Cycle Template</Form.Label>
                    <Form.Control
                      type="text"
                      value={selectedCycle.cycle_template?.name || 'N/A'}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      name="status"
                      value={editForm.status}
                      onChange={handleInputChange}
                    >
                      <option value="">Select status</option>
                      {statusOptions.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Risks (one per line)</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={editForm.risks.join('\n')}
                      onChange={handleRisksChange}
                      placeholder="Enter risks (one per line)"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Auditor ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="auditor_id"
                      value={editForm.auditor_id}
                      onChange={handleInputChange}
                      placeholder="Enter auditor ID"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Supervisor ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="supervisor_id"
                      value={editForm.supervisor_id}
                      onChange={handleInputChange}
                      placeholder="Enter supervisor ID"
                    />
                  </Form.Group>
                  <div className="d-flex gap-2">
                    <Button 
                      variant="primary" 
                      onClick={handleUpdateCycle}
                      disabled={isProcessing || !editForm.status}
                    >
                      {updatingCycle ? 'Updating...' : 'Update Cycle'}
                    </Button>
                    <Button 
                      variant="danger" 
                      onClick={handleDeleteCycle}
                      disabled={isProcessing}
                    >
                      {deletingCycle ? 'Deleting...' : 'Delete Cycle'}
                    </Button>
                    <Button 
                      variant="outline-secondary" 
                      onClick={() => {
                        setSelectedCycle(null);
                        setEditForm({ 
                          status: '', 
                          auditor_id: '', 
                          supervisor_id: '',
                          risks: []
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              ) : (
                <Alert variant="info">
                  Select a cycle from the list to edit its details.
                </Alert>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="me-auto">
          {isProcessing && (
            <span className="text-muted">
              <Spinner animation="border" size="sm" className="me-2" />
              Processing...
            </span>
          )}
        </div>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataroomCycleEditModal;