import React from 'react';
import './QuickPromptButtons.css';

const QuickPromptButtons = ({ onPromptSelect }) => {
  const prompts = [
    {
      label: "Solve control",
      fullPrompt: "Please help me solve this control by analyzing the data and providing a detailed explanation of any issues found."
    },
    {
      label: "Explain ISA 520",
      fullPrompt: "Can you explain ISA 520 (Analytical Procedures) and how it should be applied in the context of this control?"
    },
    {
      label: "Risk assessment",
      fullPrompt: "Can you help me perform a risk assessment for this control and identify potential areas of concern?"
    },
    {
      label: "Best practices",
      fullPrompt: "What are the best practices and key considerations for this type of control?"
    }
  ];

  return (
    <div className="quick-prompts-container">
      {prompts.map((prompt, index) => (
        <button
          key={index}
          onClick={() => onPromptSelect(prompt.fullPrompt)}
          className="quick-prompt-button"
        >
          {prompt.label}
        </button>
      ))}
    </div>
  );
};

export default QuickPromptButtons;