import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

const RequestModal = ({
    show,
    onHide,
    requestType,
    setRequestType,
    criteria,
    setCriteria,
    directRequest,
    setDirectRequest,
    onSubmit,
    processingRequests,
    aiLoading,
    aiError,
    createReference
}) => {
    const handleSubmit = async () => {
        if (requestType === 'direct') {
            try {
                await createReference(directRequest);
                onHide();
                setDirectRequest('');
                setRequestType(null);
            } catch (error) {
                console.error('Error creating reference:', error);
            }
        } else {
            onSubmit();
        }
    };

    return (
        <Modal 
            show={show} 
            onHide={onHide}
            className="dm-modal"
            dialogClassName="dm-modal-dialog"
        >
            <Modal.Header closeButton>
                <Modal.Title>Request Documents</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {aiError && (
                    <div className="alert alert-danger mb-3">
                        {aiError}
                    </div>
                )}

                {!requestType ? (
                    <div className="d-grid gap-3">
                        <button 
                            className="dm-request-btn"
                            onClick={() => setRequestType('ai')}
                        >
                            <i className="bi bi-robot"></i>
                            Use AI Assistant
                            <small>Let AI analyze your criteria and create specific document requests</small>
                        </button>
                        <button 
                            className="dm-request-btn"
                            onClick={() => setRequestType('direct')}
                        >
                            <i className="bi bi-pencil-square"></i>
                            Direct Request
                            <small>Create a specific document request manually</small>
                        </button>
                    </div>
                ) : requestType === 'ai' ? (
                    <Form>
                        <Form.Group>
                            <Form.Label>Document Criteria</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="e.g., Invoices over €35,000 from suppliers in the technology sector"
                                value={criteria}
                                onChange={(e) => setCriteria(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                Describe the documents you need. AI will analyze your criteria 
                                and create specific requests.
                            </Form.Text>
                        </Form.Group>
                    </Form>
                ) : (
                    <Form>
                        <Form.Group>
                            <Form.Label>Document Description</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="e.g., Purchase Order #123 for Office Equipment"
                                value={directRequest}
                                onChange={(e) => setDirectRequest(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                Provide a clear description of the document you're requesting
                            </Form.Text>
                        </Form.Group>
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                {requestType && (
                    <Button 
                        variant="outline-secondary" 
                        onClick={() => setRequestType(null)}
                    >
                        Back
                    </Button>
                )}
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                {requestType && (
                    <Button 
                        variant="primary" 
                        onClick={handleSubmit}
                        disabled={
                            (requestType === 'ai' && !criteria) || 
                            (requestType === 'direct' && !directRequest) ||
                            aiLoading ||
                            processingRequests.size > 0
                        }
                    >
                        {aiLoading || processingRequests.size > 0 ? 'Processing...' : 'Submit Request'}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default RequestModal;