import React from 'react';
import { Col, Nav } from 'react-bootstrap';

const Sidebar = ({ activeTab, setActiveTab }) => (
  <Col md={3} lg={2} className="admin_sidebar">
    <div className="admin_sidebar-sticky">
      <Nav className="flex-column">
        <Nav.Item>
          <Nav.Link active={activeTab === 'home'} onClick={() => setActiveTab('home')}>
            Home
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link active={activeTab === 'users'} onClick={() => setActiveTab('users')}>
            Users
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link active={activeTab === 'datarooms'} onClick={() => setActiveTab('datarooms')}>
            Datarooms
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link active={activeTab === 'templates'} onClick={() => setActiveTab('templates')}>
            Templates
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  </Col>
);

export default Sidebar;