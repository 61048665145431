import React, { useState, useEffect, useContext } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';
import { useUser } from '../../general/UserProvider';
import { DataRoomContext } from '../DisplayDataRoom';

import { Button, Form } from 'react-bootstrap';

function AddUserToGroupForm({ loadUsers, loadUserGroups }) {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");

    useEffect(() => {
        if (!token) return;

        const fetchUsers = async () => {
            try {
                const response = await api.get(API_ROUTES.USERS_WITH_NO_GROUPS_BY_DATAROOM(dataroom_id));
                setUsers(response.data);
            } catch (error) {
                console.error("There was an error fetching the users:", error);
            }
        };

        fetchUsers();
    }, [dataroom_id, token]);

    const handleUserChange = (e) => {
        setSelectedUser(e.target.value);
    };

    const handleSubmit = async () => {
        if (!token) return;

        try {
            await api.post(API_ROUTES.ADD_USER_TO_DEFAULT_GROUP(dataroom_id, selectedUser));
            
            setSelectedUser(""); 
            loadUsers();
            loadUserGroups();
            setUsers(prevUsers => prevUsers.filter(user => user.user_id !== selectedUser));
        } catch (error) {
            console.error("There was an error adding the user to the default group:", error);
            alert("There was an error adding the user to the default group.");
        }
    };

    return (
        <Form>
            <Form.Group controlId="userSelect">
                <Form.Label>Select a user</Form.Label>
                <Form.Control 
                    as="select" 
                    value={selectedUser}
                    onChange={handleUserChange}
                >
                    <option value="" disabled>Select a user</option>
                    {users.map(user => (
                        <option key={user.user_id} value={user.user_id}>
                            {user.username}
                        </option>
                    ))}
                </Form.Control>
                <Button onClick={handleSubmit} disabled={!selectedUser} className="my-2">Add User</Button>
            </Form.Group>
        </Form>
    );
};

export default AddUserToGroupForm;
