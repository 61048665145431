import { useState, useCallback, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';
import { useUser } from '../../general/UserProvider';

export const useFetchFiles = (folderId) => {
    const { token } = useUser();
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchFiles = useCallback(async () => {
        if (!folderId) {
            setFiles([]);
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const response = await api.get(API_ROUTES.LIST_FILES(folderId), {
                headers: { Authorization: `Bearer ${token}` } 
            });
            if (response.status === 200) {
                setFiles(response.data);
            } else {
                throw new Error('Failed to fetch files');
            }
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, [folderId, token]);

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    return { 
        files, 
        isLoading, 
        error,
        refetchFiles: fetchFiles
    };
};
