import React, { useState, useRef, useEffect, forwardRef, useCallback } from 'react';
import './PinManager.css';

const CustomDropdown = forwardRef(({ show, onToggle, children, selectedWorkspaces }, ref) => (
    <div ref={ref} className="custom-dropdown">
        <button onClick={onToggle} className="link-button no-caret">
            <i className={`pin-selector bi ${selectedWorkspaces.length > 0 ? 'bi-pin-angle-fill' : 'bi-pin-angle'}`}></i>
        </button>
        {show && <div className="custom-dropdown-menu workspace-menu">{children[1]}</div>}
    </div>
));

const PinManager = ({ folders, createNote, linkNoteWithFolder, removeNoteFromDataroom, content }) => {
    const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
    const dropdownRef = useRef(null);
    const [noteId, setNoteId] = useState(null);

    const handleDropdownWorkspaceClick = (item) => {
        let newSelectedWorkspaces;
    
        if (selectedWorkspaces.some(workspace => workspace.id === item.id)) {
            const workspaceToRemove = selectedWorkspaces.find(workspace => workspace.id === item.id);
            newSelectedWorkspaces = selectedWorkspaces.filter(workspace => workspace.id !== item.id);
            setSelectedWorkspaces(newSelectedWorkspaces);
    
            removeNoteFromDataroom(workspaceToRemove.noteId)
                .then(data => {
                })
                .catch(error => {
                    console.error("Error:", error);
                });
    
        } else {
            newSelectedWorkspaces = [...selectedWorkspaces];

            if (noteId) {
                newSelectedWorkspaces.push({ ...item, noteId: noteId });
                setSelectedWorkspaces(newSelectedWorkspaces);
                linkNoteWithFolder(noteId, item.id)
            } else {
                createNote("Note generated by the Copilot", content, item.id)
                    .then(data => {
                        setNoteId(data.note_id); 
                        newSelectedWorkspaces.push({ ...item, noteId: data.note_id });
                        setSelectedWorkspaces(newSelectedWorkspaces);
                    })
                    .catch(error => {
                        console.error("Error:", error);
                    });
            }   
        }
    };

    const [showDropdown, setShowDropdown] = useState(false);

    const handleKeyDown = useCallback((event) => {
        if (showDropdown && event.keyCode === 27) {
            event.preventDefault();
            event.stopPropagation();
        }
    }, [showDropdown]);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showDropdown, handleKeyDown]);

    const renderFolder = (folder, level = 0) => {
        return (
            <React.Fragment key={folder.id}>
                <div
                    onClick={() => handleDropdownWorkspaceClick(folder)}
                    className={`custom-dropdown-item level-${level}`}
                >
                    {selectedWorkspaces.some(workspace => workspace.id === folder.id) 
                        ? <i className="icon-check bi bi-check icon-spacing"></i>
                        : <i className="bi bi-plus icon-spacing"></i>}
                    {folder.name}
                </div>
                {folder.subcategories && folder.subcategories.map(subFolder => renderFolder(subFolder, level + 1))}
            </React.Fragment>
        );
    };

    return (
        <div className="ml-auto pin-container">
            {selectedWorkspaces.length > 0 && <span>{selectedWorkspaces.map(workspace => workspace.name).join(', ')}</span>}
            <CustomDropdown
                ref={dropdownRef}
                show={showDropdown}
                onKeyDown={handleKeyDown}
                onToggle={() => setShowDropdown(prev => !prev)}
                selectedWorkspaces={selectedWorkspaces}
            >
                {selectedWorkspaces}
                
                <div className="workspace-menu">
                    {folders.map(folder => renderFolder(folder))}
                </div>
            </CustomDropdown>
        </div>
    );
};

export default PinManager;
