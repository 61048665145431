import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Container, Alert } from 'react-bootstrap';

const AssertionEditModal = ({ 
  show, 
  handleClose, 
  assertion, 
  onSave, 
  isProcessing, 
  error
}) => {
  const [formData, setFormData] = useState({
    title: '',
    description: ''
  });

  useEffect(() => {
    if (assertion) {
      setFormData({
        title: assertion.title || '',
        description: assertion.description || ''
      });
    } else {
      setFormData({
        title: '',
        description: ''
      });
    }
  }, [assertion]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const isFormValid = () => {
    return formData.title.trim() !== '' && 
           formData.description.trim() !== '';
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" className="edit-modal" centered>
      <Modal.Header>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs="auto">
              <Button variant="link" onClick={handleClose} className="p-0 text-dark">
                <i className="bi bi-x-lg"></i>
              </Button>
            </Col>
            <Col>
              <Modal.Title>{assertion ? 'Edit Assertion' : 'Create Assertion'}</Modal.Title>
            </Col>
            <Col xs="auto">
              <Button 
                variant="primary" 
                onClick={handleSubmit} 
                disabled={isProcessing || !isFormValid()}
              >
                {isProcessing ? 'Processing...' : (assertion ? 'Save Changes' : 'Create Assertion')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <h2 className="title-h2 font-title mx-3">Assertion Details</h2>
            <div className="px-4">
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="e.g., Financial Review"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="description"
                  as="textarea"
                  rows={3}
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Describe the purpose and general steps for this assertion"
                  required
                />
              </Form.Group>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AssertionEditModal;