import React from 'react';
import { Card, Row, Col, Badge, Button } from 'react-bootstrap';

const DataroomGeneralInfo = ({ dataroom, onEdit }) => {
  return (
    <Card className="mb-4">
      <Card.Body>
        <Row className="align-items-center mb-4">
          <Col xs={12} md={9}>
            <h2 className="mb-0">{dataroom.name}</h2>
          </Col>
          <Col xs={12} md={3} className="text-md-end mt-3 mt-md-0">
            <Button variant="primary" onClick={onEdit}>
              Edit Dataroom
            </Button>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} md={6} lg={4} className="mb-3">
            <div className="d-flex align-items-center">
              <i className="bi bi-person me-2"></i>
              <div>
                <small className="text-muted">Owner</small>
                <p className="mb-0">{dataroom.owner_id}</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-3">
            <div className="d-flex align-items-center">
              <i className="bi bi-calendar me-2"></i>
              <div>
                <small className="text-muted">Created</small>
                <p className="mb-0">{dataroom.created_at}</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-3">
            <div className="d-flex align-items-center">
              <i className="bi bi-clock me-2"></i>
              <div>
                <small className="text-muted">Last Updated</small>
                <p className="mb-0">{dataroom.last_updated || 'N/A'}</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col xs={12} md={6} lg={4} className="mb-3">
            <div className="d-flex align-items-center">
              <i className="bi bi-eye me-2"></i>
              <div>
                <small className="text-muted">Visibility</small>
                <p className="mb-0">
                  <Badge bg={dataroom.visibility === 'Private' ? 'danger' : 'success'}>
                    {dataroom.visibility}
                  </Badge>
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-3">
            <div className="d-flex align-items-center">
              <i className={`bi ${dataroom.is_active ? 'bi-check-circle text-success' : 'bi-x-circle text-danger'} me-2`}></i>
              <div>
                <small className="text-muted">Status</small>
                <p className="mb-0">
                  <Badge bg={dataroom.is_active ? 'success' : 'danger'}>
                    {dataroom.is_active ? 'Active' : 'Inactive'}
                  </Badge>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Card.Text className="mb-0">
          <strong>Description:</strong>
        </Card.Text>
        <Card.Text>{dataroom.description}</Card.Text>

        {(dataroom.slack_url || dataroom.teams_url) && (
          <Row className="mt-4">
            <Col xs={12}>
              <Card.Text className="mb-2">
                <strong>Communication Channels:</strong>
              </Card.Text>
            </Col>
            {dataroom.slack_url && (
              <Col xs={12} md={6} className="mb-2">
                <a href={dataroom.slack_url} target="_blank" rel="noopener noreferrer" className="btn btn-outline-secondary btn-sm">
                  <i className="bi bi-slack me-2"></i>Slack Channel
                </a>
              </Col>
            )}
            {dataroom.teams_url && (
              <Col xs={12} md={6} className="mb-2">
                <a href={dataroom.teams_url} target="_blank" rel="noopener noreferrer" className="btn btn-outline-secondary btn-sm">
                  <i className="bi bi-microsoft-teams me-2"></i>Teams Channel
                </a>
              </Col>
            )}
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default DataroomGeneralInfo;