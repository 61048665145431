import React, { useEffect, useContext } from 'react';
import { Table, Alert, OverlayTrigger } from 'react-bootstrap';
import { DataRoomContext } from '../DisplayDataRoom';
import { useUser } from '../../general/UserProvider';

import { usePermissionNames } from '../../hooks/groups/usePermissionNames';
import { usePermissions } from '../../hooks/groups/usePermissions';

function PermissionPerGroup( {groupItemsByFolderName, setError, renderTooltip } ) {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);

    const { permNames, error: errorNames } = usePermissionNames(dataroom_id, token);
    const { permData, error: errorPerms, updatePermission } = usePermissions(dataroom_id, token);

    useEffect(() => {
        if (errorNames) {
          console.error('Error fetching permission names:', errorNames);
          setError(errorNames);
        }
    
        if (errorPerms) {
          console.error('Error fetching permissions data:', errorPerms);
          setError(errorPerms);
        }
      }, [errorNames, errorPerms, setError]);
    
      if (errorNames || errorPerms) {
        return (
          <Alert variant="danger">
            There was an error loading the permissions data. Please try again later.
          </Alert>
        );
      }

    return (
        <>
            <h2 className="my-3">Permissions per group</h2>
            <Table bordered responsive className="custom-bordered-table">
                <thead>
                    <tr>
                        <th rowSpan="2" colSpan="2"></th>
                        {permNames.map((item, index) => (
                            <th key={index} colSpan={item.permissions.length} className="text-center align-middle">
                                {item.feature}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        {permNames.map((item, index) => 
                            item.permissions.map(permission => (
                                <th key={permission.id} className="text-center align-middle">
                                    {permission.name}
                                </th>
                            ))
                        )}
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(groupItemsByFolderName(permData)).map(([categoryName, items]) =>
                        items.map((item, rowIndex) => {
                        const folderHierarchyTooltip = item.folder_hierarchy;
                        return (
                            <tr key={rowIndex}>
                            {rowIndex === 0 && (
                                folderHierarchyTooltip.length !== 0 ? (
                                <OverlayTrigger
                                    key={`folder-${item.folder_name}`}
                                    placement="top"
                                    overlay={(props) => renderTooltip(props, folderHierarchyTooltip)}
                                >
                                    <td rowSpan={items.length} className="text-center align-middle fw-bold custom-border">
                                    {item.folder_name}
                                    </td>
                                </OverlayTrigger>
                                ) : (
                                <td rowSpan={items.length} className="text-center align-middle fw-bold custom-border">
                                    {item.folder_name}
                                </td>
                                )
                            )}
                            <td className='text-center align-middle fw-bold custom-border'>{item.group_name}</td>
                            {permNames.flatMap(headerItem => headerItem.permissions).map((permissionHeader, headerIndex) => {
                                const permission = item.permissions.find(p => p[permissionHeader.id]);
                                const isTrue = permission && permission[permissionHeader.id];
                                
                                return (
                                <td 
                                    key={`permission-${headerIndex}`} 
                                    className={`${isTrue ? 'green-cell' : 'red-cell'} text-center align-middle cursor-pointer cell-hover-opacity`}
                                    onClick={() => updatePermission(item.group_id, permissionHeader.id, isTrue)}
                                >
                                    {isTrue ? (
                                        <i className="bi bi-check" style={{ color: 'green' }}></i>
                                    ) : (
                                        <i className="bi bi-x" style={{ color: 'red' }}></i>
                                    )}
                                </td>
                                );
                            })}
                            </tr>
                        );
                        })
                    )}
                </tbody>
            </Table>
        </>
    );
};

export default PermissionPerGroup;
