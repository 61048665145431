import { useState } from 'react';

export const useIRFormValidation = (formData, validate, callback) => {
    const [errors, setErrors] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);

    const handleSubmit = event => {
        if (event) event.preventDefault();
        setSubmitting(true);
        const validationErrors = validate(formData);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            callback();
        } else {
            setSubmitting(false); 
        }
    };

    return { handleSubmit, errors, isSubmitting };
}