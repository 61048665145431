import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddControl = (token) => {
    const [adding, setAdding] = useState(false);
    const [addError, setAddError] = useState(null);

    const addControl = async (controlData) => {
        setAdding(true);
        setAddError(null);

        try {
            const formattedData = {
                ...controlData,
                assertion_ids: controlData.assertion_ids.map(id => parseInt(id))
            };

            const response = await api.post(
                API_ROUTES.ADMIN_ADD_CONTROLS,
                formattedData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setAdding(false);
            return response.data;
        } catch (error) {
            setAddError(error.response?.data?.message || 'An error occurred while adding the control');
            setAdding(false);
            throw error;
        }
    };

    return { addControl, adding, addError };
};

export const useUpdateControl = (token) => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const updateControl = async (controlData) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            // Conversion des IDs d'assertions en nombres
            const formattedData = {
                ...controlData,
                assertion_ids: controlData.assertion_ids.map(id => parseInt(id))
            };

            const response = await api.put(
                `${API_ROUTES.ADMIN_UPDATE_CONTROLS}/${controlData.id}`,
                formattedData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setUpdating(false);
            return response.data;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the control');
            setUpdating(false);
            throw error;
        }
    };

    return { updateControl, updating, updateError };
};

export const useDeleteControl = (token) => {
    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const deleteControl = async (controlId) => {
        setDeleting(true);
        setDeleteError(null);

        try {
            await api.delete(
                `${API_ROUTES.ADMIN_DELETE_CONTROLS}/${controlId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setDeleting(false);
        } catch (error) {
            setDeleteError(error.response?.data?.message || 'An error occurred while deleting the control');
            setDeleting(false);
            throw error;
        }
    };

    return { deleteControl, deleting, deleteError };
};

export const useFetchControls = (token, cycleId) => {
    const [controls, setControls] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchControls = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            let url = API_ROUTES.ADMIN_GET_CONTROLS;
            if (cycleId !== -1) {
                url += `?cycleId=${cycleId}`;
            }

            const response = await api.get(url, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Sort controls by order
            const sortedControls = response.data.sort((a, b) => a.order - b.order);
            setControls(sortedControls);
            setLoading(false);
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while fetching controls');
            setLoading(false);
        }
    }, [token, cycleId]);

    useEffect(() => {
        fetchControls();
    }, [fetchControls]);

    return { controls, setControls, loading, error, refetch: fetchControls };
};

export const useReorderControls = (token) => {
    const [reordering, setReordering] = useState(false);
    const [reorderError, setReorderError] = useState(null);

    const reorderControls = async (cycleId, newOrder) => {
        setReordering(true);
        setReorderError(null);

        try {
            const response = await api.post(
                `${API_ROUTES.ADMIN_REORDER_CONTROLS.replace(':cycleId', cycleId)}`,
                { order: newOrder },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setReordering(false);
            return response.data.controls;
        } catch (error) {
            setReorderError(error.response?.data?.message || 'An error occurred while reordering the controls');
            setReordering(false);
            throw error;
        }
    };

    return { reorderControls, reordering, reorderError };
};