import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useDataroomUsers = (token, dataroom_id) => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!token) return;

        const loadUsers = async () => {
            setLoading(true);
            try {
                const response = await api.get(API_ROUTES.USERS_BY_DATAROOM(dataroom_id));
                setUsers(response.data);
                setError(null);
            } catch (error) {
                setError("There was an error fetching the users");
            } finally {
                setLoading(false);
            }
        };

        loadUsers();
    }, [dataroom_id, token]);

    return { users, error, loading };
};