import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './AssistantMessage.css';
import '../procedure/markdown-styles.css';

const AssistantMessage = ({ content }) => (
    <div className="chat-message justify-content-start">
        <div className="assistant-message">
            <div className="d-flex align-items-center mb-2">
                <i className="bi bi-robot me-2"></i>
                <strong>Execution Copilot</strong>
            </div>
            <div className="markdown-content">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {content}
                </ReactMarkdown>
            </div>
        </div>
    </div>
);

export default AssistantMessage;