import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { Auth } from 'aws-amplify';

const UserContext = createContext();

export function useUser() {
    return useContext(UserContext);
}

export function UserProvider({ children, user, signOut }) {
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState(null);

    const fetchToken = useCallback(async () => {
        try {
            const session = await Auth.currentSession();
            const idToken = session.getIdToken();
            setToken(idToken.getJwtToken());

            const expiresIn = (idToken.getExpiration() - Math.floor(Date.now() / 1000)) * 1000;
            setTimeout(fetchToken, expiresIn - 30000); 
        } catch (error) {
            console.error("Error fetching token: ", error);
            signOut(); 
        }
    }, [signOut]); 

    useEffect(() => {
        if (user && user.username) {
            setUserData({ username: user.username });
            fetchToken();
        }
    }, [user, fetchToken]);

    return (
        <UserContext.Provider value={{ username: userData?.username, signOut, token, fetchToken }}>
            {children}
        </UserContext.Provider>
    );
}
