import React, { useContext, useCallback, useMemo } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import LayoutPageHeader from '../../general/LayoutPageHeader';

import WSCardMenu from './WSCardMenu';
import WSCard from './WSCard';
import WSBreadCrumb from './WSBreadCrumb';
import WSCreateNote from './WSCreateNote';
import { DataRoomContext } from '../DisplayDataRoom';
import { useUser } from '../../general/UserProvider';
import { useFolders } from '../../hooks/documents/useFolders';
import { useNotes } from '../../hooks/workspace/useNotes';
import { useBreadcrumb } from '../../hooks/workspace/useBreadcrumb';

import './Workspace.css';

function Workspace() {
    const { id, noteId } = useParams();
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);
    
    const { folders, loading: foldersLoading, error: foldersError } = useFolders(token, dataroom_id, true, null, 'note');
    const { breadcrumb, handleCardClick, handleBreadcrumbClick, handleGoToParent } = useBreadcrumb(dataroom_id);

    const currentFolderId = useMemo(() => breadcrumb[breadcrumb.length - 1]?.id, [breadcrumb]);
    
    const { 
        getVisibleNotes,
        deleteNote, 
        addNote,
        updateNote,
        loading: notesLoading, 
        error: notesError 
    } = useNotes(token, dataroom_id);

    const visibleNotes = useMemo(() => getVisibleNotes(currentFolderId), [getVisibleNotes, currentFolderId]);

    const findFolderById = useCallback((folders, id) => {
        for (let folder of folders) {
            if (folder.id === id) {
                return folder;
            }
            if (folder.subcategories) {
                const found = findFolderById(folder.subcategories, id);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }, []);

    const currentFolder = useMemo(() => findFolderById(folders, currentFolderId), [findFolderById, folders, currentFolderId]);
    const currentItems = useMemo(() => currentFolder ? (currentFolder.subcategories || []) : folders, [currentFolder, folders]);

    const renderFolders = () => (
        <Row className="folder-section">
            {breadcrumb.length > 1 && (
                <WSCardMenu
                    cardInfo={{ name: '...' }} 
                    onClick={handleGoToParent} 
                />
            )}
            {currentItems.map((item) => (
                <WSCardMenu
                    key={`menu-${item.id}`}
                    cardInfo={item}
                    onClick={() => handleCardClick(item)}
                />
            ))}
        </Row>
    );

    const renderNotes = () => {
        if (notesLoading) {
            return (
                <div className="loading-spinner">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading notes...</span>
                    </div>
                </div>
            );
        }

        if (notesError) {
            return <p className="error-message">Error loading notes: {notesError}</p>;
        }

        return visibleNotes.length > 0 ? (
            visibleNotes.map(note => (
                <WSCard
                    key={`note-${note.note_id}`} 
                    noteId={note.note_id}
                    deleteNote={deleteNote}
                    updateNote={updateNote}
                    dataroom_id={id}
                    preloadedNote={note}
                />
            ))
        ) : (
            <div className="empty-notes-message">
                No notes found in this folder.
            </div>
        );
    };

    const renderContent = () => {
        if (foldersLoading) {
            return (
                <div className="loading-spinner">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading folders...</span>
                    </div>
                </div>
            );
        }

        if (foldersError) {
            return <p className="error-message">Error loading folders: {foldersError}</p>;
        }

        return (
            <>
                <WSBreadCrumb breadcrumbItems={breadcrumb} handleBreadcrumbClick={handleBreadcrumbClick} />
                <Container fluid className="workspace-content">
                    {renderFolders()}
                    <Row className="notes-section">
                        {renderNotes()}
                    </Row>
                </Container>
            </>
        );
    };

    const actionButtons = (
        <div className="action-buttons">
            <WSCreateNote addNote={addNote} currentFolderId={currentFolderId} />
        </div>
    );

    return (
        <LayoutPageHeader title="Workspace" actions={actionButtons}>
            {noteId ? (
                <WSCard 
                    key={`note-${noteId}`} 
                    noteId={noteId}
                    deleteNote={deleteNote}
                    updateNote={updateNote}
                    directAccessViaURL={true} 
                    dataroom_id={id}
                />
            ) : (
                renderContent()
            )}
        </LayoutPageHeader>
    );
}

export default Workspace;