import React, { useState, useCallback } from 'react';
import { Card, Button, Spinner, Alert } from 'react-bootstrap';
import TemplateFolderNode from './FolderNode';
import TemplateFolderEditModal from './FolderEditModal';
import { buildTemplateFolderTree } from './utils/buildTemplateFolderTree';
import { 
  useAddFolder, 
  useUpdateFolder, 
  useDeleteFolder, 
  useFetchFolders
} from '../../../hooks/admin/templateFoldersHooks';

import './TemplateFolders.css';

const TemplateFoldersInfo = ({ token }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const { folders, loading, error, refetch } = useFetchFolders(token);
  const { addFolder, adding, addError } = useAddFolder(token);
  const { updateFolder, updating, updateError } = useUpdateFolder(token);
  const { deleteFolder } = useDeleteFolder(token);

  const handleShowModal = useCallback((folder = null) => {
    setSelectedFolder(folder);
    setShowEditModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowEditModal(false);
    setSelectedFolder(null);
  }, []);

  const handleSaveFolder = useCallback(async (folderData) => {
    try {
      if (selectedFolder) {
        await updateFolder({ ...folderData, id: selectedFolder.id });
      } else {
        await addFolder(folderData);
      }
      refetch();
      handleCloseModal();
    } catch (error) {
      console.error("Failed to save template folder:", error);
    }
  }, [selectedFolder, updateFolder, addFolder, refetch, handleCloseModal]);

  const handleDeleteFolder = useCallback(async (folderId) => {
    try {
      await deleteFolder(folderId);
      refetch();
    } catch (error) {
      console.error("Failed to delete template folder:", error);
    }
  }, [deleteFolder, refetch]);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  const folderTree = buildTemplateFolderTree(folders);

  return (
    <Card className="admin_card shadow-sm">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Folders</h3>
          <Button 
            variant="primary" 
            onClick={() => handleShowModal()}
            disabled={adding}
          >
            <i className="bi bi-plus-lg me-2"></i>
            Add Template Folder
          </Button>
        </div>

        {folders.length === 0 ? (
          <Alert variant="info">No template folders found.</Alert>
        ) : (
          <div className="folder-tree">
            {folderTree.map((folder) => (
              <TemplateFolderNode
                key={folder.id}
                folder={folder}
                onEdit={handleShowModal}
                onDelete={handleDeleteFolder}
              />
            ))}
          </div>
        )}
      </Card.Body>

      <TemplateFolderEditModal
        show={showEditModal}
        handleClose={handleCloseModal}
        folder={selectedFolder}
        parentFolders={folders}
        onSave={handleSaveFolder}
        isProcessing={updating || adding}
        error={updateError || addError}
      />
    </Card>
  );
};

export default TemplateFoldersInfo;