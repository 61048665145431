import React, { useState, useCallback } from 'react';
import { Button, Spinner, Alert, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatDate } from '../../../utils/Date';
import DocTypeEditModal from './DocTypeEditModal';
import { 
  useAddDocumentType, 
  useUpdateDocumentType, 
  useDeleteDocumentType, 
  useFetchDocumentTypes 
} from '../../../hooks/admin/docTypesHooks';

const DocTypesInfo = ({ token }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState(null);

  const { documentTypes, loading, error, refetch } = useFetchDocumentTypes(token);
  const { addDocumentType, adding, addError } = useAddDocumentType(token);
  const { updateDocumentType, updating, updateError } = useUpdateDocumentType(token);
  const { deleteDocumentType, deleting, deleteError } = useDeleteDocumentType(token);

  const handleShowModal = useCallback((docType = null) => {
    setSelectedDocType(docType);
    setShowEditModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowEditModal(false);
    setSelectedDocType(null);
  }, []);

  const handleDelete = useCallback(async (docTypeId) => {
    const success = await deleteDocumentType(docTypeId);
    if (success) {
      refetch();
    }
  }, [deleteDocumentType, refetch]);

  const handleSaveDocType = useCallback(async (formData) => {
    try {
      if (selectedDocType) {
        await updateDocumentType({ ...formData, id: selectedDocType.id });
      } else {
        await addDocumentType(formData);
      }
      refetch();
      handleCloseModal();
    } catch (error) {
      console.error("Failed to save document type:", error);
    }
  }, [selectedDocType, updateDocumentType, addDocumentType, refetch, handleCloseModal]);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const displayError = error || deleteError || updateError || addError;
  if (displayError) {
    return <Alert variant="danger">{displayError}</Alert>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>Document Types</h4>
        <Button 
          variant="primary" 
          onClick={() => handleShowModal()}
          disabled={adding}
        >
          <i className="bi bi-plus-lg me-2"></i>
          Add Document Type
        </Button>
      </div>

      {documentTypes.length === 0 ? (
        <Alert variant="info">No document types found.</Alert>
      ) : (
        <Table hover responsive className="admin_table-nowrap">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {documentTypes.map((docType) => (
              <tr key={docType.id}>
                <td>{docType.id}</td>
                <td>{docType.name}</td>
                <td>{docType.description}</td>
                <td>{formatDate(docType.created_at)}</td>
                <td>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Edit document type</Tooltip>}>
                    <Button 
                      variant="outline-secondary" 
                      size="sm" 
                      className="me-2" 
                      onClick={() => handleShowModal(docType)}
                    >
                      <i className="bi bi-pencil"></i>
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Delete document type</Tooltip>}>
                    <Button 
                      variant="outline-danger" 
                      size="sm" 
                      onClick={() => handleDelete(docType.id)}
                      disabled={deleting}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <DocTypeEditModal
        show={showEditModal}
        handleClose={handleCloseModal}
        documentType={selectedDocType}
        onSave={handleSaveDocType}
        isProcessing={updating || adding}
        error={updateError || addError}
      />
    </div>
  );
};

export default DocTypesInfo;