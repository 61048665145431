import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './DeleteConfirmationModal.css';

const DeleteConfirmationModal = ({ show, onHide, onDeleteFromControl, onDeleteCompletely, fileName }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            className="dm-modal"
            dialogClassName="dm-modal-dialog"
        >
            <Modal.Header closeButton>
                <Modal.Title>Delete Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-4">
                    Choose how you want to delete <strong>{fileName}</strong>:
                </p>
                <div className="delete-options">
                    <div 
                        className="delete-option"
                        onClick={onDeleteFromControl}
                    >
                        <div className="delete-option-icon">
                            <i className="bi bi-heartbreak"></i>
                        </div>
                        <div className="delete-option-content">
                            <h6>Remove from control only</h6>
                            <p>The document will remain in the dataroom but will be unlinked from this control</p>
                        </div>
                    </div>
                    <div 
                        className="delete-option delete-option--danger"
                        onClick={onDeleteCompletely}
                    >
                        <div className="delete-option-icon">
                            <i className="bi bi-trash"></i>
                        </div>
                        <div className="delete-option-content">
                            <h6>Delete completely</h6>
                            <p>The document will be permanently deleted from the dataroom</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onHide}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmationModal;