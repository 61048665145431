import React, { useState, useCallback } from 'react';
import { Card, Table, Button, Spinner, Alert } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CycleEditModal from './CycleEditModal';
import { useAddCycle, useUpdateCycle, useDeleteCycle, useFetchCycles, useReorderCycles } from '../../../hooks/admin/cyclesHooks';

const CycleManagement = ({ token }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCycle, setSelectedCycle] = useState(null);
  const { addCycle, adding, addError } = useAddCycle(token);
  const { updateCycle, updating, updateError } = useUpdateCycle(token);
  const { deleteCycle, deleting } = useDeleteCycle(token);
  const { cycles, setCycles, loading, error, refetch } = useFetchCycles(token);  
  const { reorderCycles } = useReorderCycles(token);

  const handleShowModal = useCallback((cycle = null) => {
    setSelectedCycle(cycle);
    setShowEditModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowEditModal(false);
    setSelectedCycle(null);
  }, []);

  const handleSaveCycle = useCallback(async (cycleData) => {
    try {
      if (selectedCycle) {
        await updateCycle({ ...cycleData, id: selectedCycle.id });
      } else {
        await addCycle(cycleData);
      }
      refetch();
      handleCloseModal();
    } catch (error) {
      console.error("Failed to save cycle:", error);
    }
  }, [selectedCycle, updateCycle, addCycle, refetch, handleCloseModal]);

  const handleDeleteCycle = useCallback(async (cycleId) => {
    try {
      await deleteCycle(cycleId);
      refetch();
    } catch (error) {
      console.error("Failed to delete cycle:", error);
    }
  }, [deleteCycle, refetch]);

  const handleDragEnd = useCallback(async (result) => {
    if (!result.destination) {
      return;
    }
  
    const items = Array.from(cycles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    const newOrder = items.map((item) => item.id);
    
    try {
      const updatedCycles = await reorderCycles(newOrder);
      setCycles(updatedCycles);
    } catch (error) {
      console.error("Failed to reorder cycles:", error);
    }
  }, [cycles, reorderCycles, setCycles]);

  const TruncatedText = ({ text, maxLength = 100 }) => {
    const truncated = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    return <span title={text}>{truncated}</span>;
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  // Ajout d'une vérification pour s'assurer que cycles est un tableau
  const cyclesArray = Array.isArray(cycles) ? cycles : [];

  return (
    <Card className="admin_card shadow-sm">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Cycles</h3>
          <Button variant="primary" onClick={() => handleShowModal()}>
            + Add Cycle
          </Button>
        </div>
        {cyclesArray.length === 0 ? (
          <Alert variant="info">No cycles found.</Alert>
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="cycles">
              {(provided) => (
                <Table hover responsive className="admin_table-nowrap" {...provided.droppableProps} ref={provided.innerRef}>
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cyclesArray.map((cycle, index) => (
                      <Draggable key={cycle.id} draggableId={cycle.id.toString()} index={index}>
                        {(provided) => (
                          <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <td>{cycle.order}</td>
                            <td><b>{cycle.name}</b></td>
                            <td><TruncatedText text={cycle.description} /></td>
                            <td>
                              <Button
                                variant="outline-secondary"
                                size="sm"
                                className="me-2"
                                onClick={() => handleShowModal(cycle)}
                                title="Edit cycle"
                              >
                                <i className="bi bi-pencil"></i>
                              </Button>
                              <Button 
                                variant="outline-danger" 
                                size="sm" 
                                onClick={() => handleDeleteCycle(cycle.id)}
                                disabled={deleting}
                                title="Delete cycle"
                              >
                                <i className="bi bi-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Card.Body>
  
      <CycleEditModal
        show={showEditModal}
        handleClose={handleCloseModal}
        cycle={selectedCycle}
        onSave={handleSaveCycle}
        isProcessing={updating || adding}
        error={updateError || addError}
      />
    </Card>
  );
};

export default CycleManagement;