import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Container, Alert } from 'react-bootstrap';
import GeneralForm from './GeneralForm';
import SocialForm from './SocialForm';
import InfrastructureForm from './InfrastructureForm';
import DataroomPreview from './DataroomPreview';

const DataroomEditModal = ({ show, handleClose, dataroom, onSave, isProcessing, error }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    logo_path: '',
    color_code: '#ffffff',
    region: 'eu-central-1',
    owner_id: '',
    slack_url: '',
    teams_url: '',
    visibility: 'Private',
    container_name: ''
  });

  useEffect(() => {
    if (dataroom) {
      setFormData({
        name: dataroom.name || '',
        description: dataroom.description || '',
        logo_path: dataroom.logo_path || '',
        color_code: dataroom.color_code || '#ffffff',
        region: dataroom.region || 'eu-central-1',
        owner_id: dataroom.owner_id || '',
        slack_url: dataroom.slack_url || '',
        teams_url: dataroom.teams_url || '',
        visibility: dataroom.visibility || 'Private',
        container_name: dataroom.container_name || ''
      });
    }
  }, [dataroom]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const isFormValid = () => {
    return formData.name.trim() !== '' && 
           formData.description.trim() !== '' && 
           formData.region.trim() !== '' &&
           formData.container_name.trim() !== '';
  };

  return (
    <Modal show={show} onHide={handleClose} fullscreen={true} className="edit-modal modal-fullscreen">
      <Modal.Header>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs="auto">
              <Button variant="link" onClick={handleClose} className="p-0 text-dark">
                <i className="bi bi-x-lg"></i>
              </Button>
            </Col>
            <Col>
              <Modal.Title>{dataroom ? 'Edit Dataroom' : 'Create Dataroom'}</Modal.Title>
            </Col>
            <Col xs="auto">
              <Button 
                variant="primary" 
                onClick={handleSubmit} 
                disabled={isProcessing || !isFormValid()}
              >
                {isProcessing ? 'Processing...' : (dataroom ? 'Save Changes' : 'Create Dataroom')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          <Row>
            <Col lg={8}>
              <GeneralForm formData={formData} handleChange={handleChange} />
              <SocialForm formData={formData} handleChange={handleChange} />
              <InfrastructureForm formData={formData} handleChange={handleChange} />
            </Col>
            <Col lg={4}>
              <DataroomPreview formData={formData} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default DataroomEditModal;