export const findFolderById = (folders, idToFind) => {
    let hierarchy = []; 
  
    function searchInFolders(foldersList, currentHierarchy) {
      for (const folder of foldersList) {
        const newHierarchy = currentHierarchy.concat(folder.name);
  
        if (String(folder.id) === String(idToFind)) {
          return { selectedFolder: folder, folderHierarchy: newHierarchy };
        }
        if (folder.subcategories) {
          const result = searchInFolders(folder.subcategories, newHierarchy);
          if (result) return result; 
        }
      }
      return null;
    }
  
    return searchInFolders(folders, hierarchy); 
};