import { useState, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useUserGroups = (token, dataroom_id, setError) => {
    const [allUsers, setAllUsers] = useState([]);

    const loadUsers = useCallback(async () => {
        if (!token) return;

        try {
            const response = await api.get(API_ROUTES.FETCH_GROUP_USERS(dataroom_id));
            setAllUsers(response.data);
        } catch (error) {
            setError("There was an error fetching the users");
        } 
    }, [token, dataroom_id, setError]);

    const removeUserFromGroups = useCallback(async (userId) => {
        if (!token) return;
    
        try {
          const response = await api.delete(API_ROUTES.REMOVE_USER_FROM_GROUPS(dataroom_id, userId));
          setAllUsers(prevUsers => prevUsers.filter(user => user.user_id !== userId));
          return response.data; 
        } catch (error) {
          setError("There was an error removing the user from all groups: " + error.message);
          throw error; 
        }
      }, [token, dataroom_id, setAllUsers, setError]);

    return { allUsers, loadUsers, removeUserFromGroups };
};
