import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Table } from "react-bootstrap";
import { DataRoomContext } from '../DisplayDataRoom';

import GenericModal from '../../general/GenericModal';
import LayoutPageHeader from '../../general/LayoutPageHeader';

import DocumentBreadcrumb from './DocumentBreadcrumb';
import TableHeader from './table/TableHeader';
import AddDocument from './AddDocument';
import FolderRow from './table/FolderRow';
import FileRow from './table/FileRow';

import { useNavigate, useParams } from 'react-router-dom';

import { useUser } from '../../general/UserProvider';
import { useFolders } from '../../hooks/documents/useFolders';
import CustomFileViewer from '../../general/CustomFileViewer';
import { useFolderParents } from '../../hooks/documents/useFolderParents';
import { useCreateFolder } from '../../hooks/documents/useCreateFolder';  
import { useUpdateFolder } from '../../hooks/documents/useUpdateFolder';
import { useDeleteFolder } from '../../hooks/documents/useDeleteFolder';
import { useFetchFiles } from '../../hooks/documents/useFetchFiles';
import { useFetchFileDetails } from '../../hooks/documents/useFetchFileDetails';
import { useDeleteFile } from '../../hooks/documents/useDeleteFile';
import { useUpdateFile } from '../../hooks/documents/useUpdateFile';
import { usePermissions } from '../../hooks/permissions/useUserPermissions';

import './Documents.css';

const rootFolder = {
    id: 0,
    uuid: "None",
    name: 'Root',
    subcategories: []
};

const findFolderByUuid = (uuid, folders) => {
    for (let folder of folders) {
        if (folder.uuid === uuid) {
            return folder;
        }
        if (folder.subcategories && folder.subcategories.length > 0) {
            const foundSubFolder = findFolderByUuid(uuid, folder.subcategories);
            if (foundSubFolder) return foundSubFolder;
        }
    }
    return null;
};

function updateCurrentFolder({
    foundFolder,
    setCurrentFolder,
    refetch,
    refetchParents,
    setIsInitializingFolders,
    navigate,
    dataroom_id,
    allowRedirect = true
}) {
    if (foundFolder) {
        setCurrentFolder(foundFolder);
        refetch(foundFolder.id);
        refetchParents(foundFolder.id);
        setIsInitializingFolders(false);
    } else {
        if (allowRedirect) {
            navigate(`/dataroom/${dataroom_id}/documents/`);
        }
        setIsInitializingFolders(false);
    }
}



/**
 * Represents a component for managing documents in a data room.
 */
function Documents() {
    const { token } = useUser();
    const navigate = useNavigate();
    const { folderUuid } = useParams();
    const { fileUuid } = useParams();
    const { permissions } = usePermissions();
    const dataroom_id = useContext(DataRoomContext);
    const fileInputRef = React.useRef(null);
    const [sortOrder, setSortOrder] = useState('ascending');
    const [sortedColumn, setSortedColumn] = useState('name');
    const [globalError, setGlobalError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [modalProperties, setModalProperties] = useState({
        title: "Error",
        content: "Oops, I can see an error.",
        isOpen: false,
        isFullscreen: false,
        isNoPadding: false,
        isCentered: true
    });
    const [currentFolder, setCurrentFolder] = useState(rootFolder);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [breadcrumbItems, setBreadcrumbItems] = useState([rootFolder]);

    const { folders, error, refetch } = useFolders(token, dataroom_id, false, null, 'document', true);
    const { parents, loading: loadingParents, error: errorParents, refetch: refetchParents } = useFolderParents(token, dataroom_id, currentFolder.id);
    const { createFolder, isLoading: isCreatingFolder, error: createFolderError } = useCreateFolder(token);
    const { updateFolder, isLoading: isUpdatingFolder, error: updateFolderError } = useUpdateFolder(token);
    const { deleteFolder, isLoading: isDeletingFolder, error: deleteFolderError } = useDeleteFolder(token);
    const { files, isLoading: isFetchingFiles, refetchFiles } = useFetchFiles(currentFolder.id);
    const { fileDetails, refetchFileDetails } = useFetchFileDetails(null);
    const { deleteFile, isLoading: isDeletingFile, error: deleteFileError } = useDeleteFile(token);
    const { updateFile, isLoading: isUpdatingFile, error: updateFileError } = useUpdateFile(token);
    const [isInitializingFolders, setIsInitializingFolders] = useState(true);
    const [ isInitializinFiles, setIsInitializingFiles] = useState(true);

    useEffect(() => {
        setIsLoading(isUpdatingFile || isCreatingFolder || isUpdatingFolder || isDeletingFolder || isDeletingFile || isFetchingFiles || loadingParents || isInitializingFolders);
    }, [isCreatingFolder, isUpdatingFolder, isDeletingFolder, isDeletingFile, isFetchingFiles, isUpdatingFile, loadingParents, isInitializingFolders]);

    useEffect(() => {
        const errors = [updateFileError, createFolderError, updateFolderError, deleteFolderError, deleteFileError, error, errorParents].filter(e => e != null);
        if (errors.length > 0) {
            setGlobalError(errors.map(e => e.message).join(', '));
        }
    }, [createFolderError, updateFolderError, deleteFolderError, deleteFileError, updateFileError, error, errorParents]);

    useEffect(() => {
        if (!isFetchingFiles && files) {
            setCurrentFiles(files);
        }
    }, [isFetchingFiles, files]);

    const updateToRootFolder = useCallback(() => {
        const rootWithFolders = {
            ...rootFolder,
            subcategories: folders, 
        };
        setCurrentFolder(rootWithFolders);
    }, [folders]);

    useEffect(() => {   
        if (currentFolder.id === 0) {
            updateToRootFolder();
        }
    }, [folders, currentFolder.id, updateToRootFolder]);

    useEffect(() => {
        setBreadcrumbItems(parents);
    }, [parents]); 
    
    const handleCloseModalFile = useCallback((item) => {
        setModalProperties(prevProps => ({ ...prevProps, isOpen: false }));
        navigate(`/dataroom/${dataroom_id}/documents/folder/${item.folder_uuid}`);
    }, [dataroom_id, navigate, setModalProperties]);

    const handleFileClick = useCallback((item) => {
        navigate(`/dataroom/${dataroom_id}/documents/file/${item?.uuid}`);
    
        setModalProperties(prevProps => ({
            ...prevProps,
            title: item.name,
            content: <CustomFileViewer fileurl={item.presigned_url} />,
            isOpen: true,
            isFullscreen: true,
            isNoPadding: true,
            onHide: () => handleCloseModalFile(item)
        }));
    }, [dataroom_id, navigate, setModalProperties, handleCloseModalFile]);

    const handleFolderClick = (folder) => {
        setCurrentFolder(folder);
        setCurrentFiles([]);
        refetch(folder.id); 
        refetchParents(folder.id);
        if (folder.id === 0) {
            updateToRootFolder();
            navigate(`/dataroom/${dataroom_id}/documents/`); 
        }
        else {
            navigate(`/dataroom/${dataroom_id}/documents/folder/${folder.uuid}`); 
        }
        setBreadcrumbItems(parents);
    };
    
    useEffect(() => {    
        if (folders.length > 0 && isInitializingFolders) {
            if (folderUuid) {
                refetch(0, (error, refetchedFolders) => {
                    if (!error) {
                        const foundFolder = findFolderByUuid(folderUuid, refetchedFolders);
                        updateCurrentFolder({
                            foundFolder,
                            setCurrentFolder,
                            refetch,
                            refetchParents,
                            setIsInitializingFolders,
                            navigate,
                            dataroom_id
                        });
                    } else {
                        console.error("Error:", error);
                    }
                });
            } else {
                setIsInitializingFolders(false); 
            }
        }
    }, [folderUuid, folders, isInitializingFolders, refetch, refetchParents, dataroom_id, navigate]);

    useEffect(() => {
        if (fileUuid && isInitializinFiles) {
            refetchFileDetails(fileUuid, (error, details) => {
                if (!error) {
                    setIsInitializingFiles(false);
                    handleFileClick(details);
                    refetch(0, (error, refetchedFolders) => {
                        const foundFolder = findFolderByUuid(details.folder_uuid, refetchedFolders || []);
                        updateCurrentFolder({
                            foundFolder,
                            setCurrentFolder,
                            refetch,
                            refetchParents,
                            setIsInitializingFolders,
                            navigate,
                            dataroom_id,
                            allowRedirect: false
                        });
                    });
                } else {
                    console.error("Error:", error);
                }
            });
        }
    }, [fileUuid, fileDetails, folders, refetch, refetchParents, dataroom_id, navigate, handleFileClick, isInitializinFiles, refetchFileDetails]);

    const sortedFolders = useMemo(() => {
        return currentFolder.subcategories ? [...currentFolder.subcategories].sort((a, b) => {
            let comparisonResult;

            switch (sortedColumn) {
                case 'name':
                    comparisonResult = a.name.localeCompare(b.name);
                    break;
                case 'created_at': 
                    comparisonResult = new Date(a.created_at) - new Date(b.created_at);
                    break;
                default:
                    comparisonResult = a.name.localeCompare(b.name);
            }
            return sortOrder === 'ascending' ? comparisonResult : -comparisonResult;
        }) : [];
    }, [currentFolder.subcategories, sortedColumn, sortOrder]);

    const sortedFiles = useMemo(() => {
        return currentFiles.length > 0 ? [...currentFiles].sort((a, b) => {
            let comparisonResult;

            switch (sortedColumn) {
                case 'name':
                    comparisonResult = a.name.localeCompare(b.name);
                    break;
                case 'created_at': 
                    comparisonResult = new Date(a.created_at) - new Date(b.created_at);
                    break;
                default:
                    comparisonResult = a.name.localeCompare(b.name);
            }
            return sortOrder === 'ascending' ? comparisonResult : -comparisonResult;
        }) : [];
    }, [currentFiles, sortedColumn, sortOrder]);

    const documentActions = (
        <div className="action-buttons">
            <AddDocument 
                token={token}
                dataroomId={dataroom_id} 
                refetchFiles={refetchFiles} 
                fileInputRef={fileInputRef} 
                currentFolder={currentFolder}
                permissions={permissions}
                setModalProperties={setModalProperties}
                createFolder={createFolder}
                setCurrentFolder={setCurrentFolder}
            />
        </div>
    );

    return (
        <LayoutPageHeader title="Documents" actions={documentActions}>
            {currentFolder && (
                <>
                <DocumentBreadcrumb breadcrumbItems={breadcrumbItems} handleBreadcrumbClick={handleFolderClick} />
                <Table striped bordered hover className="documents-table">
                    <TableHeader 
                        sortOrder={sortOrder} 
                        setSortOrder={setSortOrder} 
                        sortedColumn={sortedColumn} 
                        setSortedColumn={setSortedColumn}
                    />
                    <tbody>
                    {sortedFolders.length > 0 || sortedFiles.length > 0 || isLoading ? (
                        <>
                        {!isInitializingFolders && sortedFolders.map((item) => (
                            <FolderRow 
                                key={"FoR_" + item.id}
                                dataroom_id={dataroom_id}
                                item={item}
                                setModalProperties={setModalProperties}
                                deleteFolder={deleteFolder}
                                permissions={permissions}
                                setCurrentFolder={setCurrentFolder}
                                updateFolder={updateFolder}
                                refetch={refetch}
                                handleFolderClick={handleFolderClick}
                            />
                        ))}
                        {!isInitializingFolders && !isLoading && sortedFiles.map((file) => (
                            <FileRow
                                key={"FiR_" + file.id}
                                item={file}
                                setModalProperties={setModalProperties}
                                handleFileClick={handleFileClick}
                                updateFile={updateFile}
                                refetchFiles={refetchFiles}
                                currentFolder={currentFolder}
                                deleteFile={deleteFile}
                            />
                        ))}
                        {isLoading && (
                            <tr>
                                <td colSpan="4" className="text-center">
                                    <div className="loading-spinner">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                        </>
                    ) : (
                        <tr>
                            <td colSpan="4">
                                <div className="empty-folder-message">
                                    {globalError && <p className="error-message">Error: {globalError}</p>}
                                    {!globalError && <div>This folder is empty.</div>}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                </>
            )}
            
            <GenericModal
                title={modalProperties.title}
                show={modalProperties.isOpen}
                onHide={modalProperties.onHide || (() => setModalProperties(prevProps => ({ ...prevProps, isOpen: false })))}
                fullscreen={modalProperties.isFullscreen}
                centered={modalProperties.isCentered}
                no_padding={modalProperties.isNoPadding}
            >
                {modalProperties.content}
            </GenericModal>
        </LayoutPageHeader>
    );
};

export default Documents;