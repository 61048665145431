import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

function useCommentCount(objectId, objectType) {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const fetchCommentCount = async () => {
            if (!objectId) return;

            try {
                const response = await api.get(API_ROUTES.NOTE_COMMENT_COUNT, {
                    params: {
                        object_id: objectId,
                        object_type: objectType
                    }
                });

                setCount(response.data.count);
            } catch (error) {
                console.error("Failed to fetch comment count:", error);
            }
        };

        fetchCommentCount();
    }, [objectId, objectType]);

    return [count, setCount];
}

export default useCommentCount;
