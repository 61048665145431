import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddCycle = (token) => {
    const [adding, setAdding] = useState(false);
    const [addError, setAddError] = useState(null);

    const addCycle = async (cycleData) => {
        setAdding(true);
        setAddError(null);

        try {
            const response = await api.post(
                API_ROUTES.ADMIN_ADD_CYCLES,
                cycleData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setAdding(false);
            return response.data;
        } catch (error) {
            setAddError(error.response?.data?.message || 'An error occurred while adding the cycle');
            setAdding(false);
            throw error;
        }
    };

    return { addCycle, adding, addError };
};

export const useUpdateCycle = (token) => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const updateCycle = async (cycleData) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            const response = await api.put(
                `${API_ROUTES.ADMIN_UPDATE_CYCLES}/${cycleData.id}`,
                cycleData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setUpdating(false);
            return response.data;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the cycle');
            setUpdating(false);
            throw error;
        }
    };

    return { updateCycle, updating, updateError };
};

export const useDeleteCycle = (token) => {
    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const deleteCycle = async (cycleId) => {
        setDeleting(true);
        setDeleteError(null);

        try {
            await api.delete(
                `${API_ROUTES.ADMIN_DELETE_CYCLES}/${cycleId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setDeleting(false);
        } catch (error) {
            setDeleteError(error.response?.data?.message || 'An error occurred while deleting the cycle');
            setDeleting(false);
            throw error;
        }
    };

    return { deleteCycle, deleting, deleteError };
};

export const useFetchCycles = (token) => {
    const [cycles, setCycles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCycles = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await api.get(
                API_ROUTES.ADMIN_GET_CYCLES,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            // Trier les cycles par ordre
            const sortedCycles = response.data.sort((a, b) => a.order - b.order);
            setCycles(sortedCycles);
            setLoading(false);
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while fetching audit cycles');
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        fetchCycles();
    }, [fetchCycles]);

    return { cycles, setCycles, loading, error, refetch: fetchCycles };
};

// Nouveau hook pour la réorganisation des cycles
export const useReorderCycles = (token) => {
    const [reordering, setReordering] = useState(false);
    const [reorderError, setReorderError] = useState(null);

    const reorderCycles = async (newOrder) => {
        setReordering(true);
        setReorderError(null);

        try {
            const response = await api.post(
                API_ROUTES.ADMIN_REORDER_CYCLES,
                { order: newOrder },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setReordering(false);
            return response.data.cycles;
        } catch (error) {
            setReorderError(error.response?.data?.message || 'An error occurred while reordering the cycles');
            setReordering(false);
            throw error;
        }
    };

    return { reorderCycles, reordering, reorderError };
};