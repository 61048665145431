import React, { useState, useRef } from 'react';
import { Modal, Button, Spinner, ProgressBar } from 'react-bootstrap';
import { useCreateFile } from '../../../hooks/documents/useCreateFile';
import './UploadSpecificDocumentModal.css';

const UploadSpecificDocumentModal = ({ show, onClose, documentInfo, onUpload, token, dataroomId, onRefetchData }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({ progress: 0, stage: '' });
  const [isDragging, setIsDragging] = useState(false);
  const { createFile, isLoading, error } = useCreateFile(token, dataroomId);
  const dropZoneRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleFileSelect = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setUploadProgress({ progress: 0, stage: '' });
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Vérifier si le curseur quitte réellement la zone de drop
    const rect = dropZoneRef.current?.getBoundingClientRect();
    if (rect) {
      const { left, right, top, bottom } = rect;
      if (
        e.clientX >= left &&
        e.clientX <= right &&
        e.clientY >= top &&
        e.clientY <= bottom
      ) {
        return;
      }
    }
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      setUploadProgress({ progress: 0, stage: '' });
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    try {
        const progressCallback = (progress, stage) => {
            setUploadProgress({ progress, stage });
        };

        const uploadOptions = {
          folderId: documentInfo.folder,
          requestedDocumentId: documentInfo.requestedDocumentId
        };

        const response = await createFile(selectedFile, uploadOptions, progressCallback);
        onUpload(response);
        setSelectedFile(null);
        onRefetchData();
        onClose();
    } catch (err) {
        console.error('Error uploading file:', err);
    }
  };

  const handleZoneClick = () => {
    if (!isLoading) {
      fileInputRef.current?.click();
    }
  };

  return (
    <Modal 
      show={show} 
      onHide={onClose}
      centered
      backdrop="static"
      size="lg"
      className="dm-modal"
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title className="d-flex align-items-center">
          <i className="bi bi-cloud-upload modal-title-icon"></i>
          Upload {documentInfo?.name}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-3">
        <div className="mb-4">
          <h5>Document Description</h5>
          <p>{documentInfo?.description}</p>
        </div>

        <div className="bg-light p-4 rounded">
          <div
            ref={dropZoneRef}
            onClick={handleZoneClick}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            className={`upload-zone ${isDragging ? 'dragging' : ''} ${isLoading ? 'upload-zone-loading' : ''}`}
          >
            <i className={`bi bi-file-earmark-arrow-up upload-icon`}></i>
            <h4 className="mt-3">Upload Your Document</h4>
            <p className={`description-text ${selectedFile ? 'selected-file' : ''}`}>
              {selectedFile 
                ? `Selected: ${selectedFile.name}`
                : 'Drag and drop your file here or click to browse'
              }
            </p>

            <input
              ref={fileInputRef}
              type="file"
              className="file-input"
              accept=".pdf,.xls,.xlsx,.csv,.doc,.docx,.pptx,.ppt,.txt"
              onChange={handleFileSelect}
              disabled={isLoading}
            />
          </div>

          {error && (
            <div className="alert alert-danger alert-container">
              <i className="bi bi-exclamation-triangle-fill me-2"></i>
              {error.message || 'An error occurred during upload'}
            </div>
          )}

          {(uploadProgress.progress > 0 || isLoading) && (
            <div className="progress-container">
              <div className="progress-status">
                <h6 className="mb-0">Status: {uploadProgress.stage || 'Preparing...'}</h6>
                <span>{uploadProgress.progress}%</span>
              </div>
              <ProgressBar 
                now={uploadProgress.progress} 
                variant="primary"
                animated={uploadProgress.progress < 100}
              />
            </div>
          )}

          <div className="alert alert-info alert-container">
            <div className="d-flex align-items-start">
              <i className="bi bi-info-circle-fill me-2 mt-1"></i>
              <div>
                <strong>Important notes:</strong>
                <ul className="info-list">
                  <li>Make sure your document follows the required format</li>
                  <li>Maximum file size: 50MB</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button 
          variant="outline-secondary" 
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={handleUpload}
          disabled={!selectedFile || isLoading}
        >
          {isLoading ? (
            <>
              <Spinner 
                as="span" 
                animation="border" 
                size="sm" 
                role="status" 
                className="me-2" 
              />
              {uploadProgress.stage || 'Processing...'}
            </>
          ) : (
            'Upload'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadSpecificDocumentModal;