import React from 'react';
import { Card, Tabs, Tab } from 'react-bootstrap';

import TemplateFoldersInfo from './Folders/FoldersInfo';
import TemplateCyclesInfo from './Cycles/CyclesInfo';
import ControlsInfo from './Controls/ControlsInfo';
import AssertionsInfo from './Assertions/AssertionsInfo';
import DocTypesInfo from './DocTypes/DocTypesInfo';
import DocAttributesInfo from './DocAttributes/DocAttributesInfo';

const TemplateManagement = ({ token }) => {

  const renderTemplateContent = () => (
    <Tabs defaultActiveKey="folders" className="mb-3">
        <Tab eventKey="folders" title="Folders">
            <TemplateFoldersInfo
              token={token} 
            />
        </Tab>
        <Tab eventKey="cycles" title="Cycles">
            <TemplateCyclesInfo token={token} />
        </Tab>
        <Tab eventKey="controls" title="Controls">
            <ControlsInfo token={token} />
        </Tab>
        <Tab eventKey="assertions" title="Assertions">
            <AssertionsInfo token={token} />
        </Tab>
        <Tab eventKey="docTypes" title="Document Types">
            <DocTypesInfo token={token} />
        </Tab>
        <Tab eventKey="docAttributes" title="Document Attributes">
            <DocAttributesInfo token={token} />
        </Tab>
    </Tabs>
  );

  return (
    <Card className="admin_card shadow-sm">
      <Card.Body>
        <h3>Template Management</h3>
        {renderTemplateContent()}
      </Card.Body>
    </Card>
  );
};

export default TemplateManagement;