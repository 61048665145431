import React from 'react';
import { Form } from 'react-bootstrap';

const InfrastructureForm = ({ formData, handleChange }) => (
  <>
    <h2 className="title-h2 font-title m-3">Infrastructure</h2>
    <div className="px-4">
      <Form.Group className="mb-3">
        <Form.Label><i className="bi bi-globe-americas icon-spacing-10"></i> Region</Form.Label>
        <Form.Select
          name="region"
          value={formData.region}
          onChange={handleChange}
          required
        >
          <option value="eu-central-1">france-central-1</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label><i className="bi bi-card-text icon-spacing-10"></i> Container Name</Form.Label>
        <Form.Control
          name="container_name"
          value={formData.container_name}
          onChange={handleChange}
          placeholder="prod-22-1702474323"
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Visibility</Form.Label>
        <Form.Select
          name="visibility"
          value={formData.visibility}
          onChange={handleChange}
        >
          <option value="Private">Private</option>
          <option value="Public">Public</option>
        </Form.Select>
      </Form.Group>
    </div>
  </>
);

export default InfrastructureForm;