import { useState, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useUpdateUserInfo = (token) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);

    const updateUserInfo = useCallback(async (dataroomId, userId, userInfo) => {
        setIsLoading(true);
        setError(null);

        try {
            if (!token) {
                throw new Error("Token is missing");
            }
            const payload = {
                user_id: userId,
                dataroom_id: dataroomId,
                ...userInfo
            };

            const res = await api.put(API_ROUTES.UPDATE_USER_INFO(dataroomId, userId), payload, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setResponse(res.data);
        } catch (err) {
            console.log(err)
            setError(err.response?.data?.error || "An unexpected error occurred while updating user info");
        } finally {
            setIsLoading(false);
        }
    }, [token]);

    return { updateUserInfo, isLoading, error, response };
};