import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFetchControlPoints = (token, dataroomId, categoryIds) => {
    const [controlPoints, setControlPoints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchControlPoints = async () => {
            if (!dataroomId) {
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                let response;
                if (categoryIds && categoryIds.length > 0) {
                    response = await api.get(API_ROUTES.GET_CONTROL_POINTS_CATEGORY(dataroomId), {
                        params: { category_ids: categoryIds.join(',') },
                        headers: { Authorization: `Bearer ${token}` }
                    });
                } else {
                    response = await api.get(API_ROUTES.GET_CONTROL_POINTS_ALL(dataroomId), {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                }
                console.log("Control points response:", response.data);
                setControlPoints(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch control points:", error);
                setError(error.message || "Error fetching control points");
                setLoading(false);
            }
        };

        fetchControlPoints();
    }, [dataroomId, categoryIds, token]);

    return { controlPoints, loading, error };
};