import React from 'react';
import { Button, Form } from 'react-bootstrap';

export const ProcedureDescription = ({ 
    description,
    isEditing,
    editedDescription,
    onStartEditing,
    onSave,
    onCancel,
    onChange,
    updating
}) => {
    return (
        <section className="description-section">
            <div className="d-flex align-items-center gap-1">
                <h2 className="section-title-left mb-0">Description</h2>
                <Button 
                    variant="link" 
                    onClick={onStartEditing}
                    className="edit-button p-0 d-flex align-items-center"
                    disabled={updating}
                >
                    <i className="bi bi-pencil edit-icon"></i>
                </Button>
            </div>
            {isEditing ? (
                <div className="edit-container w-100">
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={editedDescription}
                        onChange={onChange}
                        className="mb-2"
                        placeholder="Enter procedure description..."
                    />
                    <div className="edit-actions">
                        <Button 
                            variant="primary" 
                            onClick={onSave} 
                            className="me-2"
                            disabled={updating}
                            size="sm"
                        >
                            {updating ? 'Saving...' : 'Save'}
                        </Button>
                        <Button 
                            variant="secondary" 
                            onClick={onCancel}
                            disabled={updating}
                            size="sm"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            ) : (
                <p className="multiline-text">{description || 'No description available.'}</p>
            )}
        </section>
    );
};

export default ProcedureDescription;