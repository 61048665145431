import React, {useState, useEffect } from 'react';

import { Form, Button } from 'react-bootstrap';

function ConfGeneralForm({ dataroomDetails, onUpdateDataroom }) {
    
    const [formData, setFormData] = useState({
        name: dataroomDetails.name || '',
        logoPath: dataroomDetails.logo_path || '',
        description: dataroomDetails.description || '',
        colorCode: dataroomDetails.color_code || '#000000',
        slack_url: dataroomDetails.slack_url || '',
        teams_url: dataroomDetails.teams_url || '',
        region: dataroomDetails.region ?? '',
        container_name: dataroomDetails.container_name ?? ''
    });

    useEffect(() => {
        setFormData({
            name: dataroomDetails?.name ?? '',
            logoPath: dataroomDetails?.logo_path ?? '',
            description: dataroomDetails?.description ?? '',
            colorCode: dataroomDetails?.color_code ?? '#000000',
            slack_url: dataroomDetails?.slack_url ?? '',
            teams_url: dataroomDetails?.teams_url ?? '',
            region: dataroomDetails?.region ?? '',
            container_name: dataroomDetails?.container_name ?? ''
        });
    }, [dataroomDetails]);
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onUpdateDataroom(formData);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h2 className="title-h2 font-title mx-3">General</h2>
            <div className="px-4">
                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control 
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Due dilligence of OpenAI" 
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Color Code</Form.Label>
                    <Form.Control 
                        name="colorCode"
                        type="color"  
                        value={formData.colorCode}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Logo path</Form.Label>
                    <Form.Control 
                        name="logoPath"
                        value={formData.logoPath}
                        onChange={handleChange}
                        placeholder="https://logo.com/my-logo.png" 
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control 
                        name="description"
                        as="textarea" 
                        rows={3} 
                        value={formData.description}
                        onChange={handleChange}
                        placeholder="This data room contains all documents related to the due dilligence of OpenAI"
                    />
                </Form.Group>
            </div>
            <h2 className="title-h2 font-title m-3">Social</h2>
            <div className="px-4">
                <Form.Group className="mb-3">
                    <Form.Label><i className="bi bi-slack icon-spacing-10"></i> Slack</Form.Label>
                    <Form.Control 
                        name="slack_url"
                        value={formData.slack_url}
                        onChange={handleChange}
                        placeholder="https://yourworkspace.slack.com/archives/C01A9V5LPDQ" 
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label><i className="bi bi-microsoft-teams icon-spacing-10"></i> Teams</Form.Label>
                    <Form.Control 
                        name="teams_url"
                        value={formData.teams_url}
                        onChange={handleChange}
                        placeholder="https://teams.microsoft.com/l/channel/193a0d9e9f9a3dd4474db6ad8" 
                    />
                </Form.Group>
            </div>
            <h2 className="title-h2 font-title m-3">Infrastructure</h2>
            <div className="px-4">
                <Form.Group className="mb-3">
                    <Form.Label><i className="bi bi-globe-americas icon-spacing-10"></i> Region</Form.Label>
                    <Form.Control 
                        name="region"
                        value={dataroomDetails.region}
                        onChange={handleChange}
                        placeholder="us-east-1" 
                        readOnly
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label><i className="bi bi-card-text icon-spacing-10"></i> Container name</Form.Label>
                    <Form.Control 
                        name="container_name"
                        value={dataroomDetails.container_name}
                        onChange={handleChange}
                        placeholder="prod-22-1702474323"
                        readOnly 
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Update
                </Button>
            </div>
        </Form>
    );
};

export default ConfGeneralForm;