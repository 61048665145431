import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Modal, Button, Form, Spinner, Alert, Container, Row, Col, Table, Badge } from 'react-bootstrap';
import { useFetchDataroomControls, useAddDataroomControl, useUpdateDataroomControl } from '../../../../hooks/admin/dataroomControlsHooks';
import { useFetchControls } from '../../../../hooks/admin/controlsHooks';
import { getPriorityVariant, getStatusVariant } from '../../../../utils/dataroomControls';
import '../../../EditModal.css';

const DataroomControlEditModal = ({ show, handleClose, dataroom, token }) => {
  const { controls, loading, error, refetch: fetchControls } = useFetchDataroomControls(token, dataroom?.dataroom_id);
  const { addControl } = useAddDataroomControl(token);
  const { updateControl } = useUpdateDataroomControl(token);
  const { controls: templates, loading: templatesLoading, error: templatesError } = useFetchControls(token, -1);

  const [selectedControl, setSelectedControl] = useState(null);
  const [filter, setFilter] = useState('');
  const [editForm, setEditForm] = useState({ 
    control_template_id: '', 
    priority: '', 
    priority_description: '', 
    status: '', 
    auditor_id: '', 
    supervisor_id: '' 
  });

  const priorityOptions = useMemo(() => [
    { value: 'Very Low', label: 'Very Low' },
    { value: 'Low', label: 'Low' },
    { value: 'Medium', label: 'Medium' },
    { value: 'High', label: 'High' },
    { value: 'Critical', label: 'Critical' },
  ], []);

  const statusOptions = useMemo(() => [
    { value: 'Missing Documents', label: 'Missing Documents' },
    { value: 'Ready', label: 'Ready' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Review', label: 'Review' },
    { value: 'Completed', label: 'Completed' },
  ], []);

  const localControls = useMemo(() => {
    if (!controls || !templates) return [];
    return controls.map(control => ({
      ...control,
      control_template: templates.find(t => t.id === control.control_template_id) || { name: 'N/A' }
    }));
  }, [controls, templates]);

  useEffect(() => {
    if (show && dataroom?.dataroom_id) {
      fetchControls();
    }
  }, [show, dataroom?.dataroom_id, fetchControls]);

  const handleControlSelect = useCallback((control) => {
    setSelectedControl(control);
    setEditForm({
      control_template_id: control.control_template_id,
      priority: control.priority,
      priority_description: control.priority_description || '',
      status: control.status,
      auditor_id: control.auditor_id || '',
      supervisor_id: control.supervisor_id || ''
    });
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setEditForm(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleAddOrUpdateControl = useCallback(async () => {
    try {
      if (selectedControl) {
        await updateControl(dataroom.dataroom_id, selectedControl.id, editForm);
      } else {
        await addControl(dataroom.dataroom_id, editForm);
      }
      fetchControls();
      setSelectedControl(null);
      setEditForm({ 
        control_template_id: '', 
        priority: '', 
        priority_description: '', 
        status: '', 
        auditor_id: '', 
        supervisor_id: '' 
      });
    } catch (error) {
      console.error("Error adding/updating control:", error);
    }
  }, [selectedControl, editForm, addControl, updateControl, fetchControls, dataroom?.dataroom_id]);

  const filteredControls = useMemo(() => 
    localControls.filter(control => 
      control.control_template?.name?.toLowerCase().includes(filter.toLowerCase()) ?? true
    ),
    [localControls, filter]
  );

  return (
    <Modal show={show} onHide={handleClose} fullscreen={true} className="edit-modal modal-fullscreen">
      <Modal.Header closeButton>
        <Modal.Title>Manage Controls - {dataroom?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {(error || templatesError) && <Alert variant="danger" className="mb-4">{error || templatesError}</Alert>}
          <Row>
            <Col md={5}>
              <h5 className="mb-3">Controls</h5>
              <Form.Control
                type="text"
                placeholder="Search controls..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="mb-3"
              />
              <div style={{maxHeight: '70vh', overflowY: 'auto'}}>
                <Table hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Priority</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredControls.map(control => (
                      <tr 
                        key={control.id} 
                        onClick={() => handleControlSelect(control)}
                        className={selectedControl?.id === control.id ? 'table-primary' : ''}
                        style={{cursor: 'pointer'}}
                      >
                        <td>{control.control_template?.name || 'N/A'}</td>
                        <td><Badge bg={getPriorityVariant(control.priority)}>{control.priority}</Badge></td>
                        <td><Badge bg={getStatusVariant(control.status)}>{control.status}</Badge></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col md={7}>
              <h5 className="mb-3">Control Management</h5>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Control Template</Form.Label>
                  <Form.Select
                    name="control_template_id"
                    value={editForm.control_template_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select a control template</option>
                    {templates.map(template => (
                      <option key={template.id} value={template.id}>{template.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Priority</Form.Label>
                  <Form.Select
                    name="priority"
                    value={editForm.priority}
                    onChange={handleInputChange}
                  >
                    <option value="">Select priority</option>
                    {priorityOptions.map(option => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Priority Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="priority_description"
                    value={editForm.priority_description}
                    onChange={handleInputChange}
                    placeholder="Enter priority description"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name="status"
                    value={editForm.status}
                    onChange={handleInputChange}
                  >
                    <option value="">Select status</option>
                    {statusOptions.map(option => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Auditor ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="auditor_id"
                    value={editForm.auditor_id}
                    onChange={handleInputChange}
                    placeholder="Enter auditor ID"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Supervisor ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="supervisor_id"
                    value={editForm.supervisor_id}
                    onChange={handleInputChange}
                    placeholder="Enter supervisor ID"
                  />
                </Form.Group>
                <Button 
                  variant="primary" 
                  onClick={handleAddOrUpdateControl}
                  disabled={loading || templatesLoading || !editForm.control_template_id || !editForm.priority || !editForm.status}
                >
                  {selectedControl ? 'Update Control' : 'Add Control'}
                </Button>
                {selectedControl && (
                  <Button 
                    variant="outline-secondary" 
                    onClick={() => {
                      setSelectedControl(null);
                      setEditForm({ 
                        control_template_id: '', 
                        priority: '', 
                        priority_description: '', 
                        status: '', 
                        auditor_id: '', 
                        supervisor_id: '' 
                      });
                    }}
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="me-auto">
          {(loading || templatesLoading) && (
            <span className="text-muted">
              <Spinner animation="border" size="sm" className="me-2" />
              Processing...
            </span>
          )}
        </div>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataroomControlEditModal;