import moment from 'moment-timezone';

export const formatDate = (dateStr) => {
    const dateUtc = moment.tz(dateStr, "UTC");
    return dateUtc.local().fromNow();
};

export const getStartOfDay = (date = new Date()) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
};

export const getOneWeekAgo = (fromDate = new Date()) => {
    const newDate = new Date(fromDate);
    newDate.setDate(newDate.getDate() - 7);
    return newDate;
};