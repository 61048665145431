import { Auth } from 'aws-amplify';

export async function fetchToken() {
    try {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
    } catch (error) {
        console.error("Error fetching token: ", error);
        throw error; 
    }
}