export const getPriorityVariant = (priority) => {
    switch (priority) {
      case 'VERY_LOW': return 'info';
      case 'LOW': return 'success';
      case 'MEDIUM': return 'warning';
      case 'HIGH': return 'danger';
      case 'CRITICAL': return 'dark';
      default: return 'secondary';
    }
  };
  
export const getStatusVariant = (status) => {
    switch (status) {
        case 'MISSING_DOCUMENTS': return 'danger';
        case 'READY': return 'info';
        case 'IN_PROGRESS': return 'warning';
        case 'REVIEW': return 'primary';
        case 'COMPLETED': return 'success';
        default: return 'secondary';
    }
};