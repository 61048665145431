import React, { useState, useCallback, useMemo } from 'react';
import { Button, Spinner, Alert, Table, OverlayTrigger, Tooltip, Form, Badge } from 'react-bootstrap';
import { formatDate } from '../../../utils/Date';
import DocAttributeEditModal from './DocAttributeEditModal';
import { 
  useAddDocumentAttribute, 
  useUpdateDocumentAttribute, 
  useDeleteDocumentAttribute, 
  useFetchDocumentAttributes 
} from '../../../hooks/admin/docAttributesHooks';
import { useFetchDocumentTypes } from '../../../hooks/admin/docTypesHooks';

const DocAttributesInfo = ({ token }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const { documentAttributes, loading: loadingAttributes, error: attributesError, refetch } = useFetchDocumentAttributes(token);
  const { documentTypes, loading: loadingTypes, error: typesError } = useFetchDocumentTypes(token);
  const { addDocumentAttribute, adding, addError } = useAddDocumentAttribute(token);
  const { updateDocumentAttribute, updating, updateError } = useUpdateDocumentAttribute(token);
  const { deleteDocumentAttribute, deleting, deleteError } = useDeleteDocumentAttribute(token);

  const handleShowModal = useCallback((attribute = null) => {
    setSelectedAttribute(attribute);
    setShowEditModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowEditModal(false);
    setSelectedAttribute(null);
  }, []);

  const handleDelete = useCallback(async (attributeId) => {
    const success = await deleteDocumentAttribute(attributeId);
    if (success) {
      refetch();
    }
  }, [deleteDocumentAttribute, refetch]);

  const handleSaveAttribute = useCallback(async (formData) => {
    try {
      if (selectedAttribute) {
        await updateDocumentAttribute({ ...formData, id: selectedAttribute.id });
      } else {
        await addDocumentAttribute(formData);
      }
      refetch();
      handleCloseModal();
    } catch (error) {
      console.error("Failed to save document attribute:", error);
    }
  }, [selectedAttribute, updateDocumentAttribute, addDocumentAttribute, refetch, handleCloseModal]);

  const filteredAttributes = useMemo(() => {
    return documentAttributes.filter(attribute =>
      attribute.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      attribute.document_types.some(dt =>
        dt.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [documentAttributes, searchTerm]);

  if (loadingAttributes || loadingTypes) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const displayError = attributesError || typesError || deleteError || updateError || addError;
  if (displayError) {
    return <Alert variant="danger">{displayError}</Alert>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>Document Attributes</h4>
        <Button 
          variant="primary" 
          onClick={() => handleShowModal()}
          disabled={adding}
        >
          <i className="bi bi-plus-lg me-2"></i>
          Add Attribute
        </Button>
      </div>

      <Form.Group className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search by name or document type"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Form.Group>

      {filteredAttributes.length === 0 ? (
        <Alert variant="info">No attributes found.</Alert>
      ) : (
        <Table hover responsive className="admin_table-nowrap">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Document Type</th>
              <th>Description</th>
              <th>Data Type</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredAttributes.map((attribute) => (
              <tr key={attribute.id}>
                <td>{attribute.id}</td>
                <td>{attribute.name}</td>
                <td>
                  <Badge bg="secondary">
                    {attribute.document_types[0]?.name || 'Not assigned'}
                  </Badge>
                </td>
                <td>{attribute.description}</td>
                <td>{attribute.data_type}</td>
                <td>{formatDate(attribute.created_at)}</td>
                <td>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Edit attribute</Tooltip>}>
                    <Button 
                      variant="outline-secondary" 
                      size="sm" 
                      className="me-2" 
                      onClick={() => handleShowModal(attribute)}
                    >
                      <i className="bi bi-pencil"></i>
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Delete attribute</Tooltip>}>
                    <Button 
                      variant="outline-danger" 
                      size="sm" 
                      onClick={() => handleDelete(attribute.id)}
                      disabled={deleting}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <DocAttributeEditModal
        show={showEditModal}
        handleClose={handleCloseModal}
        attribute={selectedAttribute}
        documentTypes={documentTypes}
        onSave={handleSaveAttribute}
        isProcessing={updating || adding}
        error={updateError || addError}
      />
    </div>
  );
};

export default DocAttributesInfo;