import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form, Spinner, Alert, Container, Row, Col, Table, Badge } from 'react-bootstrap';
import { useDataroomGroups } from '../../../../hooks/admin/useDataroomGroups';
import '../../../EditModal.css';

const DataroomGroupEditModal = ({ show, handleClose, dataroom, token }) => {
  const { groups, loading, error, fetchGroups, addGroup, deleteGroup, updateGroup, fetchFolders } = useDataroomGroups(token, dataroom?.dataroom_id);
  const [localGroups, setLocalGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [folders, setFolders] = useState([]);
  const [filter, setFilter] = useState('');
  const [editForm, setEditForm] = useState({ name: '', description: '', folder_id: '' });


  useEffect(() => {
    setLocalGroups(groups);
  }, [groups]);

  const loadFolders = useCallback(async () => {
    try {
      const folderList = await fetchFolders();
      const sortedFolders = folderList.sort((a, b) => {
        const depthDiff = a.full_path.split('/').length - b.full_path.split('/').length;
        return depthDiff !== 0 ? depthDiff : a.full_path.localeCompare(b.full_path);
      });
      setFolders(sortedFolders);
    } catch (error) {
      console.error("Error loading folders:", error);
    }
  }, [fetchFolders]);

  useEffect(() => {
    if (show && dataroom) {
      fetchGroups();
      loadFolders();
    }
  }, [show, dataroom, fetchGroups, loadFolders]);

  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
    setEditForm({
      name: group.group_name,
      description: group.description,
      folder_id: group.folder_id || ''
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditForm(prev => ({ ...prev, [name]: value }));
  };

  const handleAddOrUpdateGroup = async () => {
    try {
      const folder_id = editForm.folder_id === '' ? null : editForm.folder_id;
      if (selectedGroup) {
        await updateGroup(selectedGroup.group_id, editForm.name, editForm.description, folder_id);
      } else {
        await addGroup(editForm.name, editForm.description, folder_id);
      }
      fetchGroups();
      setSelectedGroup(null);
      setEditForm({ name: '', description: '', folder_id: '' });
    } catch (error) {
      console.error("Error adding/updating group:", error);
    }
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      await deleteGroup(groupId);
      fetchGroups();
      if (selectedGroup && selectedGroup.group_id === groupId) {
        setSelectedGroup(null);
        setEditForm({ name: '', description: '', folder_id: '' });
      }
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };

  const filteredGroups = localGroups.filter(group => 
    group.group_name.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <Modal show={show} onHide={handleClose} fullscreen={true} className="edit-modal modal-fullscreen">
      <Modal.Header closeButton>
        <Modal.Title>Manage Groups - {dataroom?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          <Row>
            <Col md={5}>
              <h5 className="mb-3">Groups</h5>
              <Form.Control
                type="text"
                placeholder="Search groups..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="mb-3"
              />
              <div style={{maxHeight: '70vh', overflowY: 'auto'}}>
                <Table hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Folder</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredGroups.map(group => (
                      <tr 
                        key={group.group_id} 
                        onClick={() => handleGroupSelect(group)}
                        className={selectedGroup?.group_id === group.group_id ? 'table-primary' : ''}
                        style={{cursor: 'pointer'}}
                      >
                        <td>{group.group_name}</td>
                        <td>{group.folder_name || <Badge bg="light" text="dark">No folder</Badge>}</td>
                        <td>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteGroup(group.group_id);
                            }}
                          >
                            <i className="bi bi-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col md={7}>
              <h5 className="mb-3">Group Management</h5>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={editForm.name}
                    onChange={handleInputChange}
                    placeholder="Enter group name"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={editForm.description}
                    onChange={handleInputChange}
                    placeholder="Enter group description"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Folder</Form.Label>
                  <Form.Select
                    name="folder_id"
                    value={editForm.folder_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select a folder</option>
                    {folders.map(folder => (
                      <option key={folder.id} value={folder.id}>{folder.full_path}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Button 
                  variant="primary" 
                  onClick={handleAddOrUpdateGroup}
                  disabled={loading || !editForm.name.trim()}
                >
                  {selectedGroup ? 'Update Group' : 'Add Group'}
                </Button>
                {selectedGroup && (
                  <Button 
                    variant="outline-secondary" 
                    onClick={() => {
                      setSelectedGroup(null);
                      setEditForm({ name: '', description: '', folder_id: '' });
                    }}
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="me-auto">
          {loading && (
            <span className="text-muted">
              <Spinner animation="border" size="sm" className="me-2" />
              Processing...
            </span>
          )}
        </div>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataroomGroupEditModal;