import React, { useCallback } from 'react';

import CommentSection from '../../general/CommentSection';
import FolderActions from './FolderActions';
import useCommentCount from '../../../hooks/comments/useCommentCount';
import { formatDate } from '../../../utils/Date';

function FolderRow({ item, setModalProperties, deleteFolder, setCurrentFolder, updateFolder, refetch, handleFolderClick }) {
    const canComment = item?.permissions?.Comment;
    const [commentCount, setCommentCount] = useCommentCount(item.id, "Folder");

    const updateCommentCount = useCallback((delta) => {
        setCommentCount(prevCount => prevCount + delta);
    }, [setCommentCount]);

    const handleComments = () => {
        setModalProperties(prevProps => ({
            ...prevProps,
            title: "Comments",
            content: <CommentSection 
                objectId={item.id} 
                objectType="Folder" 
                onCommentChange={updateCommentCount}
                canComment={canComment}
            />,
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false
        }));
    };


    return (
        <tr className="tableRow">
            <td onClick={() => handleFolderClick(item)} className='cursorHover'>
                <i className={item.is_category ? "bi bi-folder-check icon-spacing" : "bi bi-folder icon-spacing"}></i>
                {item.name}
            </td>
            <td>{formatDate(item.created_at)}</td>
            <td>
                <button 
                    className="btn btn-link a-no-css p-0" 
                    onClick={() => handleComments(item)}
                >
                    {commentCount} comment{commentCount !== 1 ? 's' : ''}
                </button>
            </td>
            <td>
                <FolderActions
                    item={item}
                    handleComments={handleComments}
                    commentCount={commentCount}
                    setModalProperties={setModalProperties}
                    deleteFolder={deleteFolder}
                    setCurrentFolder={setCurrentFolder}
                    updateFolder={updateFolder}
                    refetch={refetch}
                />
            </td>
        </tr>
    );
};

export default FolderRow;