// hooks/team/useRoles.js

import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useRoles = (token, dataroomId) => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const fetchRoles = async () => {
            if (!token || !dataroomId) return;

            try {
                const response = await api.get(API_ROUTES.ROLES_BY_DATAROOM(dataroomId), {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (isMounted) {
                    setRoles(response.data);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error.message || "Error fetching roles");
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchRoles();

        return () => {
            isMounted = false;
        };
    }, [token, dataroomId]);

    return { roles, loading, error };
};