import React, { useEffect, useRef } from 'react';
import { FormControl, Button } from 'react-bootstrap';

import SimpleMDE from 'simplemde';
import "simplemde/dist/simplemde.min.css";

function ContentEditor({ isEditing, type, edited, handleSave, handleCancelEdit, setEdited, originalValue }) {
    const simpleMdeRef = useRef(null);
    const simpleMdeInstance = useRef(null);

    const handleCancel = (type) => {
        setEdited(originalValue); 
        handleCancelEdit(type);
    }

    useEffect(() => {
        if (isEditing && simpleMdeRef.current) {
            simpleMdeRef.current.style.height = 'auto'; 
            simpleMdeRef.current.style.height = `${simpleMdeRef.current.scrollHeight}px`;
        }
    }, [edited, isEditing]);
    

    useEffect(() => {
        if (isEditing && type === 'content' && simpleMdeRef.current && !simpleMdeInstance.current) {
            const simplemde = new SimpleMDE({ 
                element: simpleMdeRef.current,
                initialValue: edited,
                toolbar: [
                    "bold", "italic", "strikethrough", "heading", "|",
                    "quote", "unordered-list", "ordered-list", "|",
                    "link", "|", "guide"
                ]
            });
            
            simplemde.codemirror.on("change", function() {
                setEdited(simplemde.value());
            });
    
            simpleMdeInstance.current = simplemde; 
        } else if (!isEditing && type === 'content' && simpleMdeInstance.current) {
            if (simpleMdeRef.current) {
                simpleMdeInstance.current.toTextArea();
            }
            simpleMdeInstance.current = null; 
        }
    }, [isEditing, edited, setEdited, type]);

    return (
        <div className="editing-container">
            <FormControl
                as={type === 'content' ? "textarea" : "input"}
                value={edited}
                onChange={(e) => setEdited(e.target.value)}
                className={`editable-${type === 'content' ? "textarea" : "textinput"}`}
                ref={type === 'content' ? simpleMdeRef : null}
            />
            <div className="edit-buttons">
                <Button variant="success" onClick={() => handleSave(type)} className="mr-1">
                    <i className="bi bi-check-lg icon-spacing"></i> Save
                </Button>
                <Button 
                    variant="link" 
                    onClick={() => handleCancel(type)}
                    className="btn-link mt-2 ml-1 btn-link-comment"
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
}
export default ContentEditor;