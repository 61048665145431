import React, { useState } from 'react';
import Sidebar from './Sidebar';
import './Layout.css';

function Layout({ children }) {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => setShowSidebar(!showSidebar);

  return (
    <div className="layout-container">
      <Sidebar show={showSidebar} onHide={() => setShowSidebar(false)} />
      <div className="main-wrapper">
        <div className="d-lg-none">
          <button className="btn btn-light m-2 toggle-sidebar" onClick={toggleSidebar}>
            <i className="bi bi-list"></i>
          </button>
        </div>
        <main className="main-content custom-main-content">
          {children}
        </main>
      </div>
    </div>
  );
}

export default Layout;