import { useState, useRef } from 'react';

import { Dropdown, Button, FormControl, InputGroup, Container, Row, Col } from 'react-bootstrap';
import FolderSelector from '../../common/FolderSelector';
import { useFolders } from '../../hooks/documents/useFolders';
import { useUser } from '../../general/UserProvider';
import { useContext } from 'react';
import { DataRoomContext } from '../DisplayDataRoom';

import './Search.css';

function Search({ onAddToDiscussion, isLoading, onResetChat, quota }) {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);
    const isQuotaExceeded = quota?.remaining === 0;

    const QUESTIONS_LIST = [
        "What is the role of an engineer?",
        "How does finance impact a business?",
        "What legal actions can I take?",
        "What does it mean to diversify investments?"
    ];
    
    const [suggestions, setSuggestions] = useState([]);
    const [inputText, setInputText] = useState('');
    const [inputHistory, setInputHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('');

    const { folders } = useFolders(token, dataroom_id, true, null, 'assistant', 'Interact');
    const isSearchEnabled = folders && folders.length > 0;

    const inputRef = useRef(null);

    const handleResetChat = () => {
        onResetChat();
    };
    
    const handleInputChange = (e) => {
        if (!isQuotaExceeded) {
            const value = e.target.value;
            setInputText(value);
            if (value) {
                const filteredSuggestions = QUESTIONS_LIST.filter(question => question.toLowerCase().includes(value.toLowerCase()));
                setSuggestions(filteredSuggestions);
            } else {
                setSuggestions([]);
            }
        }
    };
    
    const handleCategorySelect = (_) => (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleSuggestionClick = (suggestion) => {
        setInputText(suggestion);
        setSuggestions([]); 
        inputRef.current.focus();
    };
    

    const handleAddToDiscussion = () => {
        if (!isQuotaExceeded && inputText.trim() !== '') {
            onAddToDiscussion(inputText, selectedCategory);
            setInputHistory(prevHistory => [...prevHistory, inputText]);
            setInputText('');
            setHistoryIndex(-1);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !isLoading) {
          handleAddToDiscussion();
        }
        else if (event.key === 'ArrowUp' && historyIndex < inputHistory.length - 1) {
            setHistoryIndex(prevIndex => prevIndex + 1);
            setInputText(inputHistory[inputHistory.length - historyIndex - 2]);
        } else if (event.key === 'ArrowDown' && historyIndex > -1) {
            setHistoryIndex(prevIndex => prevIndex - 1);
            setInputText(historyIndex === 0 ? '' : inputHistory[inputHistory.length - historyIndex]);
        }
    };

    return (
        
        <div className="d-flex justify-content-center align-items-center Search">
            <Container>
                <Row>
                    <Col>
                    <InputGroup className="mb-5 mt-5 d-flex justify-content-center">
                        <Dropdown className="search-options">
                            <Dropdown.Toggle id="dropdown-basic" className="reset-btn no-caret">
                                <i className="bi bi-three-dots"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleResetChat}><i className="bi bi-arrow-repeat icon-spacing" title="Start a new conversation"></i>Reset conversation</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item disabled><i className="bi bi-chat-left-text icon-spacing"></i> Messages left: {quota?.remaining}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <FolderSelector 
                            folders={folders} 
                            value={selectedCategory}
                            handleInputChange={handleCategorySelect} 
                            id="folderSelector"
                            allOption={true}
                        />
                        <FormControl
                            size="lg"
                            placeholder={isQuotaExceeded ? "You have reached your message limit." : "Ask a question"}
                            aria-label="Ask a question"
                            aria-describedby="basic-addon2"
                            className="search-input"
                            onChange={handleInputChange} 
                            onKeyDown={handleKeyPress} 
                            value={inputText}
                            ref={inputRef}
                            disabled={isLoading || !isSearchEnabled || isQuotaExceeded}
                        />
                        <div className="suggestions-container">
                            {suggestions && suggestions.map(suggestion => (
                                <div 
                                    key={suggestion}
                                    className="suggestion-item"
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {suggestion}
                                </div>
                            ))}
                        </div>

                        <Button 
                            id="button-addon2" 
                            onClick={handleAddToDiscussion}
                            className="search-input"
                            disabled={isLoading || !isSearchEnabled || isQuotaExceeded}
                            title="Send message"
                        >
                            <i className="bi bi-send"></i>
                        </Button>
                    </InputGroup>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Search;