import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUser } from '../../general/UserProvider';
import { DataRoomContext } from '../../dataroom/DisplayDataRoom';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

const PermissionsContext = createContext();

export function usePermissions() {
    return useContext(PermissionsContext);
}

export function PermissionsProvider({ children }) {
    const { token, username } = useUser();
    const dataroomId = useContext(DataRoomContext); 
    const [permissions, setPermissions] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        if (token && username && dataroomId) { 

            const loadPermissions = async () => {
                try {
                    const response = await api.get(API_ROUTES.PERMISSIONS_BY_USER_AND_DATAROOM(dataroomId), {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setPermissions(response.data);
                    setError(null);
                } catch (error) {
                    setError("There was an error fetching the permissions");
                }
            };

            loadPermissions();
        }
    }, [token, username, dataroomId]);

    const value = {
        permissions,
        error
    };

    return (
        <PermissionsContext.Provider value={value}>
            {children}
        </PermissionsContext.Provider>
    );
}
