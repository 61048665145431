import { useState, useCallback, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useNotes = (token, dataroom_id) => {
    const [allNotes, setAllNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchAllNotes = useCallback(async () => {
        if (!token || !dataroom_id) return;

        setLoading(true);
        setError(null);

        try {
            const response = await api.get(API_ROUTES.GET_ALL_NOTES_WITH_FOLDERS(dataroom_id));
            setAllNotes(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch notes:", error);
            setError("Failed to load notes. Please try again.");
            setLoading(false);
        }
    }, [dataroom_id, token]);

    useEffect(() => {
        fetchAllNotes();
    }, [fetchAllNotes]);

    const getVisibleNotes = useCallback((folderId) => {
        return allNotes.filter(note => 
            note.folders.some(folder => folder.id === folderId) && 
            note.permissions.Read
        );
    }, [allNotes]);

    const deleteNote = useCallback(async (noteId) => {
        if (!token) return;
    
        try {
            await api.delete(API_ROUTES.DELETE_NOTE_FROM_DATAROOM(noteId));
            setAllNotes(prevNotes => prevNotes.filter(note => note.note_id !== noteId));
        } catch (error) {
            console.error("Failed to delete note:", error);
            setError("Failed to delete note. Please try again.");
        }
    }, [token]);

    const addNote = useCallback(async (noteData) => {
        if (!token) return;

        try {
            const response = await api.post(API_ROUTES.ADD_NOTE_TO_DATAROOM(dataroom_id), noteData);
            setAllNotes(prevNotes => [...prevNotes, response.data]);
            return response.data;
        } catch (error) {
            console.error("Failed to add note:", error);
            setError("Failed to add note. Please try again.");
            throw error;
        }
    }, [dataroom_id, token]);

    const updateNote = useCallback(async (noteId, updatedData) => {
        if (!token) return;

        try {
            const response = await api.put(API_ROUTES.UPDATE_NOTE(noteId), updatedData);
            setAllNotes(prevNotes => 
                prevNotes.map(note => 
                    note.note_id === noteId ? response.data : note
                )
            );
            return response.data;
        } catch (error) {
            console.error("Failed to update note:", error);
            setError("Failed to update note. Please try again.");
            throw error;
        }
    }, [token]);

    return { 
        getVisibleNotes,
        deleteNote, 
        addNote,
        updateNote,
        loading, 
        error 
    };
};