import React from 'react';
import { Card, Table, Button, Spinner, Alert, Badge } from 'react-bootstrap';

const getStatusVariant = (status) => {
  switch (status) {
    case 'NOT_STARTED': return 'secondary';
    case 'IN_PROGRESS': return 'warning';
    case 'COMPLETED': return 'success';
    default: return 'info';
  }
};

const DataroomCyclesInfo = ({ 
  dataroom, 
  cycles, 
  loading, 
  error, 
  onEditCycles
}) => {
  return (
    <Card className="mb-4">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="mb-0">
            <i className="bi bi-calendar-check me-2"></i>
            Cycles for {dataroom?.name}
          </h4>
          {cycles?.length > 0 && (
            <Button variant="primary" onClick={onEditCycles}>
              <i className="bi bi-pencil me-2"></i>
              Edit Cycles
            </Button>
          )}
        </div>

        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <Alert variant="danger">
            <i className="bi bi-exclamation-triangle me-2"></i>
            {error}
          </Alert>
        ) : (
          <>
            {!cycles?.length ? (
              <Alert variant="info">
                <i className="bi bi-info-circle me-2"></i>
                No cycles have been set up for this dataroom yet. Please initialize controls first.
              </Alert>
            ) : (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th><i className="bi bi-calendar me-2"></i>Cycle</th>
                    <th><i className="bi bi-file-text me-2"></i>Description</th>
                    <th><i className="bi bi-exclamation-triangle me-2"></i>Risks</th>
                    <th><i className="bi bi-info-circle me-2"></i>Status</th>
                    <th><i className="bi bi-person me-2"></i>Auditor</th>
                    <th><i className="bi bi-person-check me-2"></i>Supervisor</th>
                  </tr>
                </thead>
                <tbody>
                  {cycles.map(cycle => (
                    <tr key={cycle.id}>
                      <td>{cycle.cycle_template?.name || 'N/A'}</td>
                      <td>{cycle.cycle_template?.description || 'No description'}</td>
                      <td>
                        {cycle.risks?.length > 0 ? (
                          <Badge bg="warning">{cycle.risks.length} risks</Badge>
                        ) : (
                          <Badge bg="success">No risks</Badge>
                        )}
                      </td>
                      <td>
                        <Badge bg={getStatusVariant(cycle.status)}>
                          {cycle.status}
                        </Badge>
                      </td>
                      <td>{cycle.auditor_id || 'Not assigned'}</td>
                      <td>{cycle.supervisor_id || 'Not assigned'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default DataroomCyclesInfo;