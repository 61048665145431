import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFetchQuotaRemaining = (token, dataroomId) => {
    const [quotaRemaining, setQuotaRemaining] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const updateQuotaRemaining = (usedMessageCount = 1) => {
        setQuotaRemaining(prevQuota => prevQuota ? {...prevQuota, remaining: prevQuota.remaining - usedMessageCount} : null);
    };

    useEffect(() => {
        if (!token || !dataroomId) {
            setLoading(false);
            return;
        }

        const fetchQuotaRemaining = async () => {
            try {
                const response = await api.get(API_ROUTES.GET_QUOTA_REMAINING(dataroomId), {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setQuotaRemaining(response.data.quota);
            } catch (error) {
                setError(error.message || "Error fetching quota remaining");
            } finally {
                setLoading(false);
            }
        };

        fetchQuotaRemaining();
    }, [token, dataroomId]);

    return { quotaRemaining, loading, error, updateQuotaRemaining };
};
