import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddDocumentType = (token) => {
  const [adding, setAdding] = useState(false);
  const [addError, setAddError] = useState(null);

  const addDocumentType = async (data) => {
    setAdding(true);
    setAddError(null);
    try {
      const response = await api.post(
        API_ROUTES.ADMIN_CREATE_DOCUMENT_TYPE, 
        data,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setAdding(false);
      return response.data;
    } catch (err) {
      setAddError(err.message || 'An error occurred while adding the document type');
      setAdding(false);
      return null;
    }
  };

  return { addDocumentType, adding, addError };
};

export const useUpdateDocumentType = (token) => {
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const updateDocumentType = async (data) => {
    setUpdating(true);
    setUpdateError(null);
    try {
      const response = await api.put(
        `${API_ROUTES.ADMIN_UPDATE_DOCUMENT_TYPE}/${data.id}`, 
        data,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setUpdating(false);
      return response.data;
    } catch (err) {
      setUpdateError(err.message || 'An error occurred while updating the document type');
      setUpdating(false);
      return null;
    }
  };

  return { updateDocumentType, updating, updateError };
};

export const useDeleteDocumentType = (token) => {
  const [deleting, setDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const deleteDocumentType = async (documentTypeId) => {
    setDeleting(true);
    setDeleteError(null);
    try {
      await api.delete(
        `${API_ROUTES.ADMIN_DELETE_DOCUMENT_TYPE}/${documentTypeId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setDeleting(false);
      return true;
    } catch (err) {
      console.log(err);
      setDeleting(false);
      if (err.response) {
        if (err.response.status === 403) {
          setDeleteError(err.response.data.error || "Deletion of this document type is not allowed.");
        } else {
          setDeleteError(err.response.data.error || 'An error occurred while deleting the document type.');
        }
      } else if (err.request) {
        setDeleteError('No response received from the server. Please check your connection.');
      } else {
        setDeleteError('An unexpected error occurred while deleting the document type.');
      }
      return false;
    }
  };

  return { deleteDocumentType, deleting, deleteError };
};

export const useFetchDocumentTypes = (token) => {
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDocumentTypes = useCallback(async () => {
    if (!token) return;

    setLoading(true);
    setError(null);
    try {
      const response = await api.get(
        API_ROUTES.ADMIN_GET_DOCUMENT_TYPES,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setDocumentTypes(response.data);
    } catch (error) {
      setError(error.message || "Error fetching document types");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchDocumentTypes();
  }, [fetchDocumentTypes]);

  return { 
    documentTypes, 
    setDocumentTypes, 
    loading, 
    error, 
    refetch: fetchDocumentTypes 
  };
};