import React from 'react';

import SortableHeader from '../SortableHeader';

function TableHeader({ sortField, sortDirection, handleSort }) {

    return (
        <thead>
            <tr>
                <SortableHeader 
                    label="Folder"
                    field="folder_hierarchy"
                    currentSortField={sortField}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                />
                <th>Title</th>
                <SortableHeader 
                    label="Status"
                    field="status"
                    currentSortField={sortField}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                />
                <SortableHeader 
                    label="Created by"
                    field="creator"
                    currentSortField={sortField}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                />
                <SortableHeader 
                    label="Assignee"
                    field="assignee"
                    currentSortField={sortField}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                />
                <SortableHeader 
                    label="Deadline"
                    field="deadline"
                    currentSortField={sortField}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                />
                <th></th>
            </tr>
        </thead>
    );
};

export default TableHeader;