import { useState, useCallback, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFolderParents = (token, dataroom_id) => {
    const [folderId, setFolderId] = useState(null);
    const [parents, setParents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    

    const fetchParents = useCallback(async (passedFolderId, callback) => {
        const rootFolder = {
            id: 0,
            uuid: "None",
            name: 'Root'
        };

        const effectiveFolderId = passedFolderId ?? folderId;
        if (!token || !dataroom_id || !effectiveFolderId) {
            setParents([rootFolder]);
            callback?.();
            return;
        }
        if (effectiveFolderId === 0) {
            setParents([rootFolder]);
            callback?.();
            return;
        }

        setLoading(true);
        try {
            const endpoint = API_ROUTES.GET_FOLDER_PARENTS(dataroom_id, effectiveFolderId);
            const response = await api.get(endpoint, {
                headers: { Authorization: `Bearer ${token}` },
            });
            
            const updatedParents = [rootFolder, ...(response.data || [])];
            setParents(updatedParents);
            setError(null);
        } catch (error) {
            setError(error);
            setParents([]);
        } finally {
            setLoading(false);
            callback?.();
        }
    }, [dataroom_id, folderId, token]);

    useEffect(() => {
        fetchParents();
    }, [fetchParents]);

    const refetch = useCallback((newFolderId = null, callback) => {
        setFolderId(newFolderId);
        fetchParents(newFolderId, callback);
    }, [fetchParents]);

    return {
        parents,
        loading,
        error,
        refetch
    };
};
