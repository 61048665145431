import { useState, useCallback, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFECData = (token, dataroomId) => {
    const [fecData, setFecData] = useState({
        entries: [],
        fec_info: null
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchFECData = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await api.get(
                API_ROUTES.GET_FEC_ENTRIES(dataroomId),
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setFecData(response.data);
            setLoading(false);
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while fetching the FEC data');
            setLoading(false);
        }
    }, [token, dataroomId]);

    useEffect(() => {
        fetchFECData();
    }, [fetchFECData]);

    return { 
        entries: fecData.entries,
        fecInfo: fecData.fec_info,
        loading, 
        error, 
        refetch: fetchFECData 
    };
};