import { useState, useCallback, useRef } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useDataroomGroups = (token, dataroomId) => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const groupsRef = useRef(groups);

  const fetchGroups = useCallback(async () => {
    if (!token || !dataroomId) return;

    setLoading(true);
    setError(null);

    try {
      const response = await api.get(
        API_ROUTES.DATAROOM_GROUPS(dataroomId),
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setGroups(response.data);
      groupsRef.current = response.data;
      setLoading(false);
    } catch (error) {
      setError(error.message || "Error fetching groups");
      setLoading(false);
    }
  }, [token, dataroomId]);

  const addGroup = useCallback(async (groupName, description, folderId) => {
    if (!token || !dataroomId) return;

    setLoading(true);
    setError(null);

    try {
      const response = await api.post(
        API_ROUTES.CREATE_DATAROOM_GROUP(dataroomId),
        { name: groupName, description, folder_id: folderId },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      const newGroup = response.data.group;
      setGroups(prevGroups => {
        const updatedGroups = [...prevGroups, newGroup];
        groupsRef.current = updatedGroups;
        return updatedGroups;
      });
      setLoading(false);
      return newGroup;
    } catch (error) {
      setError(error.message || "Error adding group");
      setLoading(false);
      throw error;
    }
  }, [token, dataroomId]);

  const deleteGroup = useCallback(async (groupId) => {
    if (!token || !dataroomId) return;

    setLoading(true);
    setError(null);

    try {
      await api.delete(
        API_ROUTES.DELETE_DATAROOM_GROUP(dataroomId, groupId),
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setGroups(prevGroups => {
        const updatedGroups = prevGroups.filter(group => group.group_id !== groupId);
        groupsRef.current = updatedGroups;
        return updatedGroups;
      });
      setLoading(false);
    } catch (error) {
      setError(error.message || "Error deleting group");
      setLoading(false);
      throw error;
    }
  }, [token, dataroomId]);

  const updateGroup = useCallback(async (groupId, groupName, description, folderId) => {
    if (!token || !dataroomId) return;

    setLoading(true);
    setError(null);

    try {
      const response = await api.put(
        API_ROUTES.UPDATE_DATAROOM_GROUP(dataroomId, groupId),
        { name: groupName, description, folder_id: folderId },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      const updatedGroup = response.data.group;
      setGroups(prevGroups => {
        const updatedGroups = prevGroups.map(group => 
          group.group_id === groupId ? updatedGroup : group
        );
        groupsRef.current = updatedGroups;
        return updatedGroups;
      });
      setLoading(false);
      return updatedGroup;
    } catch (error) {
      setError(error.message || "Error updating group");
      setLoading(false);
      throw error;
    }
  }, [token, dataroomId]);

  const fetchFolders = useCallback(async () => {
    if (!token || !dataroomId) return;

    setLoading(true);
    setError(null);

    try {
      const response = await api.get(
        API_ROUTES.ADMIN_DATAROOM_FOLDERS(dataroomId),
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setError(error.message || "Error fetching folders");
      setLoading(false);
      throw error;
    }
  }, [token, dataroomId]);

  return { groups: groupsRef.current, loading, error, fetchGroups, addGroup, deleteGroup, updateGroup, fetchFolders };
};