import React from 'react';
import { Form } from 'react-bootstrap';

const GeneralForm = ({ formData, handleChange }) => (
  <>
    <h2 className="title-h2 font-title mx-3">General</h2>
    <div className="px-4">
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Due diligence of OpenAI"
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Color Code</Form.Label>
        <Form.Control
          name="color_code"
          type="color"
          value={formData.color_code}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Logo Path</Form.Label>
        <Form.Control
          name="logo_path"
          value={formData.logo_path}
          onChange={handleChange}
          placeholder="https://logo.com/my-logo.png"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          name="description"
          as="textarea"
          rows={3}
          value={formData.description}
          onChange={handleChange}
          placeholder="This data room contains all documents related to the due diligence of OpenAI"
          required
        />
      </Form.Group>
    </div>
  </>
);

export default GeneralForm;