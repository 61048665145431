// useFetchIRStatuses.js
import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export function useFetchIRStatuses(token) {
    const [statuses, setStatuses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        async function fetchStatuses() {
            if (!token) {
                setLoading(false);
                return;
            }

            setLoading(true);
            try {
                const response = await api.get(API_ROUTES.GET_STATUSES, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                if (isMounted) {
                    setStatuses(response.data);
                }
            } catch (e) {
                if (isMounted) {
                    setError(e.message);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchStatuses();

        return () => {
            isMounted = false;
        };
    }, [token]); 

    return { statuses, loading, error };
}
