import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useGenerateAuditDocument } from '../../../hooks/workflow/useGenerateAuditDocument';

const GenerateDocumentModal = ({ show, onClose, documentInfo, onGenerate, token, dataroomId, onRefetchAudit }) => {
  const { generateDocument, loading, error } = useGenerateAuditDocument(token, dataroomId);

  const handleGenerate = async () => {
    try {
      await generateDocument(documentInfo?.type);
      console.log('Document generated');
      onGenerate(); 
      onClose();   
      onRefetchAudit();
    } catch (error) {
      console.error('Generation failed:', error);
    }
  };

  return (
    <Modal 
      show={show} 
      onHide={onClose}
      centered
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          <i className="bi bi-gear me-2 text-primary"></i>
          Generate Document
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-3">
        <p>{documentInfo?.description}</p>
        
        <div className="bg-light p-3 rounded my-3">
          <h5 className="mb-2">Prerequisites:</h5>
          <ul className="list-unstyled mb-0">
            {documentInfo?.requiredDocs.map(doc => (
              <li key={doc} className="mb-1">
                • {doc === 'fec' ? 'FEC File' : doc}
              </li>
            ))}
          </ul>
        </div>

        {documentInfo?.periodConstraints === 'currentAndPreviousYear' && (
          <div className="alert alert-warning">
            Note: This document requires data from the previous year
          </div>
        )}

        {error && (
          <div className="alert alert-danger">
            <i className="bi bi-exclamation-triangle me-2"></i>
            {error}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={handleGenerate}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Generating...
            </>
          ) : (
            'Generate'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenerateDocumentModal;