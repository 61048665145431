import React from 'react';
import { Image } from 'react-bootstrap';

const DataroomPreview = ({ formData }) => (
  <div className="dataroom-preview">
    <h4>Dataroom Preview</h4>
    <Image src={formData.logo_path || 'https://via.placeholder.com/150'} roundedCircle className="mb-3" style={{ width: '150px', height: '150px', backgroundColor: formData.color_code }} />
    <h5>{formData.name}</h5>
    <p>{formData.description}</p>
    <p><strong>Region:</strong> {formData.region}</p>
    <p><strong>Container Name:</strong> {formData.container_name}</p>
    <p><strong>Visibility:</strong> {formData.visibility}</p>
  </div>
);

export default DataroomPreview;