import React, { useState } from 'react';

function ProcedurePriority({ 
    procedure,
    onUpdatePriority,
    onUpdatePriorityDescription
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedDescription, setEditedDescription] = useState(procedure.priority_description || '');
    const [isEditingPriority, setIsEditingPriority] = useState(false);
    const [editedPriority, setEditedPriority] = useState(procedure.priority);

    const priorityOptions = ['Very Low', 'Low', 'Medium', 'High', 'Critical'];

    const handlePriorityEditClick = () => {
        setIsEditingPriority(true);
        setEditedPriority(procedure.priority);
    };

    const handlePrioritySave = async () => {
        if (await onUpdatePriority(editedPriority)) {
            setIsEditingPriority(false);
        }
    };

    const handlePriorityCancel = () => {
        setIsEditingPriority(false);
        setEditedPriority(procedure.priority);
    };
    
    const handleEditClick = () => {
        setIsEditing(true);
        setEditedDescription(procedure.priority_description || '');
    };

    const handleSave = async () => {
        if (await onUpdatePriorityDescription(editedDescription)) {
            setIsEditing(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedDescription(procedure.priority_description || '');
    };

    const getPriorityColor = (priority) => {
        const colors = ['#95a5a6', '#3498db', '#f39c12', '#e67e22', '#e74c3c'];
        return colors[priority - 1] || '#95a5a6';
    };

    return (
        <div className="status-section">
            <h3 className="section-title-right">Priority</h3>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                <div className="info-item">
                    <span className="info-label">
                        Level
                        {!isEditingPriority && (
                            <i 
                                className="bi bi-pencil-fill ms-2" 
                                style={{ cursor: 'pointer', fontSize: '0.8em', color: '#6c757d' }}
                                onClick={handlePriorityEditClick}
                            ></i>
                        )}
                    </span>
                    {isEditingPriority ? (
                        <div className="d-flex flex-column gap-2">
                            <select
                                className="form-select"
                                value={editedPriority}
                                onChange={(e) => setEditedPriority(e.target.value)}
                            >
                                {priorityOptions.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <div className="d-flex gap-2">
                                <button 
                                    className="btn btn-sm btn-success" 
                                    onClick={handlePrioritySave}
                                >
                                    <i className="bi bi-check-lg me-1"></i>
                                    Save
                                </button>
                                <button 
                                    className="btn btn-sm btn-secondary" 
                                    onClick={handlePriorityCancel}
                                >
                                    <i className="bi bi-x-lg me-1"></i>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    ) : (
                        <span className="info-value" style={{ color: getPriorityColor(procedure.priority) }}>
                            <i className="bi bi-flag-fill me-2"></i>
                            {procedure.priority}
                        </span>
                    )}
                </div>
                <div className="info-item">
                    <span className="info-label">
                        Description
                        {!isEditing && (
                            <i 
                                className="bi bi-pencil-fill ms-2" 
                                style={{ cursor: 'pointer', fontSize: '0.8em', color: '#6c757d' }}
                                onClick={handleEditClick}
                            ></i>
                        )}
                    </span>
                    {isEditing ? (
                        <div className="d-flex flex-column gap-2">
                            <textarea
                                className="form-control"
                                value={editedDescription}
                                onChange={(e) => setEditedDescription(e.target.value)}
                                rows="3"
                            />
                            <div className="d-flex gap-2">
                                <button 
                                    className="btn btn-sm btn-success" 
                                    onClick={handleSave}
                                >
                                    <i className="bi bi-check-lg me-1"></i>
                                    Save
                                </button>
                                <button 
                                    className="btn btn-sm btn-secondary" 
                                    onClick={handleCancel}
                                >
                                    <i className="bi bi-x-lg me-1"></i>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    ) : (
                        <span className="info-value">
                            {procedure.priority_description || 'No description provided'}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProcedurePriority;