import React from 'react';
import { Button, Form } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { processContent } from '../../../utils/PostProcessContent';
import './ProcedureFindings.css';
import './markdown-styles.css';

export const ProcedureFindings = ({
    results,
    isEditing,
    editedResults,
    onStartEditing,
    onSave,
    onCancel,
    onChange,
    onCopy,
    status,
    updating
}) => {
    const renderMarkdown = (content) => {
        const processedContent = processContent(content);
        
        return (
            <div className="markdown-content">
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                >
                    {processedContent}
                </ReactMarkdown>
            </div>
        );
    };

    return (
        <section className="results-section">
            <h2 className="section-title-results">Findings</h2>
            {isEditing ? (
                <Form>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        value={editedResults}
                        onChange={onChange}
                        className="result-textarea"
                        disabled={status === 'Completed'}
                        placeholder="Enter your findings here... (Markdown supported)"
                    />
                    <div className="text-muted small mt-1">
                        Markdown formatting is supported (e.g., **bold**, *italic*, ## headers, - lists)
                    </div>
                    <div className="mt-3">
                        <Button 
                            variant="primary" 
                            onClick={onSave} 
                            className="me-2"
                            disabled={status === 'Completed' || updating}
                        >
                            {updating ? 'Saving...' : 'Save'}
                        </Button>
                        <Button 
                            variant="secondary" 
                            onClick={onCancel}
                            disabled={status === 'Completed' || updating}
                        >
                            Cancel
                        </Button>
                    </div>
                </Form>
            ) : (
                <>
                    <div className={`markdown-wrapper ${status === 'Completed' ? 'text-muted' : ''}`}>
                        {renderMarkdown(results)}
                    </div>
                    <div className="d-flex">
                        <Button 
                            variant="outline-primary" 
                            onClick={onStartEditing}
                            disabled={status === 'Completed'}
                            className="me-2"
                        >
                            <i className="bi bi-pencil me-2"></i>
                            Edit
                        </Button>
                        {results && (
                            <Button 
                                variant="outline-secondary" 
                                onClick={onCopy}
                                className="me-2"
                            >
                                <i className="bi bi-clipboard me-2"></i>
                                Copy
                            </Button>
                        )}
                    </div>
                </>
            )}
        </section>
    );
};

export default ProcedureFindings;