import React from 'react';
import { Card } from 'react-bootstrap';

import './InitDataRoom.css';

function InitDataRoom() {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
            <Card className="no-border-card">
                <Card.Body>
                    <Card.Title className="init-card-title"><span className="material-symbols-outlined init-card-icon dark-pink">waving_hand</span> Hi curious mind,</Card.Title>
                    <Card.Text className="init-card-content">
                        Currently, you are not associated with any dataroom. This means you do not yet have a space to manage or view data. 
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
};

export default InitDataRoom;