import React, { useState, useContext } from 'react';
import { Form, Button, InputGroup, FormControl, Alert } from 'react-bootstrap';
import { DataRoomContext } from '../DisplayDataRoom';
import { useUpdateQuota } from '../../hooks/team/useUpdateQuota';
import { useUpdateUserInfo } from '../../hooks/team/useUpdateUserInfo';

function UpdateTeamForm({ member, token, onUpdateSuccess, roles = [] }) {
    const dataroom_id = useContext(DataRoomContext);
    const quotaExists = !!member.quota;
    const [newQuotaCount, setNewQuotaCount] = useState(member.quota?.usage_count || 0);
    const [newQuotaLimit, setNewQuotaLimit] = useState(member.quota?.usage_limit || 0);
    const { manageQuota, isLoading: isQuotaLoading, error: quotaError } = useUpdateQuota(token);
    const { updateUserInfo, isLoading: isUserInfoLoading, error: userInfoError } = useUpdateUserInfo(token);

    const [companyName, setCompanyName] = useState(member.company_name || '');
    const [companyUrl, setCompanyUrl] = useState(member.company_url || '');
    const [pictureUrl, setPictureUrl] = useState(member.picture_url || '');
    const [selectedRole, setSelectedRole] = useState(member.role?.role_id || '');
    const [username, setUsername] = useState(member.username || '');

    const handleSubmit = async (event) => {
        event.preventDefault();
        await Promise.all([
            manageQuota(dataroom_id, member.user_id, newQuotaLimit),
            updateUserInfo(dataroom_id, member.user_id, {
                company_name: companyName,
                company_url: companyUrl,
                picture_url: pictureUrl,
                role_id: selectedRole,
                username: username,
            })
        ]);
        if (!quotaError && !userInfoError) {
            onUpdateSuccess();
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h2 className="title-h2 font-title">General information</h2>
            <Form.Group className="mb-3">
                <Form.Label>Id</Form.Label>
                <InputGroup>
                    <FormControl value={member.user_id} disabled />
                </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <InputGroup>
                    <FormControl 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <InputGroup>
                    <FormControl value={member.email} disabled />
                </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Company Name</Form.Label>
                <InputGroup>
                    <FormControl 
                        value={companyName} 
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Company URL</Form.Label>
                <InputGroup>
                    <FormControl 
                        value={companyUrl} 
                        onChange={(e) => setCompanyUrl(e.target.value)}
                    />
                </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Picture URL</Form.Label>
                <InputGroup>
                    <FormControl 
                        value={pictureUrl} 
                        onChange={(e) => setPictureUrl(e.target.value)}
                    />
                </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>User Role</Form.Label>
                {!Array.isArray(roles) || roles.length === 0 ? (
                    <Alert variant="warning">No roles available</Alert>
                ) : (
                    <Form.Control 
                        as="select" 
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                    >
                        <option value="">Select a role</option>
                        {roles.map(role => (
                            <option key={role.role_id} value={role.role_id}>
                                {role.role_name}
                            </option>
                        ))}
                    </Form.Control>
                )}
            </Form.Group>

            <h2 className="title-h2 font-title">Assistant quota</h2>
            {quotaExists ? (
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={member.quota.start_date}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={member.quota.end_date}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Message count</Form.Label>
                        <Form.Control 
                            type="number" 
                            min="0"
                            value={newQuotaCount}
                            onChange={(e) => setNewQuotaCount(e.target.value)}
                            disabled
                        />
                    </Form.Group>
                </>
            ) : (
                <Alert variant="warning">No quota available. </Alert>
            )}

            <Form.Group className="mb-3">
                <Form.Label>Limit of this month</Form.Label>
                <Form.Control 
                    type="number" 
                    min="0"
                    value={newQuotaLimit}
                    onChange={(e) => setNewQuotaLimit(e.target.value)}
                    required
                />
            </Form.Group>

            <Button type="submit" disabled={isQuotaLoading || isUserInfoLoading}>
                {isQuotaLoading || isUserInfoLoading ? 'Processing...' : 'Save Changes'}
            </Button>
            {(quotaError || userInfoError) && (
                <div className="alert alert-danger" role="alert">
                    {quotaError || userInfoError}
                </div>
            )}
        </Form>
    );
}

export default UpdateTeamForm;