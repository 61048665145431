import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';
import { getStartOfDay, getOneWeekAgo } from '../../utils/Date';

export const useFetchEvents = (dataroomId) => {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const today = getStartOfDay();
    const oneWeekAgo = getOneWeekAgo();

    const loadEvents = useCallback(async () => {    
        setIsLoading(true);
        const limit = 100;  
    
        try {
            const response = await api.get(`${API_ROUTES.EVENTS(dataroomId)}?limit=${limit}`);
            setEvents(response.data);
        } catch (error) {
            setError(error.message || "Error loading events");
        } finally {
            setIsLoading(false);
        }
    }, [dataroomId]);

    useEffect(() => {
        loadEvents();
    }, [loadEvents]);

    const eventsToday = events?.filter(event => {
        const eventDate = getStartOfDay(new Date(event.created_at));
        return eventDate.getTime() === today.getTime();
    }) || [];

    const eventsThisWeek = events?.filter(event => {
        const eventDate = new Date(event.created_at);
        return eventDate.getTime() > oneWeekAgo.getTime() && eventDate.getTime() < today.getTime();
    }) || [];

    const eventsEarlier = events?.filter(event => new Date(event.created_at).getTime() <= oneWeekAgo.getTime()) || [];

    return { eventsToday, eventsThisWeek, eventsEarlier, isLoading, error };
};
