import { useState, useCallback, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFolders = (token, dataroom_id, onlyCategories = true, parentId = null, feature = 'document', permission = 'Read', lazyLoad = false) => {
    const [folders, setFolders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchFolders = useCallback(async (newParentId = parentId, callback = null) => {
        if (!token || !dataroom_id) {
            setFolders([]);
            if (callback) callback(new Error("Token or dataroom_id not provided"), null);
            return;
        }

        setLoading(true);
        try {
            const params = newParentId ? { parent_id: newParentId } : {};
            const endpoint = onlyCategories 
                ? API_ROUTES.DATAROOM_CATEGORIES(dataroom_id, feature, permission)
                : API_ROUTES.DATAROOM_FOLDERS(dataroom_id);
            const response = await api.get(endpoint, { params });

            setFolders(response.data || []);
            setError(null);
            if (callback) callback(null, response.data || []);
        } catch (error) {
            console.error("In hook, failed to fetch folders:", error);
            setError(error);
            setFolders([]);
            if (callback) callback(error, null);
        } finally {
            setLoading(false);
        }
    }, [dataroom_id, token, onlyCategories, parentId, feature, permission]);

    useEffect(() => {
        if (!lazyLoad) {
            fetchFolders();
        }
    }, [fetchFolders, lazyLoad]);

    return {
        folders,
        loading,
        error,
        refetch: fetchFolders
    };
};
