import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFetchUsers = (token) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const fetchUsers = async () => {
            if (!token) return;

            try {
                const response = await api.get(API_ROUTES.ADMIN_GET_USERS, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (isMounted) {
                    setUsers(response.data);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error.message || "Error fetching users");
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchUsers();

        return () => {
            isMounted = false;
        };
    }, [token]);

    return { users, loading, error, setUsers };
};