import { useState } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useUpdateDataroom = (token) => {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  const updateDataroom = async (dataroomData) => {
    if (!token) return;

    setUpdating(true);
    setError(null);

    try {
      const response = await api.put(
        API_ROUTES.ADMIN_UPDATE_DATAROOM(dataroomData.dataroom_id),
        dataroomData,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setUpdating(false);
      return response.data.dataroom;
    } catch (error) {
      setError(error.message || "Error updating dataroom");
      setUpdating(false);
      throw error;
    }
  };

  return { updateDataroom, updating, error };
};