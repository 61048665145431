import { useState, useCallback, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useControlDocuments = (token, dataroomId, controlId) => {
    const [documentReferences, setDocumentReferences] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchReferences = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await api.get(
                API_ROUTES.GET_CONTROL_REFERENCES(dataroomId, controlId),
                {
                    headers: { 
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            setDocumentReferences(response.data.data.references);
            setLoading(false);
            return response.data.data;
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred while fetching document references';
            setError(errorMessage);
            setLoading(false);
        }
    }, [token, dataroomId, controlId]);

    const createReference = useCallback(async (reference_description, reference_name = '') => {
        setLoading(true);
        setError(null);

        try {
            await api.post(
                API_ROUTES.CREATE_CONTROL_REFERENCE(dataroomId, controlId),
                {
                    reference_description,
                    reference_name
                },
                {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            await fetchReferences();
            return true;
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred while creating the reference';
            setError(errorMessage);
            setLoading(false);
            throw new Error(errorMessage);
        }
    }, [token, dataroomId, controlId, fetchReferences]);

    const deleteReference = useCallback(async (controlId, referenceId, deleteCompletely = false) => {
        setLoading(true);
        setError(null);
    
        try {
            await api.delete(
                API_ROUTES.DELETE_CONTROL_REFERENCE(dataroomId, controlId, referenceId),
                {
                    headers: { 
                        Authorization: `Bearer ${token}`
                    },
                    data: {
                        delete_completely: deleteCompletely
                    }
                }
            );
    
            await fetchReferences();
            return true;
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred while deleting the reference';
            setError(errorMessage);
            setLoading(false);
            throw new Error(errorMessage);
        }
    }, [token, dataroomId, fetchReferences]);

    const sendReference = useCallback(async (controlId, referenceId) => {
        setLoading(true);
        setError(null);

        try {
            await api.post(
                API_ROUTES.SEND_CONTROL_REFERENCE(dataroomId, controlId, referenceId),
                {}, 
                {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            await fetchReferences();
            return true;
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred while sending the reference';
            setError(errorMessage);
            setLoading(false);
            throw new Error(errorMessage);
        }
    }, [token, dataroomId, fetchReferences]);

    const extractReferences = useCallback(async (userRequest) => {
        setLoading(true);
        setError(null);

        try {
            const response = await api.post(
                API_ROUTES.EXTRACT_FEC_REFERENCES(dataroomId, controlId),
                { user_request: userRequest },
                {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            await fetchReferences();
            return response.data.data;
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred while extracting document references';
            setError(errorMessage);
            setLoading(false);
            throw new Error(errorMessage);
        }
    }, [token, dataroomId, controlId, fetchReferences]);

    const updateAttribute = useCallback(async (dataroomId, attributeId, newValue) => {
        setLoading(true);
        setError(null);
    
        try {
            await api.patch(
                API_ROUTES.UPDATE_ATTRIBUTE(dataroomId, attributeId),
                {
                    value: newValue
                },
                {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            await fetchReferences();
            return true;
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred while updating the attribute';
            setError(errorMessage);
            setLoading(false);
            throw new Error(errorMessage);
        }
    }, [token, fetchReferences]);

    const clearReferences = useCallback(() => {
        setDocumentReferences(null);
        setError(null);
    }, []);

    useEffect(() => {
        fetchReferences();
    }, [fetchReferences]);

    return {
        documentReferences,
        loading,
        error,
        extractReferences,
        clearReferences,
        fetchReferences,
        deleteReference,
        sendReference,
        createReference,
        updateAttribute
    };
};