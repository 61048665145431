import { useState, useEffect } from 'react';

export const useBreadcrumb = (dataroom_id) => {
    const [breadcrumb, setBreadcrumb] = useState([{ name: 'Root', id: -1 || null }]);

    useEffect(() => {
        setBreadcrumb([{ name: 'Root', id: -1 || null }]);
    }, [dataroom_id]);

    const handleCardClick = (item) => {
        setBreadcrumb(prev => [...prev, { name: item.name, id: item.id }]);
    };

    const handleBreadcrumbClick = (index) => {
        setBreadcrumb(prev => prev.slice(0, index + 1));
    };

    const handleGoToParent = () => {
        setBreadcrumb(prev => prev.length > 1 ? prev.slice(0, prev.length - 1) : prev);
    };

    return { breadcrumb, handleCardClick, handleBreadcrumbClick, handleGoToParent };
};
