import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFetchDataroomDetails = (dataroomId, token) => {
    const [dataroomDetails, setDataroomDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!token || !dataroomId) return;

        setIsLoading(true);
        api.get(API_ROUTES.GET_DATAROOM_BY_ID(dataroomId))
            .then(response => {
                setDataroomDetails(response.data);
            })
            .catch(error => {
                setError(error.message || "There was an error fetching the dataroom details");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [dataroomId, token]);

    return { dataroomDetails, isLoading, error };
};
