import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Container, Alert } from 'react-bootstrap';

const CycleEditModal = ({ 
  show, 
  handleClose, 
  cycle, 
  onSave, 
  isProcessing, 
  error
}) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    order: 0
  });

  useEffect(() => {
    if (cycle) {
      setFormData({
        name: cycle.name || '',
        description: cycle.description || '',
        order: cycle.order || 0
      });
    } else {
      setFormData({
        name: '',
        description: '',
        order: 0
      });
    }
  }, [cycle]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: name === 'order' ? parseInt(value, 10) : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const isFormValid = () => {
    return formData.name.trim() !== '' && 
           formData.description.trim() !== '' &&
           !isNaN(formData.order);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" className="edit-modal" centered>
      <Modal.Header>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs="auto">
              <Button variant="link" onClick={handleClose} className="p-0 text-dark">
                <i className="bi bi-x-lg"></i>
              </Button>
            </Col>
            <Col>
              <Modal.Title>{cycle ? 'Edit Cycle' : 'Create Cycle'}</Modal.Title>
            </Col>
            <Col xs="auto">
              <Button 
                variant="primary" 
                onClick={handleSubmit} 
                disabled={isProcessing || !isFormValid()}
              >
                {isProcessing ? 'Processing...' : (cycle ? 'Save Changes' : 'Create Cycle')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <h2 className="title-h2 font-title mx-3">Cycle Details</h2>
            <div className="px-4">
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="e.g., Treasury"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="description"
                  as="textarea"
                  rows={3}
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Describe the purpose and scope of this audit cycle"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Order</Form.Label>
                <Form.Control
                  name="order"
                  type="number"
                  value={formData.order}
                  onChange={handleChange}
                  placeholder="Enter the order of this cycle"
                  required
                />
              </Form.Group>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CycleEditModal;