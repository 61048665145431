import { useState } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useRemoveDataroomRole = (token) => {
  const [removing, setRemoving] = useState(false);
  const [error, setError] = useState(null);

  const removeDataroomRole = async (dataroomId, userId, roleId) => {
    if (!token) return;

    setRemoving(true);
    setError(null);

    try {
      const response = await api.delete(
        `${API_ROUTES.ADMIN_REMOVE_DATAROOM_ROLE}/${dataroomId}/roles/${userId}/${roleId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setRemoving(false);
      return response.data;
    } catch (error) {
      setError(error.message || "Error removing dataroom role");
      setRemoving(false);
      throw error;
    }
  };

  return { removeDataroomRole, removing, error };
};