import React from 'react';
import { Row, Alert } from 'react-bootstrap';

import DataRoomCard from './DataRoomCard';

import "./ListDataRooms.css";


function ListDataRooms({ dataRooms }) {

    return (
        <>
        <Row className='mt-3'>
            {dataRooms && dataRooms.length > 0 ?
                dataRooms.map(room => {
                    return (
                        <DataRoomCard key={room.dataroom_id} room={room} />
                    )
                })
                :
                <Alert key="danger" variant="danger" className='mt-3'>
                    You don't have any data room yet. Create one!
                </Alert>
            }
        </Row>
        </>
    );
};

export default ListDataRooms;