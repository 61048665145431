import React, { useState, useEffect, useContext, useCallback } from 'react';
import { formatDate } from '../../utils/Date';
import Comment from './Comment';
import CommentForm from './CommentForm';
import axios from 'axios';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';
import { useUser } from '../../general/UserProvider';
import { TEAM_URL } from '../../utils/urls';
import { DataRoomContext } from '../DisplayDataRoom';

const CommentSection = ({ objectId, objectType, onCommentChange, canComment = false }) => {
    const { token } = useUser();
    const [comments, setComments] = useState([]);
    const dataroom_id = useContext(DataRoomContext);

    const fetchComments = useCallback(async (source) => {
        if (!objectId || !token) return;

        try {
            const response = await api.get(API_ROUTES.GET_COMMENTS, {
                params: {
                    object_id: objectId,
                    object_type: objectType
                },
                cancelToken: source.token
            });
            
            setComments(response.data); 
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                console.error("Failed to fetch comments:", error);
            }
        }
    }, [objectId, objectType, token]);

    useEffect(() => {
        const source = axios.CancelToken.source();
        fetchComments(source);

        return () => {
            source.cancel('CommentSection unmounted, request cancelled.'); 
        };
    }, [fetchComments]);

    const handleCommentDeleted = useCallback((commentId) => {
        setComments(prevComments => prevComments.filter(c => c.comment_id !== commentId));
        onCommentChange?.(-1);
    }, [onCommentChange]);

    const handleCommentAdded = useCallback((newComment) => {
        setComments(prevComments => [...prevComments, newComment]);
        onCommentChange?.(1); 
    }, [onCommentChange]);

    const getTeamUrl = useCallback((createdBy) => {
        return TEAM_URL.replace(':dataroomId', dataroom_id).replace(':teamId', createdBy);
    }, [dataroom_id]);

    const renderedComments = [...comments].reverse().map((item, index) => (
        <Comment 
            key={item.comment_id} 
            commentId={item.comment_id}
            user={item.created_by}
            user_img="https://d29fhpw069ctt2.cloudfront.net/icon/image/37746/preview.svg"
            user_url={getTeamUrl(item.created_by)}
            date={formatDate(item.created_at)}
            comment={item.content} 
            className={index === 0 ? 'last-comment' : ''}
            onDelete={() => handleCommentDeleted(item.comment_id)}
        />
    ));

    return (
        <>
            {canComment && (
                <CommentForm 
                    object_type={objectType} 
                    onCommentAdded={handleCommentAdded} 
                    objectId={objectId} 
                />
            )}
            {renderedComments}
        </>
    );
};

// Exporter le composant mémorisé
export default React.memo(CommentSection);