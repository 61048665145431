import React from 'react';
import { Col } from 'react-bootstrap';

import './WSCardMenu.css';

function WSCardMenu({ cardInfo, onClick }) {
    return (
        <Col sm={cardInfo.name === '...' ? 1 : 2} className="mb-3">
            <div className="wscardmenu" onClick={onClick} title={cardInfo.name}>
                <span>{cardInfo.name}</span>
            </div>
        </Col>
    );
}

export default WSCardMenu;