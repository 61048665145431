import React, { useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import GenericModal from '../../general/GenericModal';

import { Alert } from 'react-bootstrap';

import PermissionPerGroup from './PermissionPerGroup';
import GroupsPerUser from './GroupsPerUser';

import './Permissions.css';

function Permissions() {
    const [error, setError] = useState(null);

    const [modalState, setModalState] = useState({
        isFullscreen: false,
        isCentered: true,
        isNoPadding: false,
        content: "Oops, I can see an error.",
        title: "Error",
        isOpen: false
    });

    function groupItemsByFolderName(data) {
        return data.reduce((acc, item) => {
            if (!acc[item.folder_name]) {
                acc[item.folder_name] = [];
            }
            acc[item.folder_name].push(item);
            return acc;
        }, {});
    }

    const renderTooltip = (props, folderHierarchy) => {
        return (
          <Tooltip {...props}>{folderHierarchy.join(' > ')}</Tooltip>
        );
      };

    return (
        <div className="groups">
            { error ? (
                <Alert key="danger" variant="danger" className='mt-3'>
                    {error}
                </Alert>
            ) : (
                <>
                    <GroupsPerUser 
                        setError={setError} 
                        groupItemsByFolderName={groupItemsByFolderName} 
                        setModalState={setModalState} 
                        renderTooltip={renderTooltip}
                    />
                    <PermissionPerGroup 
                        setError={setError}
                        groupItemsByFolderName={groupItemsByFolderName} 
                        renderTooltip={renderTooltip}
                    />
                </>
            )}
            <GenericModal
                title={modalState.title}
                show={modalState.isOpen}
                onHide={() => setModalState(prevState => ({ ...prevState, isOpen: false }))}
                fullscreen={modalState.isFullscreen}
                centered={modalState.isCentered}
                no_padding={modalState.isNoPadding}
            >
                {modalState.content}
            </GenericModal>
        </div>
    );
};

export default Permissions;