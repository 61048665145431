import React, { useCallback, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown } from "react-bootstrap";
import DeleteForm from '../DeleteForm';
import RenameForm from '../RenameForm';
import ActivitiesList from '../../general/ActivitiesList';

function FolderActions({ item, handleComments, commentCount, setModalProperties, deleteFolder, setCurrentFolder, updateFolder, refetch }) {
    const [show, setShow] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, right: 0 });
    const toggleRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (show && toggleRef.current) {
            const rect = toggleRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                right: window.innerWidth - rect.right + 160,
            });
        }
    }, [show]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
                toggleRef.current && !toggleRef.current.contains(event.target)) {
                setShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleEditClick = (item) => {
        setModalProperties(prevProps => ({
            ...prevProps,
            title: "Rename",
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false,
            content: <RenameForm 
                        item={item} 
                        setModalProperties={setModalProperties} 
                        submitAction={submitUpdateFolder} 
                    />
        }));
        setShow(false);
    };
    
    const handleActivities = (item) => {
        setModalProperties(prevProps => ({
            ...prevProps,
            title: "Activities",
            content:  <ActivitiesList object_type="FOLDER" object_id={item.id} />,
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false
        }));
        setShow(false);
    };

    const updateFolderInState = useCallback((folder, folderIdToUpdate, newName, newIsCategory) => {
        if (folder.id === folderIdToUpdate) {
            return { ...folder, name: newName, is_category: newIsCategory };
        }
    
        if (folder.subcategories) {
            const updatedSubcategories = folder.subcategories.map(subFolder => 
                updateFolderInState(subFolder, folderIdToUpdate, newName, newIsCategory)
            );
            return { ...folder, subcategories: updatedSubcategories };
        }
    
        return folder;
    }, []);

    const submitUpdateFolder = useCallback(async (folder_id, updatedFolderData) => {
        const { localName, isCategory } = updatedFolderData;
        const updatedFolderDataToSend = { "name": localName, "is_category": isCategory };
        try {
            await updateFolder(folder_id, updatedFolderDataToSend);
            refetch(folder_id);
            setCurrentFolder(currentFolder => 
                updateFolderInState(currentFolder, folder_id, localName, isCategory)
            );
            setModalProperties(prevProps => ({ ...prevProps, isOpen: false }));
        } catch (error) {
            console.error("Error updating folder:", error);
        }
    },  [updateFolder, refetch, updateFolderInState, setCurrentFolder, setModalProperties]);

    const handleDeleteFolder = useCallback(async (folder_id) => {
        try {
            await deleteFolder(folder_id);
            setCurrentFolder(prevFolder => ({
                ...prevFolder,
                subcategories: prevFolder.subcategories.filter(folder => folder.id !== folder_id)
            }));
            setModalProperties(prevProps => ({ ...prevProps, isOpen: false }));
        } catch (error) {
            console.error("Error deleting folder:", error);
        }
    }, [deleteFolder, setCurrentFolder, setModalProperties]);
    
    const handleDelete = () => {
        setModalProperties(prevProps => ({
            ...prevProps,
            title: "Delete a folder",
            content: <DeleteForm 
                item={item} 
                setModalProperties={setModalProperties} 
                submitDelete={() => handleDeleteFolder(item.id)} 
            />,
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false
        }));
        setShow(false);
    };

    const renderDropdown = () => (
        <div 
            ref={dropdownRef}
            style={{
                position: 'absolute',
                top: `${dropdownPosition.top}px`,
                right: `${dropdownPosition.right}px`,
                zIndex: 1050,
            }}
        >
            <Dropdown.Menu show={true} align="end">
                <Dropdown.Item onClick={() => { handleComments(item); setShow(false); }}>
                    <i className="bi bi-chat-left-dots icon-spacing"></i> {commentCount} comment{commentCount !== 1 ? 's' : ''}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleEditClick(item)}>
                    <i className="bi bi-pencil icon-spacing"></i> Rename
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleActivities(item)}>
                    <i className="bi bi-clock-history icon-spacing"></i> Activities
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleDelete()}>
                    <i className="bi bi-trash3 icon-spacing"></i> Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </div>
    );

    return (
        <>
            <span
                ref={toggleRef}
                onClick={() => setShow(!show)}
                className="action-icon cursorHover no-arrow"
            >
                <i className="bi bi-three-dots"></i>
            </span>
            {show && ReactDOM.createPortal(
                renderDropdown(),
                document.body
            )}
        </>
    );
}

export default FolderActions;