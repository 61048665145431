import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { Link, NavLink, useMatch } from 'react-router-dom';
import { Nav, NavLink as BSNavLink, Spinner, Accordion, Offcanvas } from 'react-bootstrap';
import { useUser } from './UserProvider';
import { usePermissions, PermissionsProvider } from '../hooks/permissions/useUserPermissions';
import { DataRoomContext } from '../dataroom/DisplayDataRoom';
import api from '../services/api';
import { API_ROUTES } from '../services/apiRoutes';
import './Sidebar.css';

const CustomNavLink = React.memo(({ to, children, icon, onClick }) => {
  const match = useMatch(to);
  return (
    <BSNavLink
      as={NavLink}
      to={to}
      className={`d-flex align-items-center ${match ? 'active' : ''}`}
      onClick={onClick}
    >
      <i className={`bi ${icon} me-2`}></i>
      {children}
    </BSNavLink>
  );
});

const DataRoomNavLinks = React.memo(({ dataRoomId, onLinkClick }) => {
  const { permissions, error, isLoading } = usePermissions();

  const hasPermissionForCategory = useCallback((permissionKey, action) => {
    if (!permissions?.categories) return false; 
    return Object.values(permissions.categories).some(category => 
      category[permissionKey]?.includes(action)
    );
  }, [permissions]);

  const hasGlobalPermission = useCallback((key, action) => {
    return permissions?.global?.[key]?.includes(action) || false;
  }, [permissions]);

  if (isLoading) return (
    <div className="d-flex justify-content-center py-3">
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );

  if (error) return <div className="text-danger py-2">Error loading permissions: {error.message}</div>;

  // const hasAssistantPermission = hasPermissionForCategory("Assistant", "Interact");
  const hasWorkspaceReadPermission = hasPermissionForCategory("Note", "Read");
  const hasTasksReadPermission = hasPermissionForCategory("IR", "Read");
  const hasGroupsAndPermissionsPermission = hasGlobalPermission("Groups & permissions", "Read");
  const hasWorkflowPermission = true;

  const navLinks = [
    { condition: hasWorkflowPermission, to: "workflow", label: "Workflow", icon: "bi-bar-chart-steps" },
    { condition: true, to: "documents", label: "Documents", icon: "bi-file-earmark-text" },
    // { condition: hasControlPointsPermission, to: "controlpoints", label: "Control Points", icon: "bi-check-circle" },
    { condition: hasWorkspaceReadPermission, to: "workspace", label: "Workspace", icon: "bi-pen" },
    // { condition: hasAssistantPermission, to: "assistant", label: "Copilot", icon: "bi-robot" },
    { condition: hasTasksReadPermission, to: "irl", label: "Q&A", icon: "bi-list-task" },
    { condition: true, to: "activities", label: "Activities", icon: "bi-activity" },
    { condition: true, to: "team", label: "Team", icon: "bi-people" },
    { condition: hasGroupsAndPermissionsPermission, to: "permissions", label: "Groups & Permissions", icon: "bi-shield-lock" },
  ];

  return (
    <Nav className="flex-column">
      {navLinks.map(({ condition, to, label, icon }) => 
        condition && (
          <CustomNavLink key={to} to={`/dataroom/${dataRoomId}/${to}`} icon={icon} onClick={onLinkClick}>
            {label}
          </CustomNavLink>
        )
      )}
    </Nav>
  );
});

const UserMenu = React.memo(({ username, isAuthorizedUser, isExpanded, onToggle, onCopyToken, onSignOut, onLinkClick }) => {
  return (
    <div className="mt-auto">
      <BSNavLink as={NavLink} to="/contact" className="d-flex align-items-center py-2" onClick={onLinkClick}>
        <i className="bi bi-envelope me-2"></i>
        Contact us
      </BSNavLink>
      <BSNavLink onClick={onToggle} className="d-flex align-items-center justify-content-between">
        <div>
          <i className="bi bi-person-circle me-2"></i>
          {username && username.slice(0, 15)}
        </div>
        <i className={`bi bi-chevron-${isExpanded ? 'up' : 'down'}`}></i>
      </BSNavLink>
      <Nav className={`flex-column ${isExpanded ? '' : 'd-none'}`}>
        {isAuthorizedUser && (
          <>
            <BSNavLink as={NavLink} to="/admin" className="d-flex align-items-center py-2" onClick={onLinkClick}>
              <i className="bi bi-person-gear me-2"></i> Administration
            </BSNavLink>
            <BSNavLink as="button" onClick={onCopyToken} className="d-flex align-items-center py-2 text-start">
              <i className="bi bi-clipboard me-2"></i> Copy token
            </BSNavLink>
          </>
        )}
        <BSNavLink as="button" onClick={onSignOut} className="d-flex align-items-center py-2 text-start">
          <i className="bi bi-box-arrow-right me-2"></i> Logout
        </BSNavLink>
      </Nav>
    </div>
  );
});

function Sidebar({ show, onHide }) {
  const { username, signOut, token } = useUser();
  const [dataRooms, setDataRooms] = useState([]);
  const [isUserMenuExpanded, setUserMenuExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const activeDataRoomId = useContext(DataRoomContext);
  const [openDataRooms, setOpenDataRooms] = useState(new Set());
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 992);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchDataRooms = useCallback(async () => {
    if (!token) return;
    setIsLoading(true);
    try {
      const response = await api.get(API_ROUTES.LIST_DATAROOMS);
      setDataRooms(response.data);
    } catch (error) {
      console.error("Error fetching data rooms:", error);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchDataRooms();
  }, [fetchDataRooms]);

  useEffect(() => {
    if (activeDataRoomId) {
      setOpenDataRooms(prevOpen => new Set(prevOpen).add(activeDataRoomId.toString()));
    }
  }, [activeDataRoomId]);

  const toggleDataRoom = (dataRoomId) => {
    setOpenDataRooms(prevOpen => {
      const newOpen = new Set(prevOpen);
      if (newOpen.has(dataRoomId)) {
        newOpen.delete(dataRoomId);
      } else {
        newOpen.add(dataRoomId);
      }
      return newOpen;
    });
  };

  const copyTokenToClipboard = useCallback(() => {
    navigator.clipboard.writeText(token)
      .then(() => console.log('Token copied to clipboard'))
      .catch(err => console.error('Failed to copy token:', err));
  }, [token]);

  const isAuthorizedUser = useMemo(() => 
    ['jcrisch', 'michelcenteno'].includes(username), [username]);

  const handleLinkClick = useCallback(() => {
    if (isMobile) {
      onHide();
    }
  }, [isMobile, onHide]);

  const sidebarContent = (
    <div className="sidebar-content d-flex flex-column h-100">
      <div className="sidebar-top">
        <div className="logo-container">
          <Link to="/" onClick={handleLinkClick}><img src="/logo/Skelter.png" alt="Skelter" className='w-100' /></Link>
        </div>
      </div>
      <div className="flex-grow-1 overflow-auto">
        {isLoading ? (
          <div className="d-flex justify-content-center py-5">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Accordion activeKey={Array.from(openDataRooms)} alwaysOpen>
            {dataRooms.map(dataroom => (
              <Accordion.Item key={dataroom.dataroom_id} eventKey={dataroom.dataroom_id.toString()}>
                <Accordion.Header onClick={() => toggleDataRoom(dataroom.dataroom_id.toString())}>
                  <div className="d-flex align-items-center w-100">
                    <i className="bi bi-square-fill me-2" style={{ color: dataroom.color_code }}></i>
                    <span className="dataroom-name">{dataroom.name}</span>
                    {dataroom.alertCount > 0 && (
                      <span className="badge bg-danger ms-auto">{dataroom.alertCount}</span>
                    )}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <PermissionsProvider>
                    <DataRoomNavLinks dataRoomId={dataroom.dataroom_id} onLinkClick={handleLinkClick} />
                  </PermissionsProvider>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        )}
      </div>
      <div className="sidebar-bottom mt-auto">
        <UserMenu
          username={username}
          isAuthorizedUser={isAuthorizedUser}
          isExpanded={isUserMenuExpanded}
          onToggle={() => setUserMenuExpanded(prev => !prev)}
          onCopyToken={copyTokenToClipboard}
          onSignOut={signOut}
          onLinkClick={handleLinkClick}
        />
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <Offcanvas show={show} onHide={onHide} placement="start" className="sidebar-offcanvas" backdrop={false}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {sidebarContent}
        </Offcanvas.Body>
      </Offcanvas>
    );
  }

  return (
    <div className="sidebar h-100">
      {sidebarContent}
    </div>
  );
}

export default React.memo(Sidebar);