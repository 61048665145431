import { useState } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useActivateUser = (token) => {
  const [activating, setActivating] = useState(false);
  const [error, setError] = useState(null);

  const activateUser = async (userId) => {
    if (!token) return;

    setActivating(true);
    setError(null);

    try {
      await api.post(
        API_ROUTES.ADMIN_ACTIVATE_USER(userId),
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setActivating(false);
      return true;
    } catch (error) {
      setError(error.message || "Error activating user");
      setActivating(false);
      throw error;
    }
  };

  return { activateUser, activating, error };
};