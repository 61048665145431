import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import './Overview.css';

function Overview() {

    return (
        <Container className="d-flex flex-column align-items-center justify-content-center min-vh-100">
            <Row className='d-flex justify-content-center align-items-center pb-3'>
                <Col md={12} className="hiddenLogo">
                    <Link to="/dataroom"><img src="/logo/Skelter.png" alt="Skelter" className='w-100' /></Link>
                </Col>
            </Row>
        </Container>
    );
};

export default Overview;