import axios from 'axios';
import { Auth } from 'aws-amplify';
import { fetchToken } from './auth';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(async (config) => {
    const session = await Auth.currentSession();
    config.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
    return config;
}, (error) => {
    console.error(`Request error: ${error}`);  
    return Promise.reject(error);
});

api.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    console.error(`Response error from ${error.config?.url}: ${error}`);
    if (error.response && error.response.status === 401) {
        await fetchToken();
        const session = await Auth.currentSession();
        error.config.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
        return api.request(error.config);
    }
    return Promise.reject(error);
});

export default api;
