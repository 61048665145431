import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import './AdminDashboard.css';
import { useFetchUsers } from '../hooks/admin/useFetchUsers';
import Sidebar from './Sidebar';
import DashboardHome from './DashboardHome';
import UserManagement from './User/UserManagement';
import DataroomManagement from './Dataroom/DataroomManagement';
import TemplateManagement from './Templates/TemplateManagement';
import PageHeader from './PageHeader';

const AdminDashboard = ({ token }) => {
  const [activeTab, setActiveTab] = useState('home');

  const { users, loading: usersLoading, error: usersError, setUsers } = useFetchUsers(token);

  return (
    <Row>
      <Sidebar activeTab={activeTab} setActiveTab={setActiveTab} />
      <Col md={9} lg={10} className="admin_main-content">
        {activeTab === 'home' && (
          <>
            <PageHeader 
              title="Dashboard"
              showButton={false}
            />
            <DashboardHome token={token} />
          </>
        )}
        {activeTab === 'users' && (
          <UserManagement 
            users={users} 
            loading={usersLoading} 
            error={usersError} 
            token={token}
            setUsers={setUsers}
          />
        )}
        {activeTab === 'datarooms' && (
          <DataroomManagement token={token} />
        )}
        {activeTab === 'templates' && (
          <TemplateManagement token={token} />
        )}
      </Col>
    </Row>
  );
};

export default AdminDashboard;