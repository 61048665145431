import React from 'react';
import { Card, Table, Button, Spinner, Alert } from 'react-bootstrap';

const DataroomGroupsInfo = ({ dataroom, groups, loading, error, onEditGroups }) => {
  return (
    <Card className="mb-4">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="mb-0">
            <i className="bi bi-diagram-3 me-2"></i>
            Groups for {dataroom.name}
          </h4>
          <Button variant="primary" onClick={onEditGroups}>
            <i className="bi bi-pencil me-2"></i>
            Edit Groups
          </Button>
        </div>

        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <Alert variant="danger">
            <i className="bi bi-exclamation-triangle me-2"></i>
            {error}
          </Alert>
        ) : (
          <>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th><i className="bi bi-tag me-2"></i>Name</th>
                  <th><i className="bi bi-card-text me-2"></i>Description</th>
                  <th><i className="bi bi-folder me-2"></i>Folder</th>
                </tr>
              </thead>
              <tbody>
                {groups.map(group => (
                  <tr key={group.group_id}>
                    <td>{group.group_name}</td>
                    <td>{group.description}</td>
                    <td>{group.folder_name || 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {groups.length === 0 && (
              <Alert variant="info">
                <i className="bi bi-info-circle me-2"></i>
                No groups have been created for this dataroom yet.
              </Alert>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default DataroomGroupsInfo;