import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddDocumentAttribute = (token) => {
  const [adding, setAdding] = useState(false);
  const [addError, setAddError] = useState(null);

  const addDocumentAttribute = async (data) => {
    setAdding(true);
    setAddError(null);
    try {
      const response = await api.post(
        API_ROUTES.ADMIN_ADD_ATTRIBUTE, 
        data,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setAdding(false);
      return response.data;
    } catch (err) {
      setAddError(err.message || 'An error occurred while adding the attribute');
      setAdding(false);
      return null;
    }
  };

  return { addDocumentAttribute, adding, addError };
};

export const useUpdateDocumentAttribute = (token) => {
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const updateDocumentAttribute = async (data) => {
    setUpdating(true);
    setUpdateError(null);
    try {
      const response = await api.put(
        `${API_ROUTES.ADMIN_UPDATE_ATTRIBUTE}/${data.id}`, 
        data,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setUpdating(false);
      return response.data;
    } catch (err) {
      setUpdateError(err.message || 'An error occurred while updating the attribute');
      setUpdating(false);
      return null;
    }
  };

  return { updateDocumentAttribute, updating, updateError };
};

export const useDeleteDocumentAttribute = (token) => {
  const [deleting, setDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const deleteDocumentAttribute = async (attributeId) => {
    setDeleting(true);
    setDeleteError(null);
    try {
      await api.delete(
        `${API_ROUTES.ADMIN_DELETE_ATTRIBUTE}/${attributeId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setDeleting(false);
      return true;
    } catch (err) {
      setDeleteError(err.message || 'An error occurred while deleting the attribute');
      setDeleting(false);
      return false;
    }
  };

  return { deleteDocumentAttribute, deleting, deleteError };
};

export const useFetchDocumentAttributes = (token) => {
  const [documentAttributes, setDocumentAttributes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAttributes = useCallback(async () => {
    if (!token) return;

    setLoading(true);
    setError(null);
    try {
      const response = await api.get(
        API_ROUTES.ADMIN_GET_DOCUMENT_ATTRIBUTES,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setDocumentAttributes(response.data);
    } catch (error) {
      setError(error.message || "Error fetching document attributes");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchAttributes();
  }, [fetchAttributes]);

  return { 
    documentAttributes, 
    setDocumentAttributes, 
    loading, 
    error, 
    refetch: fetchAttributes 
  };
};