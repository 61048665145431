import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Container, Image, Alert } from 'react-bootstrap';
import '../EditModal.css';

const UserEditModal = ({ show, handleClose, user, onSave, isProcessing, error }) => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    company_name: '',
    company_url: '',
    picture_url: '',
  });

  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username || '',
        email: user.email || '',
        company_name: user.company_name || '',
        company_url: user.company_url || '',
        picture_url: user.picture_url || '',
      });
    } else {
      setFormData({
        username: '',
        email: '',
        company_name: '',
        company_url: '',
        picture_url: '',
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const isSubmitDisabled = !formData.username.trim() || !isValidEmail(formData.email.trim()) || isProcessing;

  return (
    <Modal show={show} onHide={handleClose} fullscreen={true} className="edit-modal modal-fullscreen">
      <Modal.Header>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs="auto">
              <Button variant="link" onClick={handleClose} className="p-0 text-dark">
                <i className="bi bi-x-lg"></i>
              </Button>
            </Col>
            <Col>
              <Modal.Title>{user ? 'Edit User' : 'Create User'}</Modal.Title>
            </Col>
            <Col xs="auto">
              <Button 
                variant="primary" 
                onClick={handleSubmit} 
                disabled={isSubmitDisabled}
              >
                {isProcessing ? 'Processing...' : (user ? 'Save Changes' : 'Create User')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          <Row>
            <Col lg={8}>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-4">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                    className="form-control-lg"
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="form-control-lg"
                    isInvalid={formData.email.trim() !== '' && !isValidEmail(formData.email.trim())}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                    className="form-control-lg"
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Company URL</Form.Label>
                  <Form.Control
                    type="url"
                    name="company_url"
                    value={formData.company_url}
                    onChange={handleChange}
                    className="form-control-lg"
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Picture URL</Form.Label>
                  <Form.Control
                    type="url"
                    name="picture_url"
                    value={formData.picture_url}
                    onChange={handleChange}
                    className="form-control-lg"
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col lg={4}>
              <div className="preview">
                <h4>User Preview</h4>
                <Image src={formData.picture_url || 'https://via.placeholder.com/150'} roundedCircle className="mb-3" style={{ width: '150px', height: '150px' }} />
                <h5>{formData.username}</h5>
                <p>{formData.email}</p>
                <p>{formData.company_name}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default UserEditModal;