import React, { useContext} from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import TeamCard from './TeamCard';
import { DataRoomContext } from '../DisplayDataRoom';
import { useUser } from '../../general/UserProvider';
import { useFetchTeamMember } from '../../hooks/team/useFetchTeamMember'; 

function TeamCardWrapper() {
    const { token } = useUser();
    const dataroomId = useContext(DataRoomContext);
    const { userId } = useParams();
    const { member, loading, error } = useFetchTeamMember(token, dataroomId, userId);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!member) {
        return <div>No member found.</div>;
    }

    return (
        <Container fluid>
            <Row className="row-eq-height">
                <Col md={3} className="mb-4">
                    <TeamCard member={member} />
                </Col>
            </Row>
        </Container>
    );
}

export default TeamCardWrapper;
