import React from 'react';

import CustomFileViewer from '../../../general/CustomFileViewer';
import './Source.css';

const Sources = ({ sources, onLinkClick, setModalProperties }) => {
    const uniqueSourceURLs = new Set();
    const uniqueSources = sources.filter(source => {
        if (uniqueSourceURLs.has(source.sourcePDFURL)) {
            return false;
        } else {
            uniqueSourceURLs.add(source.sourcePDFURL);
            return true;
        }
    });

    const handleSourceClick = (source) => {
        setModalProperties(prevProps => ({
            ...prevProps,
            title: source.sourceFileBaseName,
            content: <CustomFileViewer fileurl={source.sourcePDFURL} />,
            isOpen: true,
            isFullscreen: true,
            isNoPadding: true
        }));
    }

    if (uniqueSources.length === 0) {
        return null;
    }

    return (
        <div className="source-container">
            <hr />
            <span className="source-title">Sources:</span>
            {uniqueSources.map((source, index) => (
                <button 
                    key={source.sourcePDFURL} 
                    onClick={(e) => handleSourceClick(source)} 
                    className="source-button"
                >
                    <i className="bi bi-file-earmark-text-fill source-icon"></i>
                    <span className="source-text">{source.sourceFileBaseName}</span>
                </button>
            ))}
        </div>
    );
};

export default Sources;
