import { useState, useCallback } from 'react';

export const useProcedureExecution = (token, dataroomId, procedureId, setters) => {
   const [executionStatus, setExecutionStatus] = useState(null);
   const [executing, setExecuting] = useState(false);
   const [error, setError] = useState(null);
   const [progress, setProgress] = useState(null);

   const startExecution = useCallback(() => {
       return new Promise((resolve, reject) => {
           setExecuting(true);
           setError(null);

           const eventSourceUrl = `${process.env.REACT_APP_API_URL}/dataroom/${dataroomId}/procedures/${procedureId}/execute?token=${encodeURIComponent(token)}`;
           console.log('Starting procedure execution:', eventSourceUrl);

           const eventSource = new EventSource(eventSourceUrl);

           eventSource.onopen = (event) => {
               console.log('EventSource connection opened:', event);
           };

           eventSource.onmessage = (event) => {
               console.log('Raw SSE data received:', event.data);
               try {
                   const data = JSON.parse(event.data);
                   console.log('Parsed SSE data:', data);

                   if (data.error) {
                       console.error('Error in SSE data:', data.error);
                       eventSource.close();
                       setError(data.error);
                       setExecuting(false);
                       reject(new Error(data.error));
                       return;
                   }

                   setExecutionStatus(data.step);
                   setProgress(data);

                   if (data.results && setters?.setFindings) {
                       setters.setFindings(data.results);
                   }
                   if (data.conclusion && setters?.setConclusion) {
                       setters.setConclusion(data.conclusion);
                   }
                   if (data.assessment && setters?.setAssessment) {
                       setters.setAssessment(data.assessment);
                   }

                   // Vérifier si l'exécution est terminée
                   if (data.step === 'completion' && data.status === 'completed') {
                       console.log('Procedure execution completed');
                       eventSource.close();
                       setExecuting(false);
                       resolve(true);
                   }
               } catch (error) {
                   console.error('Error parsing SSE data:', error);
                   eventSource.close();
                   setError('Error parsing server response');
                   setExecuting(false);
                   reject(error);
               }
           };

           eventSource.onerror = (err) => {
               console.error('SSE Error:', err);
               console.log('EventSource readyState:', eventSource.readyState);
               eventSource.close();
               setError('Connection to server lost');
               setExecuting(false);
               reject(new Error('Connection to server lost'));
           };
       });
   }, [token, dataroomId, procedureId, setters]);

   const getStepProgress = () => {
       if (!progress) return null;

       switch (progress.step) {
           case 'starting':
               return 10;
           case 'findings':
               return 20;
           case 'conclusion':
               return 60;
           case 'completion':
               return 100;
           default:
               return 0;
       }
   };

   return {
       executionStatus,
       executing,
       error,
       progress,
       getStepProgress,
       startExecution
   };
};