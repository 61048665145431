import React from 'react';
import { Card, Table, Button, Spinner, Alert } from 'react-bootstrap';

const DataroomRolesInfo = ({ dataroom, users, loading, error, onEditRoles }) => {
  const usersWithRoles = users.filter(user => user.roles.length > 0);

  return (
    <Card className="mb-4">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="mb-0">
            <i className="bi bi-people me-2"></i>
            Roles for {dataroom.name}
          </h4>
          <Button variant="primary" onClick={onEditRoles}>
            <i className="bi bi-pencil me-2"></i>
            Edit Roles
          </Button>
        </div>

        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <Alert variant="danger">
            <i className="bi bi-exclamation-triangle me-2"></i>
            {error}
          </Alert>
        ) : usersWithRoles.length > 0 ? (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th><i className="bi bi-person me-2"></i>User</th>
                <th><i className="bi bi-shield me-2"></i>Role</th>
              </tr>
            </thead>
            <tbody>
              {usersWithRoles.map(user => (
                <tr key={user.id}>
                  <td>{user.username}</td>
                  <td>
                    {user.roles.map(role => (
                      <span key={role.id} className="badge bg-secondary me-1">
                        {role.name}
                      </span>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Alert variant="info">
            <i className="bi bi-info-circle me-2"></i>
            No users have been assigned roles in this dataroom yet.
          </Alert>
        )}

        <div className="mt-3">
          <small className="text-muted">
            <i className="bi bi-info-circle me-1"></i>
            Total users: {users.length} | Users with roles: {usersWithRoles.length}
          </small>
        </div>
      </Card.Body>
    </Card>
  );
};

export default DataroomRolesInfo;