import React from 'react';
import './NoteInformation.css';

import DeleteNote from '../DeleteNote';
import { useUser } from '../../../general/UserProvider';
import { formatDate } from '../../../utils/Date';

function NoteInformation({ info, commentCount, closeModal, deleteNote }) {
    const { username } = useUser();
    const canDelete = info.permissions.Delete;
    const showDelete = canDelete || username === info.created_by;

    return (
        <div className="note-information">
            <table className="note-information-table">
                <tbody>
                    <tr>
                        <th>Item</th>
                        <th>Information</th>
                    </tr>
                    <tr>
                        <td><strong>Creation Date:</strong></td>
                        <td><i>{formatDate(info.created_at)}</i></td>
                    </tr>
                    <tr>
                        <td><strong>Last Edited:</strong></td>
                        <td><i>{formatDate(info.updated_at)}</i></td>
                    </tr>
                    <tr>
                        <td><strong>Author:</strong></td>
                        <td><i>{info.created_by}</i></td>
                    </tr>
                    <tr>
                        <td><strong>Comments:</strong></td>
                        <td><i>{commentCount} comment{commentCount !== 1 ? 's' : ''}</i></td>
                    </tr>
                    <tr>
                        <td><strong>Tags:</strong></td>
                        <td><i>{info.tags.join(', ')}</i></td>
                    </tr>
                </tbody>
            </table>
            {showDelete && (
                <DeleteNote note_id={info?.id} closeModal={closeModal} deleteNote={deleteNote} />
            )}
        </div>
    );
}
export default NoteInformation;
