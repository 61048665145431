import React from 'react';
import { Breadcrumb } from "react-bootstrap";
import { Container } from 'react-bootstrap';

function WSBreadCrumb({ breadcrumbItems, handleBreadcrumbClick }) {

    const addIconForRoot = (name) => {
        if (name === "Root") {
            return <i className="bi bi-house-fill"></i>;
        } else {
            return name;
        }
    };


    return (
        <Container fluid>
            <Breadcrumb className="breadcrumbDoc">
                {breadcrumbItems.map((segment, index) => (
                    <Breadcrumb.Item key={index} onClick={() => handleBreadcrumbClick(index)}>
                        {addIconForRoot(segment.name)}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        </Container>
    );
  }

export default WSBreadCrumb;
