import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Container, Alert, ListGroup } from 'react-bootstrap';
import { useFetchCycles } from '../../../hooks/admin/cyclesHooks';
import { useFetchAssertions } from '../../../hooks/admin/assertionsHooks';

const ControlEditModal = ({ 
  show, 
  handleClose, 
  control, 
  onSave, 
  isProcessing, 
  error,
  token,
  currentAssertions,
  onAssertionsChange
}) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    order: 0,
    steps: [],
    cycle_id: ''
  });

  const { cycles, loading: cyclesLoading } = useFetchCycles(token);
  const { assertions, loading: assertionsLoading } = useFetchAssertions(token);

  useEffect(() => {
    if (control) {
      setFormData({
        name: control.name || '',
        description: control.description || '',
        order: control.order || 0,
        steps: control.steps || [],
        cycle_id: control.cycle?.id || '',
      });
    } else {
      setFormData({
        name: '',
        description: '',
        order: 0,
        steps: [],
        cycle_id: '',
      });
    }
  }, [control]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: name === 'order' ? parseInt(value, 10) : value
    }));
  };

  const handleStepChange = (index, value) => {
    const updatedSteps = [...formData.steps];
    updatedSteps[index] = value;
    setFormData(prevData => ({
      ...prevData,
      steps: updatedSteps
    }));
  };

  const addStep = () => {
    setFormData(prevData => ({
      ...prevData,
      steps: [...prevData.steps, '']
    }));
  };

  const removeStep = (index) => {
    const updatedSteps = formData.steps.filter((_, i) => i !== index);
    setFormData(prevData => ({
      ...prevData,
      steps: updatedSteps
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    if (!isProcessing) { 
      const completeFormData = {
        ...formData,
        assertion_ids: currentAssertions
      };
      onSave(completeFormData, true);
    }
  };

  const toggleAssertion = async (assertionId, event) => {
    event.preventDefault();
    event.stopPropagation();

    const newAssertions = currentAssertions.includes(assertionId.toString())
      ? currentAssertions.filter(id => id !== assertionId.toString())
      : [...currentAssertions, assertionId.toString()];
    
    onAssertionsChange(newAssertions);
    
    const updatedData = {
      ...formData,
      assertion_ids: newAssertions
    };
    onSave(updatedData, false);
  };

  const isFormValid = () => {
    return formData.name.trim() !== '' && 
           formData.description.trim() !== '' &&
           !isNaN(formData.order) &&
           formData.steps.length > 0 &&
           formData.steps.every(step => step.trim() !== '') &&
           formData.cycle_id !== '';
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" className="edit-modal" centered>
      <Modal.Header>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs="auto">
              <Button variant="link" onClick={handleClose} className="p-0 text-dark">
                <i className="bi bi-x-lg"></i>
              </Button>
            </Col>
            <Col>
              <Modal.Title>{control ? 'Edit Control' : 'Create Control'}</Modal.Title>
            </Col>
            <Col xs="auto">
              <Button 
                variant="primary" 
                onClick={handleSubmit} 
                disabled={isProcessing || !isFormValid()}
              >
                {isProcessing ? 'Processing...' : (control ? 'Save Changes' : 'Create Control')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          <Form noValidate onSubmit={handleSubmit}>
            <h2 className="title-h2 font-title mx-3">Control Details</h2>
            <div className="px-4">
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="e.g., Bank Reconciliation"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="description"
                  as="textarea"
                  rows={3}
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Describe the purpose and scope of this control"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Order</Form.Label>
                <Form.Control
                  name="order"
                  type="number"
                  value={formData.order}
                  onChange={handleChange}
                  placeholder="Enter the order of this control"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Cycle</Form.Label>
                <Form.Select
                  name="cycle_id"
                  value={formData.cycle_id}
                  onChange={handleChange}
                  required
                  disabled={cyclesLoading}
                >
                  <option value="">Select a cycle</option>
                  {cycles.map((cycle) => (
                    <option key={cycle.id} value={cycle.id}>
                      {cycle.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Steps</Form.Label>
                {formData.steps.map((step, index) => (
                  <Row key={index} className="mb-2">
                    <Col>
                      <Form.Control
                        value={step}
                        onChange={(e) => handleStepChange(index, e.target.value)}
                        placeholder={`Step ${index + 1}`}
                        required
                      />
                    </Col>
                    <Col xs="auto">
                      <Button variant="outline-danger" onClick={() => removeStep(index)}>
                        <i className="bi bi-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button variant="outline-primary" onClick={addStep} className="mt-2">
                  Add Step
                </Button>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Assertions</Form.Label>
                {assertionsLoading ? (
                  <Alert variant="info">Loading assertions...</Alert>
                ) : (
                  <ListGroup className="mt-2">
                    {assertions.map(assertion => (
                      <ListGroup.Item 
                        key={assertion.id}
                        action
                        active={currentAssertions.includes(assertion.id.toString())}
                        onClick={(e) => toggleAssertion(assertion.id, e)}
                        className="d-flex align-items-center"
                      >
                        <div className="me-2">
                          {currentAssertions.includes(assertion.id.toString()) ? (
                            <i className="bi bi-check-circle-fill text-success"></i>
                          ) : (
                            <i className="bi bi-circle text-muted"></i>
                          )}
                        </div>
                        <div>
                          <div className="fw-medium">{assertion.title}</div>
                          {assertion.description && (
                            <small className="text-muted">{assertion.description}</small>
                          )}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Form.Group>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ControlEditModal;