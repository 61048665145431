import React from 'react';
import './InitChat.css';

function InitChat() {
    return (
        <div className="initChat">
            <h2>Skelter.</h2>
        </div>
    );
};

export default InitChat;