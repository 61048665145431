import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFetchTeamMember = (token, dataroomId, userId) => {
    const [member, setMember] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const fetchTeamMember = async () => {
            if (!token || !dataroomId || !userId) return;

            try {
                const response = await api.get(API_ROUTES.DATAROOM_USER(dataroomId, userId), {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (isMounted) {
                    setMember(response.data);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error.message || "Error fetching data");
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchTeamMember();

        return () => {
            isMounted = false;
        };
    }, [token, dataroomId, userId]);

    return { member, loading, error };
};
