import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFetchDiscussion = (token, dataroomId, sessionId) => {
    const [discussion, setDiscussion] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const fetchDiscussion = async () => {
            if (!token || !dataroomId || !sessionId) return;

            try {
                const response = await api.get(API_ROUTES.GET_DISCUSSION_BY_SESSION_ID(sessionId, dataroomId), {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (isMounted) {
                    setDiscussion(response.data); 
                }
            } catch (error) {
                if (isMounted) {
                    setError(error.message || "Error fetching data");
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchDiscussion();

        return () => {
            isMounted = false;
        };
    }, [token, dataroomId, sessionId]);

    return { discussion, loading, error };
};
