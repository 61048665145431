import React from 'react';

import { Button } from 'react-bootstrap';

function DeleteRequest( {item, closeModal, submitDelete} ) {

    return (
        <>
            <p>Are you sure you want to delete the information request ?</p>
            <Button className="mt-3" variant="secondary" onClick={() => closeModal()}>
                Close
            </Button>
            <Button className="mt-3 mx-3" variant="danger" onClick={() => submitDelete(item)}>
                Yes, delete
            </Button>
        </>
    );
}

export default DeleteRequest;