import React from 'react';
import { Card, Badge, Row, Col, Button, Alert } from 'react-bootstrap';
import './ModalContent.css';

const ModalContent = ({ point, onFileOpen }) => {
  const getStatusVariant = (status) => {
    switch(status?.toLowerCase()) {
      case 'agree': return 'success';
      case 'disagree': return 'danger';
      case 'cannot determine': return 'secondary';
      default: return 'primary';
    }
  };

  const renderDetails = () => {
    const detailEntries = Object.entries(point.details).filter(([key]) => key !== 'errors' && key !== 'reason');
    return detailEntries.map(([key, detail]) => (
      <div key={key} className="detail-container mb-4">
        <div className="detail-header d-flex justify-content-between align-items-center mb-3">
          <h6 className="mb-0">
            <Badge bg="secondary" className="me-2 file-type-badge">
              <i className="bi bi-file-earmark me-1"></i>
              {detail.document_type}
            </Badge>
            {detail.attribute_name}
          </h6>
          {detail.file_url && (
            <Button
              className="btn-file"
              onClick={() => onFileOpen(detail.file_url, detail.page)}
              size="sm"
            >
              <i className="bi bi-eye me-1"></i>
              View File
            </Button>
          )}
        </div>
        <Row className="mb-2">
          <Col md={6}>
            <strong>Value:</strong> <span className="detail-value">{detail.value}</span>
          </Col>
          <Col md={6}>
            <strong>Page:</strong> <span className="detail-page">{detail.page}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Justification:</strong>
            <p className="detail-justification mt-1">{detail.justification}</p>
          </Col>
        </Row>
      </div>
    ));
  };

  return (
    <div className="modal-content-wrapper">
      <h4 className="modal-title">{point.name}</h4>
      
      <Card className="mb-4 shadow-sm-box">
        <Card.Body>
          <h5 className="mb-3">
            <i className="bi bi-flag me-2"></i>Status: 
            <Badge 
              bg={getStatusVariant(point.status.status)} 
              className="ms-2 p-2 status-badge"
            >
              {point.status.status}
            </Badge>
          </h5>
          <h6>Description:</h6>
          <p className="mb-0">{point.description}</p>
        </Card.Body>
      </Card>
      
      {point.details && Object.keys(point.details).length > 0 && (
        <Card className="mb-4 shadow-sm-box">
          <Card.Body>
            <h5 className="mb-3">
              <i className="bi bi-list-ul me-2"></i>
              Details
            </h5>
            {renderDetails()}
            {point.details.errors && point.details.errors.length > 0 && (
              <Alert variant="warning">
                <h6>Conversion Errors:</h6>
                <ul>
                  {point.details.errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </Alert>
            )}
          </Card.Body>
        </Card>
      )}
      
      {point.details && point.details.reason && (
        <Card className="shadow-sm-box">
          <Card.Body>
            <h5 className="mb-3">
              <i className="bi bi-chat-right-text me-2"></i>
              Reason
            </h5>
            <p className="mb-0">{point.details.reason}</p>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default ModalContent;