import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export function usePermissions(dataroom_id, token) {
  const [permData, setPermData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) return;

    const fetchPermissions = async () => {
      try {
        const response = await api.get(API_ROUTES.PERMISSIONS_BY_DATAROOM(dataroom_id));
        setPermData(response.data);
      } catch (error) {
        setError("There was an error fetching the permissions");
      }
    };

    fetchPermissions();
  }, [dataroom_id, token]);

  const updatePermission = async (group_id, permission_id, currentValue) => {
      if (!token) return;

      const newValue = !currentValue;
      try {
          await api.post(API_ROUTES.UPDATE_PERMISSION, {
              group_id,
              permission_id,
              value: newValue
          });

          setPermData(prevData => {
              return prevData.map(group => {
                  if (group.group_id === group_id) {
                      return {
                          ...group,
                          permissions: group.permissions.map(permission => {
                          if (Object.keys(permission)[0] === permission_id.toString()) {
                              return { [permission_id]: newValue };
                          }
                          return permission;
                          }),
                  };
                  }
                  return group;
              });
          });
      } catch (error) {
          setError("There was an error updating the permission");
          // Gérer l'erreur comme vous le souhaitez, peut-être en la remontant à l'utilisateur
      }
  };

  return { permData, error, updatePermission };
}