import React from 'react';
import { Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DocumentItem from './DocumentItem';
import DocumentSection from './DocumentSection';

const RequestedDocuments = ({
    documents,
    onUploadDocument,
    onDeleteRequest,
    processingRequests,
    onAddClick,
    controlId,
    onSendReference
}) => {
    const AddButton = (
        <Button 
            variant="light"
            size="sm"
            onClick={onAddClick}
            className="dm-add-btn"
        >
            <i className="bi bi-plus-lg"></i>
        </Button>
    );

    const handleUpload = (reference) => {
        const documentInfo = {
            name: reference.reference_name || reference.reference_description,
            description: reference.suggested_folder 
                ? `${reference.suggested_folder.name} / ${reference.reference_description}`
                : reference.reference_description,
            id: reference.id,
            requestedDocumentId: reference.id,
            folder: reference.suggested_folder_id || -1,
        };
        
        onUploadDocument(documentInfo);
    };

    const handleSendReference = (e, reference) => {
        e.stopPropagation();
        if (!reference.is_requested) {
            onSendReference(controlId, reference.id);
        }
    };

    return (
        <DocumentSection title="Requested Documents" action={AddButton}>
            {processingRequests.size > 0 && (
                <div className="dm-processing">
                    <Spinner 
                        animation="border" 
                        size="sm" 
                        className="me-2"
                    />
                    <span>AI is analyzing your criteria...</span>
                </div>
            )}

            {documents.length > 0 ? (
                <div className="dm-list">
                    {documents.map((reference) => (
                        <DocumentItem
                            key={reference.id}
                            name={reference.reference_name || reference.reference_description}
                            description={reference.reference_description}
                            icon="bi-file-earmark"
                            metadata={<></>}
                            actions={
                                <>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                {reference.is_requested ? 'Request sent' : 'Submit request'}
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            <i 
                                                className={`bi bi-send dm-action-btn ${reference.is_requested ? 'dm-action-btn--done' : 'dm-action-btn--request'}`}
                                                role={reference.is_requested ? 'status' : 'button'}
                                                onClick={(e) => handleSendReference(e, reference)}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Upload document</Tooltip>}
                                    >
                                        <i 
                                            className="bi bi-cloud-upload dm-action-btn dm-action-btn--send"
                                            onClick={() => handleUpload(reference)}
                                        />
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Delete request</Tooltip>}
                                    >
                                        <i 
                                            className="bi bi-trash dm-action-btn dm-action-btn--delete"
                                            onClick={() => onDeleteRequest(controlId, reference.id)}
                                        />
                                    </OverlayTrigger>
                                </>
                            }
                        />
                    ))}
                </div>
            ) : (
                <p className="dm-empty">No document requests.</p>
            )}
        </DocumentSection>
    );
};

export default RequestedDocuments;