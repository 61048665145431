import React from 'react';

function TableHeader({ sortOrder, setSortOrder, sortedColumn, setSortedColumn }) {

    const handleHeaderClick = (column) => {
        if (sortedColumn === column) {
            setSortOrder(sortOrder === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortedColumn(column);
            setSortOrder('ascending');
        }
    };


    return (
        <thead>
            <tr>
                <th onClick={() => handleHeaderClick('name')} className='tableCellName cursor-pointer'>
                    Name {sortedColumn === 'name' && (sortOrder === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleHeaderClick('created_at')} className="cursor-pointer">
                    Created at {sortedColumn === 'created_at' && (sortOrder === 'ascending' ? '↑' : '↓')}
                </th>
                <th>
                    Comments
                </th>
                <th></th>
            </tr>
        </thead>
    );
};

export default TableHeader;