import { useState, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useUpdateFile = (token) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateFile = useCallback(async (fileId, updates) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await api.put(API_ROUTES.UPDATE_FILE(fileId), updates, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.status !== 200) {
                throw new Error('Failed to update folder');
            }

            return response.data;
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [token]);

    return {
        updateFile,
        isLoading,
        error
    };
};
