import { useState } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFetchDataroomRoles = (token) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);

  const fetchDataroomRoles = async (dataroomId) => {
    if (!token) return;

    setLoading(true);
    setError(null);

    try {
      const response = await api.get(
        `${API_ROUTES.ADMIN_GET_DATAROOM_USERS_AND_ROLES}/${dataroomId}/users-and-roles`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      setError(error.message || "Error fetching users and roles");
      setLoading(false);
      setUsers([]);
    }
  };

  return { fetchDataroomRoles, users, loading, error };
};