import React, { useContext, useCallback, useEffect } from 'react';

import './Bubble.css';
import Sources from './Source';
import PinManager from './PinManager';
import SocialShare from './SocialShare';
import { DataRoomContext } from '../../DisplayDataRoom';

import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { cleanMarkdown } from '../../../utils/Markdown';
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'

import api from '../../../services/api';
import { API_ROUTES } from '../../../services/apiRoutes';
import { useUser } from '../../../general/UserProvider';

function Bubble({ side, text, sources, isLoaded, social_url, folders, setModalProperties }) {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);
    const isSpinnerLoading = text.includes('<div class="spinner-grow spinner-grow-sm" role="status">');

    const preprocessMath = (text) => {
        // Convert \[...\] to $$...$$
        let processedText = text.replace(/\\\[([\s\S]*?)\\\]/g, (match, p1) => `$$${p1}$$`);
        // Convert \(...\) to $...$
        processedText = processedText.replace(/\\\(([\s\S]*?)\\\)/g, (match, p1) => `$${p1}$`);
        return processedText;
    };

    useEffect(() => {
        console.log("Original text:", text);
        const cleanedText = cleanMarkdown(text);
        console.log("Cleaned text:", cleanedText);
        const processedText = preprocessMath(cleanedText);
        console.log("Processed text:", processedText);
    }, [text]);

    const renderContent = () => {
        if (isSpinnerLoading) {
            console.log("Rendering spinner");
            return parse(text);
        }
        const cleanedText = cleanMarkdown(text);
        const processedText = preprocessMath(cleanedText);
        
        return (
            <ReactMarkdown 
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeKatex]}
                components={{
                    code: ({node, inline, className, children, ...props}) => {
                        return <code className={className} {...props}>{children}</code>;
                    },
                    math: ({value}) => {
                        return <span>{value}</span>;
                    }
                }}
            >
                {processedText}
            </ReactMarkdown>
        );
    };

    const createNote = useCallback(async (title, content, folderId) => {
        if(!token) return;

        try {
            const response = await api.post(API_ROUTES.CREATE_NOTE_IN_DATAROOM(dataroom_id), {
                title,
                content,
                folder_ids: [folderId]
            });
            return response.data;
        } catch (error) {
            console.error("Error adding note:", error);
            throw error;
        }
    }, [dataroom_id, token]);

    const linkNoteWithFolder = useCallback(async (noteId, folderId) => {
        if(!token) return;

        try {
            const response = await api.post(API_ROUTES.LINK_NOTE_TO_FOLDER, {
                note_id: noteId,
                folder_id: folderId
            });
            return response.data;
        } catch (error) {
            console.error("Error linking note with folder:", error);
            throw error;
        }
    }, [token]);

    const removeNoteFromWorkspace = useCallback(async (noteId) => {
        if(!token) return;
        try {
            const response = await api.delete(API_ROUTES.DELETE_NOTE_FROM_DATAROOM(noteId));
            return response.data;
        } catch (error) {
            console.error("Error removing note:", error);
            throw error;
        }
    }, [token]);

    return (
        <div className={`bubble ${side}`}>
            {renderContent()}
            {isLoaded && (
                <Sources sources={sources} setModalProperties={setModalProperties} />
            )}
            {isLoaded && side === 'bubbleLeft' && 
                <>
                    <hr />
                    <div className="d-flex  justify-content-between  w-100">
                        <SocialShare social_url={social_url} />
                        <PinManager 
                            folders={folders} 
                            createNote={createNote}
                            linkNoteWithFolder={linkNoteWithFolder}
                            removeNoteFromDataroom={removeNoteFromWorkspace}
                            content={text}
                        />
                    </div>
                </>
            }
        </div>
    );
};

export default Bubble;