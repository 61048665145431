import { useState, useCallback } from 'react';

export const useIRFormData = (initialState) => {
    const [formData, setFormData] = useState(initialState);

    const handleInputChange = useCallback((key) => (e) => {
        setFormData(prev => ({ ...prev, [key]: e.target.value }));
    }, []);

    return [formData, handleInputChange, setFormData];
};
