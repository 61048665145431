import React from 'react';
import { Form } from 'react-bootstrap';

const SocialForm = ({ formData, handleChange }) => (
  <>
    <h2 className="title-h2 font-title m-3">Social</h2>
    <div className="px-4">
      <Form.Group className="mb-3">
        <Form.Label><i className="bi bi-slack icon-spacing-10"></i> Slack</Form.Label>
        <Form.Control
          name="slack_url"
          value={formData.slack_url}
          onChange={handleChange}
          placeholder="https://yourworkspace.slack.com/archives/C01A9V5LPDQ"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label><i className="bi bi-microsoft-teams icon-spacing-10"></i> Teams</Form.Label>
        <Form.Control
          name="teams_url"
          value={formData.teams_url}
          onChange={handleChange}
          placeholder="https://teams.microsoft.com/l/channel/193a0d9e9f9a3dd4474db6ad8"
        />
      </Form.Group>
    </div>
  </>
);

export default SocialForm;