import { useState, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useGroups = (token, dataroom_id, setError) => {
    const [groupsWithUsers, setGroupsWithUsers] = useState([]);

    const loadUserGroups = useCallback(async () => {
        if (!token) return;
        try {
            const response = await api.get(API_ROUTES.GROUPS_WITH_USERS(dataroom_id));
            setGroupsWithUsers(response.data);
        } catch (error) {
            setError("There was an error fetching the groups with users");
        }
    }, [token, dataroom_id, setError]);

    const updateGroupUser = useCallback(async (user_id, group_id, isMember) => {
        if (!token) return;
        try {
            await api.post(API_ROUTES.UPDATE_USER_GROUP, {
                user_id,
                group_id,
                value: !isMember
            });
            setGroupsWithUsers(prevData => {
                const newData = [...prevData];
                const groupToUpdate = newData.find(group => group.group_id === group_id);
                if (!isMember) {
                    groupToUpdate.users.push(user_id);
                } else {
                    const index = groupToUpdate.users.indexOf(user_id);
                    if (index > -1) {
                        groupToUpdate.users.splice(index, 1);
                    }
                }
                return newData;
            });
        } catch (error) {
            console.error("There was an error updating the user's group membership:", error);
        }
    }, [token]);

    return { groupsWithUsers, loadUserGroups, updateGroupUser };
};
