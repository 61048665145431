import { useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useCreateInformationRequest = (token, setRequests) => {
    const createRequest = useCallback(async (dataroomId, requestData) => {
        try {
            const response = await api.post(API_ROUTES.CREATE_IRL, {
                ...requestData,
                dataroom_id: dataroomId
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            setRequests(prevRequests => [...prevRequests, response.data]);
        } catch (error) {
            console.error('There was a problem creating the request:', error);
        }
    }, [token, setRequests]);

    return createRequest;
};