import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddFolder = (token) => {
    const [adding, setAdding] = useState(false);
    const [addError, setAddError] = useState(null);

    const addFolder = async (folderData) => {
        setAdding(true);
        setAddError(null);

        try {
            const response = await api.post(
                API_ROUTES.ADMIN_ADD_FOLDERS,
                folderData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setAdding(false);
            return response.data;
        } catch (error) {
            setAddError(error.response?.data?.message || 'An error occurred while adding the folder');
            setAdding(false);
            throw error;
        }
    };

    return { addFolder, adding, addError };
};

export const useUpdateFolder = (token) => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const updateFolder = async (folderData) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            const response = await api.put(
                `${API_ROUTES.ADMIN_UPDATE_FOLDERS}/${folderData.id}`,
                folderData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setUpdating(false);
            return response.data;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the folder');
            setUpdating(false);
            throw error;
        }
    };

    return { updateFolder, updating, updateError };
};

export const useDeleteFolder = (token) => {
    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const deleteFolder = async (folderId) => {
        setDeleting(true);
        setDeleteError(null);

        try {
            await api.delete(
                `${API_ROUTES.ADMIN_DELETE_FOLDERS}/${folderId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setDeleting(false);
        } catch (error) {
            setDeleteError(error.response?.data?.message || 'An error occurred while deleting the folder');
            setDeleting(false);
            throw error;
        }
    };

    return { deleteFolder, deleting, deleteError };
};

export const useFetchFolders = (token) => {
    console.log('useFetchFolders');
    const [folders, setFolders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchFolders = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await api.get(
                API_ROUTES.ADMIN_GET_FOLDERS,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            // Maintenant nous trions d'abord par parent_id (null en premier)
            // puis par folder_order au sein de chaque niveau
            const sortedFolders = [...response.data].sort((a, b) => {
                // Les dossiers racine (parent_id null) viennent en premier
                if (a.parent_id === null && b.parent_id !== null) return -1;
                if (a.parent_id !== null && b.parent_id === null) return 1;
                
                // Si même parent, trier par folder_order
                if (a.parent_id === b.parent_id) {
                    return a.folder_order - b.folder_order;
                }
                
                // Sinon trier par parent_id
                return (a.parent_id || 0) - (b.parent_id || 0);
            });

            setFolders(sortedFolders);
            setLoading(false);
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while fetching folders');
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        fetchFolders();
    }, [fetchFolders]);

    return { folders, setFolders, loading, error, refetch: fetchFolders };
};

export const useReorderFolders = (token) => {
    const [reordering, setReordering] = useState(false);
    const [reorderError, setReorderError] = useState(null);

    const reorderFolders = async ({ folderId, newParentId, newIndex }) => {
        setReordering(true);
        setReorderError(null);

        try {
            const response = await api.post(
                API_ROUTES.ADMIN_REORDER_FOLDERS,
                {
                    folderId,
                    newParentId,
                    newIndex
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setReordering(false);
            // La réponse devrait contenir la liste complète des dossiers mise à jour
            return response.data.folders;
        } catch (error) {
            setReorderError(error.response?.data?.message || 'An error occurred while reordering the folders');
            setReordering(false);
            throw error;
        }
    };

    return { reorderFolders, reordering, reorderError };
};