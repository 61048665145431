import { useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useUpdateDataroom = (dataroomId, token) => {
    const updateDataroom = useCallback(async (updatedData, onSuccess, onError) => {
        if (!token) return;

        try {
            const response = await api.put(API_ROUTES.UPDATE_DATAROOM(dataroomId), updatedData);
            if (onSuccess) {
                onSuccess(response.data);
            }
        } catch (error) {
            console.error("There was an error updating the dataroom:", error);
            if (onError) {
                onError('Error updating dataroom.');
            }
        }
    }, [dataroomId, token]);

    return updateDataroom;
};
