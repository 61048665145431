// EditableField.jsx
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import './EditableField.css';

const EditableField = ({ value, onSave, type = "text", options = [], children }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);

  const handleSave = () => {
    onSave(editValue);
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && type !== 'textarea') {
      handleSave();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setEditValue(value);
    }
  };

  return (
    <div className="editable-field">
      {isEditing ? (
        <div className="editable-field__edit-mode">
          <div className="input-group">
            {type === "select" ? (
              <Form.Select 
                value={editValue} 
                onChange={(e) => setEditValue(e.target.value)}
                className="form-select-sm"
                autoFocus
                onKeyDown={handleKeyDown}
              >
                {options.map(option => (
                  <option 
                    key={option.value} 
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            ) : type === "textarea" ? (
              <Form.Control
                as="textarea"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                className="form-control-sm"
                autoFocus
                onKeyDown={handleKeyDown}
              />
            ) : (
              <Form.Control
                type={type}
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                className="form-control-sm"
                autoFocus
                onKeyDown={handleKeyDown}
              />
            )}
            <div className="input-group-append">
              <Button 
                size="sm" 
                variant="success"
                className="btn-save" 
                onClick={handleSave}
              >
                <i className="bi bi-check"></i>
              </Button>
              <Button 
                size="sm" 
                variant="light"
                className="btn-cancel" 
                onClick={() => {
                  setIsEditing(false);
                  setEditValue(value);
                }}
              >
                <i className="bi bi-x"></i>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div 
          className="editable-field__display-mode"
          onClick={() => {
            setEditValue(value);
            setIsEditing(true);
          }}
        >
          {children}
          <span className="edit-icon">
            <i className="bi bi-pencil-square"></i>
          </span>
        </div>
      )}
    </div>
  );
};

export default EditableField;