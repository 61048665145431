import { useState, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useDeleteFolder = (token) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const deleteFolder = useCallback(async (folderId) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await api.delete(API_ROUTES.DELETE_FOLDER(folderId), {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.status !== 200) {
                throw new Error('Failed to delete folder');
            }

            return response.data;
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [token]);

    return {
        deleteFolder,
        isLoading,
        error
    };
};
