import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export function usePermissionNames(dataroom_id, token) {
  const [permNames, setPermNames] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) return;

    const fetchPermissionNames = async () => {
      try {
        const response = await api.get(API_ROUTES.PERMISSIONS_NAMES_BY_DATAROOM(dataroom_id));
        setPermNames(response.data);
      } catch (error) {
        setError("There was an error fetching the permission names");
      }
    };

    fetchPermissionNames();
  }, [dataroom_id, token]);

  return { permNames, error };
}