import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddDataroomCycle = (token) => {
    const [adding, setAdding] = useState(false);
    const [addError, setAddError] = useState(null);

    const addCycle = async (dataroomId, cycleData) => {
        setAdding(true);
        setAddError(null);

        try {
            const response = await api.post(
                API_ROUTES.ADMIN_ADD_DATAROOM_CYCLE(dataroomId),
                cycleData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setAdding(false);
            return response.data.cycle;
        } catch (error) {
            setAddError(error.response?.data?.message || 'An error occurred while adding the cycle');
            setAdding(false);
            throw error;
        }
    };

    return { addCycle, adding, addError };
};

export const useUpdateDataroomCycle = (token) => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const updateCycle = async (dataroomId, cycleId, cycleData) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            const response = await api.put(
                API_ROUTES.ADMIN_UPDATE_DATAROOM_CYCLE(dataroomId, cycleId),
                cycleData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setUpdating(false);
            return response.data.cycle;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the cycle');
            setUpdating(false);
            throw error;
        }
    };

    return { updateCycle, updating, updateError };
};

export const useDeleteDataroomCycle = (token) => {
    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const deleteCycle = async (dataroomId, cycleId) => {
        setDeleting(true);
        setDeleteError(null);

        try {
            await api.delete(
                API_ROUTES.ADMIN_DELETE_DATAROOM_CYCLE(dataroomId, cycleId),
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setDeleting(false);
        } catch (error) {
            setDeleteError(error.response?.data?.message || 'An error occurred while deleting the cycle');
            setDeleting(false);
            throw error;
        }
    };

    return { deleteCycle, deleting, deleteError };
};

export const useFetchDataroomCycles = (token, dataroomId) => {
    const [cycles, setCycles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCycles = useCallback(async () => {
        if (!dataroomId) return;
        
        setLoading(true);
        setError(null);

        try {
            const response = await api.get(
                API_ROUTES.ADMIN_DATAROOM_CYCLES(dataroomId),
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setCycles(response.data);
            setLoading(false);
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while fetching cycles');
            setLoading(false);
        }
    }, [token, dataroomId]);

    useEffect(() => {
        fetchCycles();
    }, [fetchCycles]);

    return { cycles, setCycles, loading, error, refetch: fetchCycles };
};

export const useInitializeDataroomCycles = (token) => {
    const [initializing, setInitializing] = useState(false);
    const [initializeError, setInitializeError] = useState(null);

    const initializeCycles = async (dataroomId) => {
        setInitializing(true);
        setInitializeError(null);

        try {
            const response = await api.post(
                API_ROUTES.ADMIN_INITIALIZE_DATAROOM_CYCLES(dataroomId),
                {},
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setInitializing(false);
            return response.data;
        } catch (error) {
            setInitializeError(error.response?.data?.message || 'An error occurred while initializing cycles');
            setInitializing(false);
            throw error;
        }
    };

    return { initializeCycles, initializing, initializeError };
};