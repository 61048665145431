import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar, Container } from 'react-bootstrap';

import { useUser } from '../general/UserProvider';
import AdminDashboard from './AdminDashboard';
import './AdminLayout.css'; 

const AdminLayout = () => {
  const { token } = useUser();
  return (
    <div className="admin-layout">
      <Navbar bg="dark" variant="dark" expand="lg" className="mb-3 fixed-top">
        <Container fluid>
          <Navbar.Brand href="/admin">Administration</Navbar.Brand>
        </Container>
      </Navbar>
      <div className="content-wrapper">
        <Container fluid>
          <AdminDashboard token={token} />
          <Outlet />
        </Container>
      </div>
    </div>
  );
};

export default AdminLayout;