export const processContent = (content) => {
    if (!content) return 'No content available yet.';

    // Divise le contenu en lignes
    const lines = content.split('\n');

    // Traite chaque ligne
    const processedLines = lines.map(line => {
        // Vérifie si la ligne fait partie d'un tableau (commence et finit par | ou contient |)
        if (line.includes('|')) {
            // Sépare les cellules
            const cells = line.split('|');
            
            // Traite chaque cellule
            const processedCells = cells.map(cell => {
                let processedCell = cell.trim();
                
                // Applique les transformations seulement si la cellule contient exactement un des termes
                if (/^not\s+satisfied$/i.test(processedCell) || 
                    /^not\s+material$/i.test(processedCell) || 
                    /^not\s+significant$/i.test(processedCell) ||
                    /^insignificant$/i.test(processedCell)) {
                    return ` <font color="red"><b>${processedCell}</b></font> `;
                }
                else if (/^satisfied$/i.test(processedCell) || 
                         /^material$/i.test(processedCell) || 
                         /^significant$/i.test(processedCell)) {
                    return ` <font color="green"><b>${processedCell}</b></font> `;
                }
                
                return cell; // Retourne la cellule inchangée si pas de match
            });
            
            // Reconstruit la ligne de tableau
            return processedCells.join('|');
        }
        
        // Retourne la ligne inchangée si ce n'est pas un tableau
        return line;
    });

    // Reconstruit le contenu
    return processedLines.join('\n');
};
