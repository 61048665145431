import { useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useDeleteInformationRequest = (token, setRequests, closeModal) => {
    const deleteRequest = useCallback(async (requestId) => {
        try {
            await api.delete(API_ROUTES.DELETE_IRL(requestId), {
                headers: { Authorization: `Bearer ${token}` }
            });

            setRequests(prevRequests => prevRequests.filter(request => request.irl_id !== requestId));
            closeModal();
        } catch (error) {
            console.error('Error deleting IRL', error);
        }
    }, [token, setRequests, closeModal]);

    return deleteRequest;
};