import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useFetchDatarooms } from '../hooks/admin/useFetchDatarooms';
import { useFetchUsers } from '../hooks/admin/useFetchUsers';

const DashboardHome = ({ token }) => {
  const { datarooms, loading: dataroomsLoading } = useFetchDatarooms(token);
  const { users, loading: usersLoading } = useFetchUsers(token);

  if (dataroomsLoading || usersLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Row>
      <Col md={4}>
        <Card className="admin_card mb-4 shadow-sm">
          <Card.Body>
            <Card.Title>Users</Card.Title>
            <Card.Text className="admin_big-number">{users.length}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card className="admin_card mb-4 shadow-sm">
          <Card.Body>
            <Card.Title>Datarooms</Card.Title>
            <Card.Text className="admin_big-number">{datarooms.length}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card className="admin_card mb-4 shadow-sm">
          <Card.Body>
            <Card.Title>Active Projects</Card.Title>
            <Card.Text className="admin_big-number">
              {datarooms.filter(d => d.is_active === true).length}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default DashboardHome;