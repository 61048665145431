import React, { useEffect } from 'react';
import { Modal, ListGroup, Badge, ProgressBar, Button } from 'react-bootstrap';
import './FileUploadModal.css';

const PREPARATION_STAGES = [
    { 
        id: 'audit_plan_extraction',
        name: 'Attributes Extraction',
        step: 'audit_plan_extraction'
    },
    { 
        id: 'cycle_risks_extraction',
        name: 'Cycle Risks Extraction',
        step: 'cycle_risks_extraction'
    },
    { 
        id: 'control_risks_extraction',
        name: 'Control Risks Extraction',
        step: 'control_risks_extraction'
    }
];

const AuditPreparationModal = ({ show, onHide, preparationStatus, onComplete }) => {
    useEffect(() => {
        if (preparationStatus?.step === 'completion' && preparationStatus?.status === 'completed') {
            // On attend un peu pour que l'utilisateur voie que c'est fini
            const timer = setTimeout(() => {
                onComplete?.();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [preparationStatus, onComplete]);

    const getStageStatus = (stage) => {
        if (!preparationStatus) {
            return { status: 'pending', progress: 0 };
        }

        if (preparationStatus.step === stage.step) {
            return {
                status: preparationStatus.status,
                progress: preparationStatus.status === 'in_progress' ? 50 : 
                         preparationStatus.status === 'completed' ? 100 : 0
            };
        }

        // Si on est après cette étape
        const stageIndex = PREPARATION_STAGES.findIndex(s => s.id === stage.id);
        const currentStageIndex = PREPARATION_STAGES.findIndex(s => s.step === preparationStatus.step);
        
        if (stageIndex < currentStageIndex) {
            return { status: 'success', progress: 100 };
        }
        
        return { status: 'pending', progress: 0 };
    };

    const isCompleted = preparationStatus?.step === 'completion' && 
                       preparationStatus?.status === 'completed';

    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Audit Preparation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {PREPARATION_STAGES.map((stage) => {
                        const { status, progress } = getStageStatus(stage);
                        return (
                            <ListGroup.Item key={stage.id} className="d-flex flex-column align-items-start file-item mt-2">
                                <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                                    <div className="file-info">
                                        <span className="file-name">{stage.name}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="file-status mr-2">
                                            {status === 'pending' && <Badge bg="secondary">En attente</Badge>}
                                            {status === 'in_progress' && <Badge bg="primary">En cours</Badge>}
                                            {status === 'completed' || status === 'success' ? 
                                                <Badge bg="success">Terminé</Badge> : null}
                                            {status === 'failed' && <Badge bg="danger">Erreur</Badge>}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <ProgressBar 
                                        now={progress} 
                                        label={`${progress}%`}
                                        variant={progress === 100 ? "success" : "primary"}
                                        striped={progress !== 100}
                                        animated={status === 'in_progress'}
                                    />
                                </div>
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="secondary" 
                    onClick={onHide}
                    disabled={!isCompleted && preparationStatus?.status !== 'failed'}
                >
                    {isCompleted ? 'Close' : 
                     preparationStatus?.status === 'failed' ? 'Close' : 
                     'Preparing...'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AuditPreparationModal;