import React from 'react';

const SocialShare = ({ social_url }) => {
    const hasSocialUrl = social_url && (social_url.slack_url || social_url.teams_url);
    
    const shareSocial = (event) => {
        event.preventDefault();
        console.log("shareSocial");
    };

    return (
        <div>
            {hasSocialUrl && (
                <>
                Share: 
                {social_url && social_url.slack_url && (
                    <button onClick={shareSocial} className="link-button">
                        <i title="Slack" className="bi bi-slack px-2"></i>
                    </button>
                )}

                {social_url && social_url.teams_url && (
                    <button onClick={shareSocial} className="link-button">
                        <i title="Teams" className="bi bi-microsoft-teams px-2"></i>
                    </button>
                )}
                </>
            )}
        </div>
    );
};

export default SocialShare;
