export const buildTemplateFolderTree = (folders) => {
    const folderMap = new Map();
    const rootItems = [];
  
    folders.forEach(folder => {
      folderMap.set(folder.id, { ...folder, children: [] });
    });
  
    folders.forEach(folder => {
      const node = folderMap.get(folder.id);
      if (folder.parent_id) {
        const parent = folderMap.get(folder.parent_id);
        if (parent) {
          parent.children.push(node);
        }
      } else {
        rootItems.push(node);
      }
    });
  
    return rootItems;
  };