import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Container, Alert } from 'react-bootstrap';

const TemplateFolderEditModal = ({ 
  show, 
  handleClose, 
  folder, 
  parentFolders,
  onSave, 
  isProcessing, 
  error
}) => {
  const [formData, setFormData] = useState({
    name: '',
    parent_id: null,
    folder_order: 0
  });

  useEffect(() => {
    if (folder) {
      setFormData({
        name: folder.name || '',
        parent_id: folder.parent_id || null,
        folder_order: folder.folder_order || 0
      });
    } else {
      setFormData({
        name: '',
        parent_id: null,
        folder_order: 0
      });
    }
  }, [folder]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: name === 'folder_order' ? parseInt(value, 10) : 
              name === 'parent_id' ? (value === '' ? null : parseInt(value, 10)) : 
              value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const isFormValid = () => {
    return formData.name.trim() !== '' && 
           !isNaN(formData.folder_order);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" className="edit-modal" centered>
      <Modal.Header>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs="auto">
              <Button variant="link" onClick={handleClose} className="p-0 text-dark">
                <i className="bi bi-x-lg"></i>
              </Button>
            </Col>
            <Col>
              <Modal.Title>{folder ? 'Edit Template Folder' : 'Create Template Folder'}</Modal.Title>
            </Col>
            <Col xs="auto">
              <Button 
                variant="primary" 
                onClick={handleSubmit} 
                disabled={isProcessing || !isFormValid()}
              >
                {isProcessing ? 'Processing...' : (folder ? 'Save Changes' : 'Create Template Folder')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <h2 className="title-h2 font-title mx-3">Template Folder Details</h2>
            <div className="px-4">
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="e.g., Financial Templates"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Parent Folder</Form.Label>
                <Form.Select
                  name="parent_id"
                  value={formData.parent_id || ''}
                  onChange={handleChange}
                >
                  <option value="">No parent (Root folder)</option>
                  {parentFolders?.map(parentFolder => (
                    parentFolder.id !== folder?.id && (
                      <option key={parentFolder.id} value={parentFolder.id}>
                        {parentFolder.name}
                      </option>
                    )
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Order</Form.Label>
                <Form.Control
                  name="folder_order"
                  type="number"
                  value={formData.folder_order}
                  onChange={handleChange}
                  placeholder="Enter the display order"
                  required
                />
              </Form.Group>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default TemplateFolderEditModal;