import React, { useCallback } from 'react';
import NewRequestForm from './NewRequestForm';

function AddTask({ 
    handleAddRequest, 
    permissions, 
    setModalState, 
    folders = [],
    users = []
}) {
    const hasAddPermissionInAnyCategory = () => {
        if (!permissions || !permissions.categories) {
            return false;
        }

        for (const categoryPermissions of Object.values(permissions.categories)) {
            if (categoryPermissions["IR"] && categoryPermissions["IR"].includes("Create")) {
                return true;
            }
        }

        return false;
    };

    const handleAddTaskClick = useCallback(() => {
        setModalState(prevState => {
            // Si la modal est déjà dans l'état souhaité, ne pas modifier
            if (
                prevState.isOpen &&
                prevState.title === "Create new task" &&
                prevState.fullscreen === false
            ) {
                return prevState;
            }

            // Sinon, mettre à jour l'état
            return {
                title: "Create new task",
                content: (
                    <NewRequestForm 
                        onSubmit={handleAddRequest}
                        folders={folders}
                        users={users}
                    />
                ),
                isOpen: true,
                fullscreen: false,
                centered: true
            };
        });
    }, [setModalState, handleAddRequest, folders, users]);

    if (!hasAddPermissionInAnyCategory()) {
        return null;
    }

    return (
        <button 
            className="btn-add-task" 
            onClick={handleAddTaskClick}
            type="button"
        >
            <i className="bi bi-plus-square icon-spacing"></i> Create a task
        </button>
    );
}

export default AddTask;