import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Toast } from 'react-bootstrap';
import Layout from '../general/Layout';

import api from '../services/api';
import { API_ROUTES } from '../services/apiRoutes';
import { useUser } from './UserProvider';


function Contact() {
    const { token } = useUser();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const isValidEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(String(email).toLowerCase());
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!formData.name || !formData.message || !isValidEmail(formData.email)) {
            setToastMessage("Please fill in all fields with valid data.");
            setShowToast(true);
            return;
        }

        if (!token) { 
            setToastMessage("You are not authenticated.");
            setShowToast(true);
            return;
        }
    
        try {
            if (!token) return; 
            const response = await api.post(API_ROUTES.SEND_MAIL, formData);
    
            if (response.status === 200) {
                setToastMessage("Email sent successfully!"); 
                setFormData(prevState => ({
                    ...prevState,
                    message: ''  
                }));
            } else {
                setToastMessage("Failed to send the email.");  
            }
        } catch (error) {
            setToastMessage("Failed to send the email.");  
        }
        setShowToast(true);
    };
    

    return (
        <Layout>
            <Container className="contact mt-5">
                <Row>
                    <Col>
                        <h2>Contact us</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName" className="my-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="John Doe" 
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formEmail" className="my-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="john@doe.com" 
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formMessage" className="my-3">
                                <Form.Label>Message</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows={3} 
                                    placeholder="Your message." 
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Button 
                                variant="primary" 
                                type="submit" 
                                className="my-1"
                                disabled={!formData.name || !formData.message || !isValidEmail(formData.email)}
                            >
                                <i className="bi bi-send icon-spacing"></i> Send
                            </Button>

                        </Form>
                    </Col>
                </Row>
            </Container>
            <Toast 
                onClose={() => setShowToast(false)} 
                show={showToast} 
                delay={3000} 
                autohide
                style={{
                    position: 'absolute',
                    bottom: 20,
                    right: 20,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">Notification</strong>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </Layout>
    );
};

export default Contact;
