import React from 'react';
import { Col, Container } from 'react-bootstrap';
import { Outlet, useParams } from 'react-router-dom';

import Layout from '../general/Layout';
import { PermissionsProvider } from '../hooks/permissions/useUserPermissions';

export const DataRoomContext = React.createContext();

function DisplayDataRoom() {
    const { id: dataroom_id } = useParams();

    return (
        <DataRoomContext.Provider value={dataroom_id}>
            <PermissionsProvider>
                <Layout>
                    <Container fluid className="mt-3">
                        <Col md={12}>
                            <Outlet />
                        </Col>
                    </Container>
                </Layout>
            </PermissionsProvider>
        </DataRoomContext.Provider>
    );
}

export default DisplayDataRoom;
