import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddAssertion = (token) => {
    const [adding, setAdding] = useState(false);
    const [addError, setAddError] = useState(null);

    const addAssertion = async (assertionData) => {
        setAdding(true);
        setAddError(null);

        try {
            const response = await api.post(
                API_ROUTES.ADMIN_ADD_ASSERTIONS,
                assertionData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setAdding(false);
            return response.data;
        } catch (error) {
            setAddError(error.response?.data?.message || 'An error occurred while adding the assertion');
            setAdding(false);
            throw error;
        }
    };

    return { addAssertion, adding, addError };
};

export const useUpdateAssertion = (token) => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const updateAssertion = async (assertionData) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            const response = await api.put(
                `${API_ROUTES.ADMIN_UPDATE_ASSERTIONS}/${assertionData.id}`,
                assertionData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setUpdating(false);
            return response.data;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the assertion');
            setUpdating(false);
            throw error;
        }
    };

    return { updateAssertion, updating, updateError };
};

export const useDeleteAssertion = (token) => {
    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const deleteAssertion = async (assertionId) => {
        setDeleting(true);
        setDeleteError(null);

        try {
            await api.delete(
                `${API_ROUTES.ADMIN_DELETE_ASSERTIONS}/${assertionId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setDeleting(false);
        } catch (error) {
            setDeleteError(error.response?.data?.message || 'An error occurred while deleting the assertion');
            setDeleting(false);
            throw error;
        }
    };

    return { deleteAssertion, deleting, deleteError };
};

export const useFetchAssertions = (token) => {
    const [assertions, setAssertions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchAssertions = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await api.get(
                API_ROUTES.ADMIN_GET_ASSERTIONS,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setAssertions(response.data);
            setLoading(false);
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while fetching audit task types');
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        fetchAssertions();
    }, [fetchAssertions]);

    return { assertions, loading, error, refetch: fetchAssertions };
};