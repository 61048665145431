import React, { useState, useEffect } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import ControlPoint from './ControlPoint';

const calculatePercentage = (item) => {
  let totalPoints = 0;
  let agreePoints = 0;

  const countPoints = (node) => {
    if (node.control_points) {
      node.control_points.forEach(cp => {
        totalPoints++;
        if (cp.status?.status?.toLowerCase() === 'agree') {
          agreePoints++;
        }
      });
    }
    if (node.children) {
      node.children.forEach(countPoints);
    }
  };

  countPoints(item);

  return totalPoints > 0 ? Math.round((agreePoints / totalPoints) * 100) : 0;
};

const getAllItemIds = (data) => {
  let ids = [];
  data.forEach(item => {
    ids.push(item.id);
    if (item.children) {
      ids = [...ids, ...getAllItemIds(item.children)];
    }
  });
  return ids;
};

const TreeView = ({ data, level = 0, onStatusClick, onContentClick }) => {
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    const allIds = getAllItemIds(data);
    const expandedState = allIds.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
    setExpandedItems(expandedState);
  }, [data]);

  if (!data || data.length === 0) return null;

  const toggleExpand = (itemId) => {
    setExpandedItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };

  return (
    <>
      {data.map((item) => {
        const isExpanded = expandedItems[item.id];
        const hasChildren = item.children && item.children.length > 0;
        const hasControlPoints = item.control_points && item.control_points.length > 0;
        const percentage = calculatePercentage(item);

        return (
          <React.Fragment key={item.id}>
            <tr className={`tree-item-row ${hasChildren || hasControlPoints ? 'category-row' : ''}`}>
              <td colSpan="2" className="tree-item-cell">
                <div className="tree-item-container" style={{ paddingLeft: `${level * 24}px` }}>
                  <div className="tree-item-header" onClick={() => toggleExpand(item.id)}>
                    {(hasChildren || hasControlPoints) && (
                      <span className="toggle-icon">
                        <i className={`bi ${isExpanded ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
                      </span>
                    )}
                    <span className="item-name">{item.name}</span>
                  </div>
                  {(hasChildren || hasControlPoints) && (
                    <div className="item-progress">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-${item.id}`}>{percentage}% Agree</Tooltip>}
                      >
                        <div className="percentage-container">
                          <div className="percentage-bar" style={{ width: `${percentage}%` }}></div>
                        </div>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              </td>
            </tr>
            {isExpanded && (
              <>
                {item.control_points && item.control_points.map((cp) => (
                  <ControlPoint
                    key={cp.id}
                    cp={cp}
                    level={level + 1}
                    onStatusClick={onStatusClick}
                    onContentClick={onContentClick}
                    isControlPoint={true}
                  />
                ))}
                {item.children && (
                  <TreeView 
                    data={item.children} 
                    level={level + 1} 
                    onStatusClick={onStatusClick} 
                    onContentClick={onContentClick} 
                  />
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default TreeView;