import React, { useRef, useEffect, useContext, useState } from 'react';
import { DataRoomContext } from '../../DisplayDataRoom';
import { useUser } from '../../../general/UserProvider';
import { useControlCopilot } from '../../../hooks/workflow/useControlCopilot';
import UserMessage from './UserMessage';
import AssistantMessage from './AssistantMessage';
import NewMessageInput from './NewMessageInput';
import ThreadSidebar from './ThreadSidebar';
import { Button } from 'react-bootstrap';
import './ControlCopilot.css';

const ControlCopilot = ({ control_id }) => {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);
    const [showSidebar, setShowSidebar] = useState(false);
    const [activeThread, setActiveThread] = useState(null);
    const { 
        isStreaming, 
        sendMessage, 
        currentThreadId, 
        chatHistory,
        deleteThread,
        threads,
        isLoadingThreads,
        isLoadingMessages,
        loadMessages,
        cleanup
    } = useControlCopilot(token, dataroom_id, control_id);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatHistory]);

    useEffect(() => {
        if (currentThreadId && !activeThread) {
            setActiveThread(currentThreadId);
        }
    }, [currentThreadId, activeThread]);
  
    const handleThreadSelect = async (threadId) => {
        try {
            console.log('threadId:', threadId);
            if (threadId === activeThread) {
                return;
            }
            
            cleanup(); 
            setActiveThread(threadId);
            await loadMessages(threadId);
            setShowSidebar(false);
        } catch (error) {
            if (error.name !== 'CanceledError') { 
                console.error('Failed to load thread messages:', error);
                setActiveThread(null);
            }
        }
    };
  
    const handleSendMessage = (content) => {
        sendMessage(content, activeThread);
    };

    const handleDeleteThread = async (threadId) => {
        try {
            cleanup(); // Nettoyer les requêtes en cours avant la suppression
            await deleteThread(threadId);
            if (threadId === activeThread) {
                setActiveThread(null);
            }
        } catch (error) {
            console.error('Failed to delete thread:', error);
        }
    };

    // Nettoyer les requêtes en cours lors du démontage du composant
    useEffect(() => {
        return () => {
            cleanup();
        };
    }, [cleanup]);
  
    return (
        <div className="copilot-container">
            <Button
                variant="light"
                className="d-md-none position-fixed top-0 start-0 m-2 z-3"
                onClick={() => setShowSidebar(!showSidebar)}
            >
                <i className="bi bi-list"></i>
            </Button>

            <div className={`thread-sidebar ${showSidebar ? 'show' : ''}`}>
                <ThreadSidebar 
                    threads={threads}
                    activeThread={activeThread}
                    onThreadSelect={handleThreadSelect}
                    onDeleteThread={handleDeleteThread}
                    isLoading={isLoadingThreads}
                />
            </div>

            <div className="copilot-chat-container">
                <div className="chat-messages-area">
                    {isLoadingMessages ? (
                        <div className="text-center p-4">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {chatHistory.map((message, index) => (
                                message.type === 'user' ? 
                                    <UserMessage key={index} content={message.content} /> :
                                    <AssistantMessage key={index} content={message.content} />
                            ))}
                            <div ref={messagesEndRef} />
                        </>
                    )}
                </div>
                <NewMessageInput 
                    onSubmit={handleSendMessage} 
                    isStreaming={isStreaming}
                    disabled={isLoadingMessages} 
                />
            </div>
        </div>
    );
};

export default ControlCopilot;