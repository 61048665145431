import { useState, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';
import { useUser } from '../../general/UserProvider';

export const useCreateFolder = () => {
    const { token } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const createFolder = useCallback(async (folderData) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await api.post(API_ROUTES.CREATE_FOLDER, folderData, {
                headers: { Authorization: `Bearer ${token}` }
            });

            console.log("Create folder response", response.data)
            if (response.status === 201) {
                return response.data;
            } else {
                const errorData = response.data || { message: 'Failed to create folder' };
                throw new Error(errorData.message);
            }
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [token]);

    return { createFolder, isLoading, error };
};

