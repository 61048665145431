import { useState, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useCreateFile = (token, dataroomId) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const createFile = useCallback(async (file, options, progressCallback) => {
        setIsLoading(true);
        setError(null);

        const isValidFileType = (file) => {
            const allowedExtensions = /(\.pdf|\.txt|\.doc|\.docx|\.pptx|\.ppt|\.xls|\.xlsx)$/i;
            return allowedExtensions.test(file.name.toLowerCase());
        };

        if (!isValidFileType(file)) {
            console.error('Invalid file type:', file.name);
            setError(new Error(`Invalid file type: ${file.name}`));
            setIsLoading(false);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', file);
            console.log(options);

            if (!options.folderId) {
                throw new Error('folder_id is required');
            }
            formData.append('folder_id', options.folderId);

            if (!options.requestedDocumentId) {
                throw new Error('requested_document_id is required');
            }
            formData.append('requested_document_id', options.requestedDocumentId);


            progressCallback(0, 'Uploading');
            const uploadResponse = await api.post(API_ROUTES.UPLOAD_FILES(dataroomId), formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 10) / progressEvent.total);
                    progressCallback(percentCompleted, 'Uploading');
                }
            });

            const { file_id, file_key } = uploadResponse.data;
            progressCallback(10, 'Uploading');

            const eventSourceUrl = `${process.env.REACT_APP_API_URL}/dataroom/${dataroomId}/process/${file_id}?token=${encodeURIComponent(token)}`;

            return new Promise((resolve, reject) => {
                const eventSource = new EventSource(eventSourceUrl);
                
                eventSource.onmessage = (event) => {
                    try {
                        const data = JSON.parse(event.data);
            
                        if (data.error) {
                            eventSource.close();
                            setError(new Error(data.error));
                            reject(new Error(data.error));
                        } else {
                            progressCallback(data.progress, data.stage);

                            if (data.stage === 'Completed') {
                                eventSource.close();
                                setIsLoading(false);
                                resolve({ 
                                    file_id, 
                                    file_key,
                                    requestedDocumentId: options.requestedDocumentId 
                                });
                            }
                        }
                    } catch (error) {
                        eventSource.close();
                        setError(new Error('Error parsing SSE data'));
                        reject(new Error('Error parsing SSE data'));
                    }
                };
            
                eventSource.onerror = (err) => {
                    eventSource.close();
                    setError(new Error('Error during file processing'));
                    reject(new Error('Error during file processing'));
                };
            });

        } catch (error) {
            console.error('Error in createFile:', error);
            setError(error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [token, dataroomId]);

    return { createFile, isLoading, error };
};