import React, { useState, useEffect, useCallback } from 'react';
import { Card, Spinner, Alert, Form, Tabs, Tab } from 'react-bootstrap';
import DataroomEditModal from './General/DataroomEditModal';
import DataroomRoleEditModal from './Roles/DataroomEditRole/DataroomRoleEditModal';
import DataroomGroupEditModal from './Groups/DataroomEditGroup/DataroomGroupEditModal';
import DataroomControlEditModal from './Controls/DataroomEditControls/DataroomControlEditModal';
import DataroomCycleEditModal from './Cycles/DataroomEditCycles/DataroomCycleEditModal';

import { useFetchDatarooms } from '../../hooks/admin/useFetchDatarooms';
import { useUpdateDataroom } from '../../hooks/admin/useUpdateDataroom';
import { useAddDataroom } from '../../hooks/admin/useAddDataroom';
import { useFetchDataroomRoles } from '../../hooks/admin/useFetchDataroomRoles';
import { useDataroomGroups } from '../../hooks/admin/useDataroomGroups';
import { useFetchDataroomControls, useAddDataroomControl, useUpdateDataroomControl, useDeleteDataroomControl } from '../../hooks/admin/dataroomControlsHooks';
import { useFetchDataroomCycles, useUpdateDataroomCycle, useDeleteDataroomCycle } from '../../hooks/admin/dataroomCyclesHooks';

import DataroomGeneralInfo from './General/DataroomGeneralInfo';
import DataroomRolesInfo from './Roles/DataroomRolesInfo';
import DataroomGroupsInfo from './Groups/DataroomGroupsInfo';
import DataroomControlsInfo from './Controls/DataroomControlsInfo';
import DataroomCyclesInfo from './Cycles/DataroomCyclesInfo';
const DataroomManagement = ({ token }) => {
  const [selectedDataroomId, setSelectedDataroomId] = useState('');
  const [selectedDataroom, setSelectedDataroom] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showControlModal, setShowControlModal] = useState(false);
  const [showCycleModal, setShowCycleModal] = useState(false);

  const { datarooms, loading, error, setDatarooms } = useFetchDatarooms(token);
  const { updateDataroom, updating, updateError } = useUpdateDataroom(token);
  const { addDataroom, adding, addError } = useAddDataroom(token);
  const { users, loading: usersLoading, error: usersError, fetchDataroomRoles } = useFetchDataroomRoles(token);
  const { 
    groups, 
    loading: groupsLoading, 
    error: groupsError, 
    fetchGroups 
  } = useDataroomGroups(token, selectedDataroom?.dataroom_id);

  const {
    controls,
    loading: controlsLoading,
    error: controlsError,
    refetch: fetchControls
  } = useFetchDataroomControls(token, selectedDataroom?.dataroom_id);

  const {
    cycles,
    loading: cyclesLoading,
    error: cyclesError,
    refetch: fetchCycles
  } = useFetchDataroomCycles(token, selectedDataroom?.dataroom_id);

  const { addControl } = useAddDataroomControl(token);
  const { updateControl } = useUpdateDataroomControl(token);
  const { deleteControl } = useDeleteDataroomControl(token);

  const { updateCycle } = useUpdateDataroomCycle(token);
  const { deleteCycle } = useDeleteDataroomCycle(token);

  useEffect(() => {
    if (selectedDataroomId && datarooms.length > 0) {
      const selectedId = Number(selectedDataroomId);
      const selected = datarooms.find(d => d.dataroom_id === selectedId);
      setSelectedDataroom(selected);
    }
  }, [selectedDataroomId, datarooms]);

  useEffect(() => {
    if (selectedDataroom) {
      fetchDataroomRoles(selectedDataroom.dataroom_id);
      fetchGroups(selectedDataroom.dataroom_id);
      fetchControls();
      fetchCycles();
    }
    // eslint-disable-next-line
  }, [selectedDataroom]);

  const handleDataroomSelect = useCallback((event) => {
    const newSelectedId = event.target.value;
    setSelectedDataroomId(newSelectedId);
  }, []);

  const handleEditDataroom = useCallback(() => setShowEditModal(true), []);
  const handleEditGroup = useCallback(() => setShowGroupModal(true), []);
  const handleEditRole = useCallback(() => setShowRoleModal(true), []);
  const handleEditControl = useCallback(() => setShowControlModal(true), []);

  const handleEditCycle = useCallback(() => setShowCycleModal(true), []);

  
  const handleCloseModals = useCallback(() => {
    setShowEditModal(false);
    setShowGroupModal(false);
    setShowRoleModal(false);
    setShowControlModal(false);
    setShowCycleModal(false);
    if (selectedDataroom) {
      fetchDataroomRoles(selectedDataroom.dataroom_id);
      fetchGroups(selectedDataroom.dataroom_id);
      fetchControls();
      fetchCycles();
    }
  }, [selectedDataroom, fetchDataroomRoles, fetchGroups, fetchControls, fetchCycles]);

  const handleSaveDataroom = useCallback(async (dataroomData) => {
    try {
      let updatedDataroom;
      if (selectedDataroomId) {
        updatedDataroom = await updateDataroom({ ...dataroomData, dataroom_id: selectedDataroomId });
      } else {
        updatedDataroom = await addDataroom(dataroomData);
      }

      setDatarooms(prevDatarooms => {
        if (selectedDataroomId) {
          return prevDatarooms.map(dataroom => 
            dataroom.dataroom_id === updatedDataroom.dataroom_id ? updatedDataroom : dataroom
          );
        } else {
          return [...prevDatarooms, updatedDataroom];
        }
      });

      handleCloseModals();
    } catch (error) {
      console.error("Failed to save dataroom:", error);
    }
  }, [selectedDataroomId, updateDataroom, addDataroom, setDatarooms, handleCloseModals]);

  const handleAddControl = useCallback(async (controlData) => {
    try {
      await addControl(selectedDataroom.dataroom_id, controlData);
      fetchControls();
    } catch (error) {
      console.error("Failed to add control:", error);
    }
  }, [selectedDataroom, addControl, fetchControls]);

  const handleUpdateControl = useCallback(async (controlId, controlData) => {
    try {
      await updateControl(selectedDataroom.dataroom_id, controlId, controlData);
      fetchControls();
    } catch (error) {
      console.error("Failed to update control:", error);
    }
  }, [selectedDataroom, updateControl, fetchControls]);

  const handleDeleteControl = useCallback(async (controlId) => {
    try {
      await deleteControl(selectedDataroom.dataroom_id, controlId);
      fetchControls();
    } catch (error) {
      console.error("Failed to delete control:", error);
    }
  }, [selectedDataroom, deleteControl, fetchControls]);

  const handleUpdateCycle = useCallback(async (cycleId, cycleData) => {
    try {
      await updateCycle(selectedDataroom.dataroom_id, cycleId, cycleData);
      fetchCycles();
    } catch (error) {
      console.error("Failed to update cycle:", error);
    }
  }, [selectedDataroom, updateCycle, fetchCycles]);

  const handleDeleteCycle = useCallback(async (cycleId) => {
    try {
      await deleteCycle(selectedDataroom.dataroom_id, cycleId);
      fetchCycles();
    } catch (error) {
      console.error("Failed to delete cycle:", error);
    }
  }, [selectedDataroom, deleteCycle, fetchCycles]);

  const renderDataroomSelector = () => (
    <Form.Group className="mb-3">
      <Form.Label>Select Dataroom</Form.Label>
      <Form.Select onChange={handleDataroomSelect} value={selectedDataroomId}>
        <option value="">Choose a dataroom</option>
        {datarooms.map((dataroom) => (
          <option key={dataroom.dataroom_id} value={dataroom.dataroom_id}>
            {dataroom.name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  const renderDataroomContent = () => (
    selectedDataroom && (
      <Tabs defaultActiveKey="global" className="mb-3">
        <Tab eventKey="global" title="Global">
          <DataroomGeneralInfo 
            dataroom={selectedDataroom} 
            onEdit={handleEditDataroom}
          />
        </Tab>
        <Tab eventKey="roles" title="Roles">
          <DataroomRolesInfo 
            dataroom={selectedDataroom}
            users={users}
            loading={usersLoading}
            error={usersError}
            onEditRoles={handleEditRole}
          />
        </Tab>
        <Tab eventKey="groups" title="Groups">
          <DataroomGroupsInfo 
            dataroom={selectedDataroom}
            groups={groups}
            loading={groupsLoading}
            error={groupsError}
            onEditGroups={handleEditGroup}
          />
        </Tab>
        <Tab eventKey="controls" title="Controls">
        <DataroomControlsInfo 
          dataroom={selectedDataroom}
          controls={controls}
          loading={controlsLoading}
          error={controlsError}
          onEditControls={handleEditControl}
          token={token}
          refetchControls={fetchControls}
        />
        </Tab>
        <Tab eventKey="cycles" title="Cycles">
          <DataroomCyclesInfo 
            dataroom={selectedDataroom}
            cycles={cycles}
            loading={cyclesLoading}
            error={cyclesError}
            onEditCycles={handleEditCycle}
          />
        </Tab>
      </Tabs>
    )
  );

  return (
    <Card className="admin_card shadow-sm">
      <Card.Body>
        <h3>Dataroom Management</h3>
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <>
            {renderDataroomSelector()}
            {selectedDataroom && renderDataroomContent()}
          </>
        )}
      </Card.Body>

      <DataroomEditModal
        show={showEditModal}
        handleClose={handleCloseModals}
        dataroom={selectedDataroom}
        onSave={handleSaveDataroom}
        isProcessing={updating || adding}
        error={updateError || addError}
      />
      <DataroomGroupEditModal
        show={showGroupModal}
        handleClose={handleCloseModals}
        dataroom={selectedDataroom}
        token={token}
      />
      <DataroomRoleEditModal
        show={showRoleModal}
        handleClose={handleCloseModals}
        dataroom={selectedDataroom}
        token={token}
      />
      <DataroomControlEditModal
        show={showControlModal}
        handleClose={handleCloseModals}
        dataroom={selectedDataroom}
        token={token}
        onAddControl={handleAddControl}
        onUpdateControl={handleUpdateControl}
        onDeleteControl={handleDeleteControl}
      />
      <DataroomCycleEditModal
        show={showCycleModal}
        handleClose={handleCloseModals}
        dataroom={selectedDataroom}
        token={token}
        onUpdateCycle={handleUpdateCycle}
        onDeleteCycle={handleDeleteCycle}
      />
    </Card>
  );
};

export default DataroomManagement;