import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export function useHandleIRStatusChange({ dataroom_id, token, requests, setRequests }) {
    const handleFieldChange = async (item, field, value) => {
        try {
            const response = await api.put(
                API_ROUTES.UPDATE_IRL_FIELD(item.dataroom_id, item.irl_id),
                {
                    field: field,
                    value: value
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            console.log('Réponse API reçue:', response);
            
            if (response.status === 200) {
                const updatedData = response.data;
                const updatedRequests = requests.map(r =>
                    r.irl_id === item.irl_id ? updatedData : r
                );
                setRequests(updatedRequests);
                return { data: updatedData };
            } else {
                console.error(`Error updating ${field}`, response.data);
                throw new Error(`Failed to update ${field}`);
            }
        } catch (error) {
            console.error(`Failed to update ${field}:`, error);
            throw error;
        }
    };

    return {
        handleFieldChange
    };
}