import { useState } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useUpdateUser = (token) => {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  const updateUser = async (userData) => {
    if (!token) return;

    setUpdating(true);
    setError(null);

    try {
        const response = await api.put(
            API_ROUTES.ADMIN_UPDATE_USER(userData.user_id),
            userData,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );
        setUpdating(false);
        return response.data;
    } catch (error) {
        setError(error.message || "Error updating user");
        setUpdating(false);
        throw error;
    }
  };

  return { updateUser, updating, error };
};