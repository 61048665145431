import React, { useState, useMemo } from 'react';
import { Card, ProgressBar, Row, Col, Badge, Modal, Button } from 'react-bootstrap';

import './ProcedureList.css';

const ProgressCard = ({ cycleName, supervisor, auditor, risks_justifications = [], controls = [] }) => {
    const [showDetails, setShowDetails] = useState(false);
    const [selectedRisk, setSelectedRisk] = useState(null);

    const globalProgress = useMemo(() => {
        if (!controls || controls.length === 0) {
            return { percentage: 0, completedCount: 0, totalCount: 0 };
        }
        const completedControls = controls.filter(control => control.status === 'Completed').length;
        return {
            percentage: (completedControls / controls.length) * 100,
            completedCount: completedControls,
            totalCount: controls.length
        };
    }, [controls]);

    const cleanRisksJustifications = useMemo(() => {
        if (!risks_justifications) return [];
        return risks_justifications.map(rj => ({
            risk: rj.risk?.replace(/^[-–—]\s*/, '').trim() || '',
            justification: rj.justification?.trim() || 'No justification provided'
        }));
    }, [risks_justifications]);

    const RiskItem = ({ risk, justification }) => (
        <div 
            className="risk-item mb-2 p-3 border rounded cursor-pointer"
            onClick={() => setSelectedRisk({ risk, justification })}
            style={{
                backgroundColor: '#fff',
                transition: 'all 0.2s ease',
                borderColor: '#dee2e6'
            }}
            onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#f8f9fa';
                e.currentTarget.style.borderColor = '#ced4da';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#fff';
                e.currentTarget.style.borderColor = '#dee2e6';
            }}
        >
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    <p className="mb-0 text-body" style={{ fontSize: '0.95rem' }}>{risk}</p>
                </div>
                <i className="bi bi-info-circle text-muted"></i>
            </div>
        </div>
    );

    return (
        <>
            <Card className="shadow-sm mb-3">
                <Card.Body className="pb-4">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <Card.Title className="mb-0">Overall Progress</Card.Title>
                        <button
                            onClick={() => setShowDetails(!showDetails)}
                            className="details-toggle-btn"
                        >
                            {showDetails ? 'Hide details' : 'Show details'}
                            <i className={`bi bi-chevron-${showDetails ? 'up' : 'down'} ms-1`}></i>
                        </button>
                    </div>

                    <div className="mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <span className="text-muted">Completion Status</span>
                            <span className="small fw-medium">
                                {globalProgress.completedCount} of {globalProgress.totalCount} controls
                            </span>
                        </div>
                        <ProgressBar 
                            now={globalProgress.percentage} 
                            variant="success"
                            style={{ height: '0.5rem' }}
                        />
                    </div>

                    {showDetails && (
                        <Row className="g-4">
                            <Col md={6}>
                                <div className="d-flex align-items-center mb-3">
                                    <i className="bi bi-people text-primary me-2"></i>
                                    <h6 className="mb-0 fw-bold">Audit Team</h6>
                                </div>
                                <div className="ps-4">
                                    <div className="mb-3">
                                        <div className="text-muted small mb-1">Supervisor</div>
                                        <div className="fw-medium">{supervisor || 'Not assigned'}</div>
                                    </div>
                                    <div>
                                        <div className="text-muted small mb-1">Auditor</div>
                                        <div className="fw-medium">{auditor || 'Not assigned'}</div>
                                    </div>
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className="d-flex align-items-center mb-3">
                                    <i className="bi bi-exclamation-triangle text-warning me-2"></i>
                                    <h6 className="mb-0 fw-bold">
                                        Identified Risks{' '}
                                        <Badge 
                                            bg={cleanRisksJustifications.length > 0 ? 'warning' : 'success'}
                                            className="ms-2"
                                        >
                                            {cleanRisksJustifications.length}
                                        </Badge>
                                    </h6>
                                </div>
                                <div>
                                    {cleanRisksJustifications.length > 0 ? (
                                        cleanRisksJustifications.map((rj, index) => (
                                            <RiskItem 
                                                key={index}
                                                risk={rj.risk}
                                                justification={rj.justification}
                                            />
                                        ))
                                    ) : (
                                        <div className="d-flex align-items-center p-3 bg-success bg-opacity-10 rounded">
                                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                                            <span className="fw-medium text-success">No risks identified</span>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    )}
                </Card.Body>
            </Card>

            <Modal 
                show={selectedRisk !== null} 
                onHide={() => setSelectedRisk(null)}
                size="lg"
                centered
                animation={true}
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                contentClassName="border-0"
            >
                <Modal.Header closeButton className="border-bottom">
                    <Modal.Title className="d-flex align-items-center">
                        <i className="bi bi-exclamation-triangle text-warning me-2"></i>
                        <span>Risk Assessment</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    {selectedRisk && (
                        <div>
                            <div className="mb-4">
                                <h6 className="text-muted mb-2">Identified Risk</h6>
                                <p className="mb-0">{selectedRisk.risk}</p>
                            </div>
                            <div>
                                <h6 className="text-muted mb-2">Justification</h6>
                                <p className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>
                                    {selectedRisk.justification}
                                </p>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSelectedRisk(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProgressCard;