import React from 'react';
import { Card, Table, Button, Spinner, Alert, Badge } from 'react-bootstrap';
import { useInitializeDataroomControls } from '../../../hooks/admin/dataroomControlsHooks';

const getPriorityVariant = (priority) => {
  switch (priority) {
    case 'VERY_LOW': return 'info';
    case 'LOW': return 'success';
    case 'MEDIUM': return 'warning';
    case 'HIGH': return 'danger';
    case 'CRITICAL': return 'dark';
    default: return 'secondary';
  }
};

const getStatusVariant = (status) => {
  switch (status) {
    case 'MISSING_DOCUMENTS': return 'danger';
    case 'READY': return 'info';
    case 'IN_PROGRESS': return 'warning';
    case 'REVIEW': return 'primary';
    case 'COMPLETED': return 'success';
    default: return 'secondary';
  }
};

const DataroomControlsInfo = ({ 
  dataroom, 
  controls, 
  loading, 
  error, 
  onEditControls,
  token,
  refetchControls
}) => {
  const { initializeControls, initializing, initializeError } = useInitializeDataroomControls(token);

  const handleCreateControls = async () => {
    try {
      await initializeControls(dataroom.dataroom_id);
      refetchControls();
    } catch (error) {
      console.error("Failed to create/reinit controls:", error);
    }
  };

  const getInitButtonText = () => {
    if (initializing) {
      return controls.length === 0 ? 'Creating Controls...' : 'Reinitializing Controls...';
    }
    return controls.length === 0 ? 'Create Controls' : 'Re-init Controls';
  };

  const getInitButtonVariant = () => {
    return controls.length === 0 ? 'success' : 'warning';
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="mb-0">
            <i className="bi bi-shield-check me-2"></i>
            Controls for {dataroom?.name}
          </h4>
          <div>
            <Button 
              variant={getInitButtonVariant()}
              onClick={handleCreateControls} 
              className="me-2" 
              disabled={initializing}
            >
              <i className="bi bi-plus-circle me-2"></i>
              {getInitButtonText()}
            </Button>
            <Button variant="primary" onClick={onEditControls}>
              <i className="bi bi-pencil me-2"></i>
              Edit Controls
            </Button>
          </div>
        </div>

        {loading || initializing ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error || initializeError ? (
          <Alert variant="danger">
            <i className="bi bi-exclamation-triangle me-2"></i>
            {error || initializeError}
          </Alert>
        ) : (
          <>
            {controls.length === 0 ? (
              <Alert variant="info">
                <i className="bi bi-info-circle me-2"></i>
                No controls have been set up for this dataroom yet. Click the "Create Controls" button to initialize them.
              </Alert>
            ) : (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th><i className="bi bi-calendar-check me-2"></i>Cycle</th>
                    <th><i className="bi bi-shield me-2"></i>Control</th>
                    <th><i className="bi bi-exclamation-triangle me-2"></i>Priority</th>
                    <th><i className="bi bi-info-circle me-2"></i>Status</th>
                    <th><i className="bi bi-person me-2"></i>Auditor</th>
                    <th><i className="bi bi-person-check me-2"></i>Supervisor</th>
                  </tr>
                </thead>
                <tbody>
                  {controls.map(control => (
                    <tr key={control.id}>
                      <td>{control.control_template?.cycle?.name || 'N/A'}</td>
                      <td>{control.control_template?.name || 'N/A'}</td>
                      <td>
                        <Badge bg={getPriorityVariant(control.priority)}>
                          {control.priority}
                        </Badge>
                      </td>
                      <td>
                        <Badge bg={getStatusVariant(control.status)}>
                          {control.status}
                        </Badge>
                      </td>
                      <td>{control.auditor_id || 'Not assigned'}</td>
                      <td>{control.supervisor_id || 'Not assigned'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default DataroomControlsInfo;