import React, { useState } from 'react';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import ProgressCard from './ProgressCard';
import ProcedureCard from './ProcedureCard';
import './ProcedureList.css';

function ProcedureList({ procedures, onProcedureClick, cycleName, supervisor, auditor, risks }) {
    const [statusFilter, setStatusFilter] = useState('all');
    const [priorityFilter, setPriorityFilter] = useState('all');
    const [sortBy, setSortBy] = useState('default');
    const [showFilters, setShowFilters] = useState(false);

    const statusOptions = [
        { value: 'all', icon: 'bi-grid-3x3-gap-fill', label: 'All' },
        { value: 'Missing Documents', icon: 'bi-file-earmark-x', label: 'Missing documents' },
        { value: 'Ready', icon: 'bi-hourglass', label: 'Ready' },
        { value: 'In Progress', icon: 'bi-play-circle', label: 'In progress' },
        { value: 'Review', icon: 'bi-eye', label: 'For review' },
        { value: 'Completed', icon: 'bi-check-circle', label: 'Completed' }
    ];

    const priorityOptions = [
        { value: 'all', icon: 'bi-funnel', label: 'All' },
        { value: 'Very Low', icon: 'bi-flag', label: 'Very Low' },
        { value: 'Low', icon: 'bi-flag-fill', label: 'Low' },
        { value: 'Medium', icon: 'bi-flag-fill', label: 'Medium' },
        { value: 'High', icon: 'bi-flag-fill', label: 'High' },
        { value: 'Critical', icon: 'bi-flag-fill', label: 'Critical' }
    ];

    const sortOptions = [
        { value: 'default', icon: 'bi-sort-down', label: 'Default' },
        { value: 'priority', icon: 'bi-sort-numeric-down', label: 'Priority' },
        { value: 'status', icon: 'bi-sort-alpha-down', label: 'Status' }
    ];

    const getPriorityWeight = (priority) => {
        const weights = {
            'Critical': 5,
            'High': 4,
            'Medium': 3,
            'Low': 2,
            'Very Low': 1
        };
        return weights[priority] || 0;
    };

    const getStatusWeight = (status) => {
        const weights = {
            'Missing Documents': 5,
            'Ready': 4,
            'In Progress': 3,
            'Review': 2,
            'Completed': 1
        };
        return weights[status] || 0;
    };

    const sortProcedures = (procedures) => {
        const sortedProcedures = [...procedures];
        
        switch (sortBy) {
            case 'priority':
                return sortedProcedures.sort((a, b) => 
                    getPriorityWeight(b.priority) - getPriorityWeight(a.priority)
                );
            case 'status':
                return sortedProcedures.sort((a, b) => 
                    getStatusWeight(b.status) - getStatusWeight(a.status)
                );
            default:
                return sortedProcedures;
        }
    };

    const filteredProcedures = procedures
        .filter(procedure => {
            const matchesStatus = statusFilter === 'all' || procedure.status === statusFilter;
            const matchesPriority = priorityFilter === 'all' || procedure.priority === priorityFilter;
            return matchesStatus && matchesPriority;
        });

    const sortedAndFilteredProcedures = sortProcedures(filteredProcedures);

    const getPriorityColor = (priority) => {
        switch (priority) {
            case 'Very Low': return 'text-muted';
            case 'Low': return 'text-success';
            case 'Medium': return 'text-warning';
            case 'High': return 'text-orange';
            case 'Critical': return 'text-danger';
            default: return '';
        }
    };

    return (
        <div className="procedure-list-container">
            <div className="header-container">
                <h2 className="procedure-list-title">
                    <i className="bi bi-list-check me-2"></i>Controls for {cycleName}
                </h2>
                <button
                    onClick={() => setShowFilters(!showFilters)}
                    className="details-toggle-btn"
                >
                    {showFilters ? 'Hide filters' : 'Show filters'}
                    <i className={`bi ${showFilters ? 'bi-chevron-up' : 'bi-chevron-down'} ms-1`}></i>
                </button>
            </div>
            
            {showFilters && (
                <Card className="filters-card mb-4">
                    <Card.Body>
                        <div id="filters-content">
                            <div className="filter-group mb-3">
                                <h6 className="filter-label mb-2">Status</h6>
                                <ButtonGroup className="flex-wrap">
                                    {statusOptions.map(({ value, icon, label }) => (
                                        <Button
                                            key={value}
                                            variant={statusFilter === value ? "secondary" : "outline-secondary"}
                                            onClick={() => setStatusFilter(value)}
                                            className="filter-button"
                                        >
                                            <i className={`bi ${icon} me-2`}></i>
                                            {label}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </div>

                            <div className="filter-group mb-3">
                                <h6 className="filter-label mb-2">Priority</h6>
                                <ButtonGroup className="flex-wrap">
                                    {priorityOptions.map(({ value, icon, label }) => (
                                        <Button
                                            key={value}
                                            variant={priorityFilter === value ? "secondary" : "outline-secondary"}
                                            onClick={() => setPriorityFilter(value)}
                                            className="filter-button"
                                        >
                                            <i className={`bi ${icon} me-2 ${value !== 'all' ? getPriorityColor(value) : ''}`}></i>
                                            {label}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </div>

                            <div className="filter-group">
                                <h6 className="filter-label mb-2">Order by</h6>
                                <ButtonGroup className="flex-wrap">
                                    {sortOptions.map(({ value, icon, label }) => (
                                        <Button
                                            key={value}
                                            variant={sortBy === value ? "secondary" : "outline-secondary"}
                                            onClick={() => setSortBy(value)}
                                            className="filter-button"
                                        >
                                            <i className={`bi ${icon} me-2`}></i>
                                            {label}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}

            <ProgressCard 
                cycleName={cycleName}
                supervisor={supervisor}
                auditor={auditor}
                risks_justifications={risks}
                controls={procedures}
            />

            <div className="procedures-grid">
                {sortedAndFilteredProcedures.map(procedure => (
                    <ProcedureCard 
                        key={procedure.id}
                        procedure={procedure}
                        onClick={() => onProcedureClick(procedure)}
                    />
                ))}
            </div>
        </div>
    );
}

export default ProcedureList;
