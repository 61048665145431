import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DataRoomContext } from '../DisplayDataRoom';
import { Card, ListGroup, Button } from 'react-bootstrap';
import { formatDate } from '../../utils/Date';
import { TEAM_URL } from '../../utils/urls';
import './TeamCard.css';

function TeamCard({ member, canEdit, onEdit }) {
    const dataroom_id = useContext(DataRoomContext);
    const message_count = member.messages ? member.messages.length : 0;

    return (
        <Card className="team-card">
            <div className="team-card-header">
                <div className="team-card-image-wrapper">
                    <Card.Img variant="top" src={member.picture_url} className="team-card-image" />
                </div>
                <div className="team-card-info">
                    <Card.Title className="team-card-name">
                        <Link to={TEAM_URL.replace(':dataroomId', dataroom_id).replace(':teamId', member.user_id)}>
                            {member.username}
                        </Link>
                    </Card.Title>
                    <Card.Text className="team-card-role">
                        {member.role.role_name}
                    </Card.Text>
                </div>
            </div>
            <Card.Body>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <i className="bi bi-globe"></i> 
                        <a href={member.company_url} target="_blank" rel="noreferrer">{member.company_name}</a>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <i className="bi bi-collection"></i> 
                        {member.groups ? 
                            member.groups.map(group => group.folder_name ? `${group.folder_name} (${group.group_name})` : group.group_name).join(', ') : 
                            "No groups"}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <i className="bi bi-file-earmark"></i> {`${member.file_count} document${member.file_count !== 1 ? 's' : ''} added`}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <i className="bi bi-chat-dots"></i> {`${member.comment_count} comment${member.comment_count !== 1 ? 's' : ''} added`}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <i className="bi bi-robot"></i> {`${message_count} copilot message${message_count !== 1 ? 's' : ''} sent`}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <i className="bi bi-clock-history"></i> Connected {formatDate(member.last_connection)}
                    </ListGroup.Item>
                </ListGroup>
            </Card.Body>
            <Card.Footer>
                <Button variant="link" href={`mailto:${member.email}`}>
                    <i className="bi bi-envelope"></i> Contact
                </Button>
                {canEdit && (
                    <Button variant="primary" onClick={() => onEdit(member)}>
                        <i className="bi bi-pencil"></i> Edit
                    </Button>
                )}
            </Card.Footer>
        </Card>
    );
};

export default TeamCard;