import { useState } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddUser = (token) => {
  const [adding, setAdding] = useState(false);
  const [error, setError] = useState(null);

  const addUser = async (userData) => {
    if (!token) return;

    setAdding(true);
    setError(null);

    try {
      const response = await api.post(
        API_ROUTES.ADMIN_ADD_USER,
        userData,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setAdding(false);
      return response.data;
    } catch (error) {
      setError(error.message || "Error adding user");
      setAdding(false);
      throw error;
    }
  };

  return { addUser, adding, error };
};