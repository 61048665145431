import React, { useState } from 'react';
import DocumentManager from './documents/DocumentManager';
import ProcedurePriority from './procedureSidebar/ProcedurePriority';
import ProcedureInformation from './procedureSidebar/ProcedureInformation';
import ProcedureStatus from './procedureSidebar/ProcedureStatus';
import './ProcedureDetailsRight.css';

function ProcedureDetailsRight({ 
    procedure,
    setFindings,
    setConclusion,
    setAssessment,
    refetch,
    onFileOpen,
    supervisor,
    auditor,
    onUpdatePriorityDescription,
    onUpdatePriority,
    onUpdateStatus,
    token,
    dataroomId
}) {
    const [pendingDocuments, setPendingDocuments] = useState(0);

    return (
        <div className="procedure-details-right">
            <ProcedureStatus 
                status={procedure.status}
                onUpdateStatus={onUpdateStatus}
                pendingDocuments={pendingDocuments}
                token={token}
                dataroomId={dataroomId}
                procedureId={procedure.dataroom_control_id}
                refetch={refetch}
                setFindings={setFindings}
                setConclusion={setConclusion}
                setAssessment={setAssessment}
            />

            <div className="document-section">
                <h3 className="section-title-right">Documents</h3>
                <DocumentManager 
                    procedure={procedure}
                    onFileOpen={onFileOpen}
                    dataroomId={dataroomId}
                    token={token}
                    onRequestedDocsChange={setPendingDocuments} 
                />
            </div>

            <ProcedurePriority 
                procedure={procedure}
                onUpdatePriority={onUpdatePriority}
                onUpdatePriorityDescription={onUpdatePriorityDescription}
            />

            <ProcedureInformation 
                procedure={procedure}
                supervisor={supervisor}
                auditor={auditor}
            />
        </div>
    );
}

export default ProcedureDetailsRight;