import React, { useState, useContext } from 'react';
import { Form, FormControl, Button, Alert } from 'react-bootstrap';
import { useUser } from '../../general/UserProvider';
import './CommentForm.css';
import { DataRoomContext } from '../DisplayDataRoom';
import { useAddComment } from '../../hooks/comments/useAddComment';

function CommentForm({ object_type, onCommentAdded, initialText = '', objectId }) {
    const dataroom_id = useContext(DataRoomContext);
    const { token } = useUser();
    const [commentText, setCommentText] = useState(initialText);
    const [isFocused, setFocus] = useState(false);
    const [error, setError] = useState(null);

    const addComment = useAddComment(token);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!commentText.trim()) {
            setError('Comment cannot be empty');
            return;
        }

        const commentData = {
            content: commentText,
            object_id: objectId,
            object_type: object_type,
            dataroom_id: dataroom_id
        };

        addComment(commentData, (data) => {
            setCommentText('');
            setError(null);
            if (onCommentAdded) {
                onCommentAdded(data);
            }
        }, (errorMsg) => {
            setError(errorMsg);
        });
    };

    const handleCancel = () => {
        setFocus(false);
        setCommentText("");
    };

    return (
        <>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form className="mb-3 mt-3" onSubmit={handleSubmit}>
                <Form.Group controlId="userComment">
                    <FormControl 
                        as="textarea" 
                        rows={isFocused ? 3 : 1} 
                        placeholder="Write your comment here..." 
                        className="form-control-comment" 
                        value={commentText}
                        onChange={e => setCommentText(e.target.value)}
                        onFocus={() => setFocus(true)}
                    />
                </Form.Group>
                {isFocused && (
                    <>
                        <Button 
                            variant="primary" 
                            type="submit" 
                            className="btn btn-primary mt-2"
                        >
                            Comment
                        </Button>
                        <Button 
                            variant="link" 
                            className="btn-link mt-2 ml-2 btn-link-comment"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </>
                )}
            </Form>
        </>
    );
}

export default CommentForm;
