import React, { useState } from 'react';
import { Card, Button, Col } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

function DataRoomCard({ room }) {
    const navigate = useNavigate();

    const handleSeeDataRoom = () => {
        navigate(`/dataroom/${room.dataroom_id}/documents`);
    };

    const [isButtonHovered, setIsButtonHovered] = useState(false);

    return (
        <Col md={3} className="pb-2">
            <Card className='dataRoomCard box-bottom-shadow'>
                <div className="square-img">
                    <Card.Img variant="top" src={room.logo_path} />
                </div>
                <Card.Body>
                    <Card.Title className='font-title'>{room.name || 'Card Title'}</Card.Title>
                    <Card.Text>
                        {room.description || 'Some quick example text to build on the card title and make up the bulk of the card content.'}
                    </Card.Text>
                </Card.Body>
                <Card.Footer 
                    className='dataRoomCardFooter'
                    onMouseEnter={() => setIsButtonHovered(true)}
                    onMouseLeave={() => setIsButtonHovered(false)}
                >
                    <Button variant="primary" className='black-button' onClick={handleSeeDataRoom}>
                        <i className={`bi icon-spacing ${isButtonHovered ? 'bi-door-open-fill' : 'bi-door-closed-fill'}`}></i> Enter the room
                    </Button>
                </Card.Footer>
            </Card>
        </Col>
    );
}

export default DataRoomCard;