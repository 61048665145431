// utils/String.js

/**
 * Capitalize the first letter of a string and make the rest lowercase.
 * @param {string} string - The string to capitalize.
 * @returns {string} The capitalized string.
 */
export function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
