import { useState } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFetchRoles = (token) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [roles, setRoles] = useState([]);

  const fetchRoles = async () => {
    if (!token) return;

    setLoading(true);
    setError(null);

    try {
      const response = await api.get(
        API_ROUTES.ADMIN_GET_ROLES,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setRoles(response.data);
      setLoading(false);
    } catch (error) {
      setError(error.message || "Error fetching roles");
      setLoading(false);
    }
  };

  return { fetchRoles, roles, loading, error };
};