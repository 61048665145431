import React from 'react';
import { Button, Form } from 'react-bootstrap';
import './ProcedureHeader.css';
export const ProcedureHeader = ({ 
    procedure,
    isEditing,
    editedName,
    onStartEditing,
    onSave,
    onCancel,
    onChange,
    onCopilotClick,
    updating 
}) => {
    return (
        <div className="procedure-header d-flex justify-content-between align-items-start">
            <div>
                <div className="d-flex align-items-center">
                    {isEditing ? (
                        <div className="edit-container w-100">
                            <Form.Control
                                type="text"
                                value={editedName}
                                onChange={onChange}
                                className="mb-2"
                                placeholder="Enter procedure name"
                            />
                            <div className="edit-actions">
                                <Button 
                                    variant="primary" 
                                    onClick={onSave} 
                                    className="me-2"
                                    disabled={updating}
                                    size="sm"
                                >
                                    {updating ? 'Saving...' : 'Save'}
                                </Button>
                                <Button 
                                    variant="secondary" 
                                    onClick={onCancel}
                                    disabled={updating}
                                    size="sm"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <h1 className="procedure-title mb-1">{procedure.name}</h1>
                            <Button 
                                variant="link" 
                                onClick={onStartEditing}
                                className="edit-button"
                                disabled={updating}
                            >
                                <i className="bi bi-pencil edit-icon"></i>
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <Button 
                variant="link" 
                onClick={onCopilotClick} 
                className="chat-button p-0"
                style={{ color: 'black' }}
            >
                <i className="bi bi-robot fs-4"></i>
            </Button>
        </div>
    );
};

export default ProcedureHeader;