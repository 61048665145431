import { useState, useMemo } from 'react';

export function useSortRequests(initialRequests) {
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc'); // 'asc' ou 'desc'

    const sortedRequests = useMemo(() => {
        let sortableItems = [...initialRequests];
        if (sortField != null) {
            sortableItems.sort((a, b) => {
                if (a[sortField] < b[sortField]) {
                    return sortDirection === 'asc' ? -1 : 1;
                }
                if (a[sortField] > b[sortField]) {
                    return sortDirection === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [initialRequests, sortField, sortDirection]);

    const handleSort = (field) => {
        const isAsc = sortField === field && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortField(field);
    };

    return { sortedRequests, handleSort, sortField, sortDirection };
}
