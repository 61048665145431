import { useState, useRef, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useControlCopilot = (token, dataroomId, controlId) => {
    const [state, setState] = useState({
        chatHistory: [],
        isStreaming: false,
        currentThreadId: null,
        threads: [],
        isLoadingThreads: true,
        isLoadingMessages: false
    });

    const sseRef = useRef(null);
    const abortControllerRef = useRef(null);

    const cleanup = useCallback(() => {
        if (sseRef.current) {
            sseRef.current.close();
            sseRef.current = null;
        }
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
        }
    }, []);

    useEffect(() => {
        return cleanup;
    }, [cleanup]);

    const handleError = useCallback((message) => {
        cleanup();
        setState(prev => ({
            ...prev,
            isStreaming: false,
            chatHistory: prev.chatHistory.length > 0 
                ? prev.chatHistory.map((msg, i) => 
                    i === prev.chatHistory.length - 1 
                        ? { ...msg, content: `Error: ${message}` }
                        : msg
                )
                : []
        }));
    }, [cleanup]);

    const loadMessages = useCallback(async (threadId) => {        
        cleanup();
        abortControllerRef.current = new AbortController();

        if (!threadId) {
            setState(prev => ({
                ...prev,
                chatHistory: [],
                currentThreadId: null,
                isLoadingMessages: false
            }));
            return;
        }
        
        try {
            setState(prev => ({ ...prev, isLoadingMessages: true }));
            
            const response = await api.get(
                API_ROUTES.THREAD_MESSAGES(threadId, controlId),
                {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: abortControllerRef.current.signal
                }
            );

            const messages = response.data.messages;
            const formattedMessages = messages.map(message => ({
                type: message.role,
                content: message.content[0]?.text || ''
            })).reverse();

            setState(prev => ({
                ...prev,
                chatHistory: formattedMessages,
                currentThreadId: threadId,
                isLoadingMessages: false
            }));

            return formattedMessages;
        } catch (error) {
            if (error.name === 'AbortError') {
                throw error;
            }
            
            setState(prev => ({ 
                ...prev, 
                isLoadingMessages: false,
                chatHistory: []
            }));
            throw error;
        }
    }, [controlId, token, cleanup]);

    const loadThreads = useCallback(async () => {
        cleanup();
        abortControllerRef.current = new AbortController();
    
        try {
            setState(prev => ({ ...prev, isLoadingThreads: true }));
    
            const response = await api.get(
                API_ROUTES.CONTROL_THREADS(controlId),
                {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: abortControllerRef.current.signal
                }
            );
    
            if (!Array.isArray(response?.data)) {
                throw new Error('Invalid response format from server');
            }
    
            const formattedThreads = response.data
                .map(thread => ({
                    id: thread.id,
                    title: thread.title,
                    lastUpdate: thread.updated_at ? new Date(thread.updated_at).getTime() : Date.now(),
                }))
                // Tri par date décroissante (plus récent en premier)
                .sort((a, b) => b.lastUpdate - a.lastUpdate);
            
            setState(prev => ({
                ...prev,
                threads: formattedThreads,
                isLoadingThreads: false
            }));
        } catch (error) {
            if (error.name === 'AbortError') return;
    
            setState(prev => ({ 
                ...prev, 
                isLoadingThreads: false,
                threads: []
            }));
        }
    }, [controlId, token, cleanup]);

    useEffect(() => {
        loadThreads();
    }, [loadThreads]);

    const deleteThread = useCallback(async (threadId) => {
        try {
            await api.delete(
                API_ROUTES.DELETE_THREAD(threadId, controlId),
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setState(prev => ({
                ...prev,
                threads: prev.threads.filter(t => t.id !== threadId),
                currentThreadId: prev.currentThreadId === threadId ? null : prev.currentThreadId,
                chatHistory: prev.currentThreadId === threadId ? [] : prev.chatHistory
            }));

            return true;
        } catch (error) {
            console.error('Error deleting thread:', error);
            throw error.response?.data?.message || 'Failed to delete thread';
        }
    }, [controlId, token]);

    const sendMessage = useCallback((userInput, threadId = null) => {
        return new Promise((resolve, reject) => {
            cleanup();
    
            const initialMessages = [
                { type: 'user', content: userInput },
                { type: 'assistant', content: '' }
            ];
            
            setState(prev => ({
                ...prev,
                chatHistory: [...prev.chatHistory, ...initialMessages],
                isStreaming: true
            }));
    
            try {
                const baseURL = process.env.REACT_APP_API_URL || '';
                const eventSource = new EventSource(
                    `${baseURL}${API_ROUTES.MESSAGE_STREAM(
                        dataroomId,
                        controlId,
                        userInput,
                        threadId || state.currentThreadId,
                        token
                    )}`
                );
                
                sseRef.current = eventSource;
    
                eventSource.onopen = (event) => {
                    console.log('EventSource connection opened:', event);
                };
                
                eventSource.onmessage = (event) => {
                    try {
                        const result = JSON.parse(event.data);
                        console.log('Message received:', result);
                        
                        if (result.error) {
                            throw new Error(result.error);
                        }
    
                        if (result.thread_id && !threadId) {
                            setState(prev => {
                                const newThread = {
                                    id: result.thread_id,
                                    title: result.title || 'New Conversation',
                                    lastUpdate: Date.now()
                                };
                                
                                const updatedThreads = [newThread, ...prev.threads]
                                    .sort((a, b) => b.lastUpdate - a.lastUpdate);
    
                                return { 
                                    ...prev, 
                                    currentThreadId: result.thread_id,
                                    threads: updatedThreads
                                };
                            });
                            return;
                        }
                
                        if (result.content === "END_OF_STREAM") {
                            cleanup();
                            // Mettre à jour la date du thread actif et trier la liste
                            setState(prev => {
                                const currentThreadId = threadId || prev.currentThreadId;
                                const updatedThreads = prev.threads.map(thread => 
                                    thread.id === currentThreadId
                                        ? { ...thread, lastUpdate: Date.now() }
                                        : thread
                                ).sort((a, b) => b.lastUpdate - a.lastUpdate);
    
                                return {
                                    ...prev,
                                    isStreaming: false,
                                    threads: updatedThreads
                                };
                            });
                            resolve(true);
                            return;
                        }
                
                        setState(prev => ({
                            ...prev,
                            chatHistory: prev.chatHistory.map((msg, i) => 
                                i === prev.chatHistory.length - 1 && msg.type === 'assistant'
                                    ? { ...msg, content: msg.content + result.content }
                                    : msg
                            )
                        }));
                    } catch (error) {
                        console.error('Error processing message:', error);
                        handleError(error.message);
                        reject(error);
                    }
                };
    
                eventSource.onerror = (err) => {
                    console.error('SSE Error:', err);
                    if (eventSource.readyState === EventSource.CLOSED) {
                        console.log('EventSource connection closed');
                    }
                    handleError('Connection error');
                    reject(new Error('Connection error'));
                };
                
            } catch (error) {
                console.error('Setup error:', error);
                handleError('Failed to setup connection');
                reject(error);
            }
        });
    }, [dataroomId, controlId, token, state.currentThreadId, cleanup, handleError]);

    return {
        chatHistory: state.chatHistory,
        isStreaming: state.isStreaming,
        currentThreadId: state.currentThreadId,
        threads: state.threads,
        isLoadingThreads: state.isLoadingThreads,
        isLoadingMessages: state.isLoadingMessages,
        sendMessage,
        deleteThread,
        refreshThreads: loadThreads,
        loadMessages,
        cleanup  
    };
};