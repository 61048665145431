import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFetchIRLData = (token, dataroomId) => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const fetchIrlData = async () => {
            if (!token || !dataroomId) return;

            try {
                const response = await api.get(API_ROUTES.GET_IRL_BY_DATAROOM(dataroomId), {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (isMounted) {
                    setRequests(response.data);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error.message || "Error fetching data");
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchIrlData();

        return () => {
            isMounted = false;
        };
    }, [token, dataroomId]);

    return { requests, loading, error, setRequests };
};