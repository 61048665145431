import { useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddComment = (token) => {
    const addComment = useCallback(async (commentData, onSuccess, onError) => {
        if (!token) return;

        try {
            const response = await api.post(API_ROUTES.CREATE_COMMENT, commentData);
            if (onSuccess) {
                onSuccess(response.data);
            }
        } catch (err) {
            console.error("Failed to add comment:", err);
            if (onError) {
                onError('Failed to add comment. Please try again later.');
            }
        }
    }, [token]);

    return addComment;
};
