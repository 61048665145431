import React, { useState, useContext } from 'react';
import { DataRoomContext } from '../DisplayDataRoom';
import ConfGeneralForm from './ConfGeneralForm';
import { Toast } from 'react-bootstrap';

import { useUser } from '../../general/UserProvider';
import { useFetchDataroomDetails } from '../../hooks/configuration/useFetchDataroomDetails';
import { useUpdateDataroom } from '../../hooks/configuration/useUpdateDataroom';

function Configuration() {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);
    const { dataroomDetails, isLoading } = useFetchDataroomDetails(dataroom_id, token);
    const updateDataroom = useUpdateDataroom(dataroom_id, token);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');

    const handleUpdateDataroom = (updatedData) => {
        updateDataroom(updatedData, (data) => {
            setToastMessage('Dataroom updated successfully!');
            setToastVariant('success');
            setShowToast(true);
        }, (errorMsg) => {
            setToastMessage(errorMsg);
            setToastVariant('danger');
            setShowToast(true);
        });
    };

    return (
        <div className="configuration">
            {isLoading ? (
                <div className="loader">Loading...</div> 
            ) : (
                <ConfGeneralForm
                    dataroomDetails={dataroomDetails}
                    onUpdateDataroom={handleUpdateDataroom}
                />
            )}
            <Toast 
                onClose={() => setShowToast(false)} 
                show={showToast} 
                delay={3000} 
                autohide
                style={{
                    position: 'absolute',
                    bottom: 20,
                    right: 20,
                    zIndex: 9999
                }}
            >
                <Toast.Header className="justify-content-between">
                    <strong>Notification</strong>
                </Toast.Header>
                <Toast.Body className={`text-${toastVariant}`}>{toastMessage}</Toast.Body>
            </Toast>
        </div>
    );
};

export default Configuration;