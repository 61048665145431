import React, { useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import './AttributesModal.css';

const AttributesModal = ({ show, onHide, attributes, onUpdateAttribute, file }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingValue, setEditingValue] = useState('');
  const [updatingAttributeId, setUpdatingAttributeId] = useState(null);

  if (!attributes || !attributes.length) return null;

  const handleEditClick = (index, value) => {
    setEditingIndex(index);
    setEditingValue(value);
  };

  const handleSave = async (index) => {
    const attributeId = attributes[index].id;
    setEditingIndex(null);  
    setEditingValue('');
    setUpdatingAttributeId(attributeId);
    
    try {
      await onUpdateAttribute(attributeId, editingValue);
    } finally {
      setUpdatingAttributeId(null);
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.key === 'Enter') {
      handleSave(index);
    } else if (e.key === 'Escape') {
      setEditingIndex(null);
      setEditingValue('');
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      centered
      className="dm-modal"
      dialogClassName="dm-modal-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title className="attributes-modal-title">
          <i className="bi bi-list-ul me-2"></i>
          {file.document_type_name} / {file.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="attributes-modal-body">
        <Table hover>
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Value</th>
              <th className="text-center">Page</th>
              <th className="text-center">Justification</th>
            </tr>
          </thead>
          <tbody>
            {attributes.map((attr, index) => (
              <tr key={attr.id || index}>
                <td className="attribute-name">{attr.attribute_name}</td>
                <td className="attribute-value">
                  {editingIndex === index ? (
                    <div className="attribute-edit-container">
                      <input
                        type="text"
                        className="attribute-edit-input"
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                        onKeyDown={(e) => handleKeyPress(e, index)}
                        autoFocus
                      />
                      <div className="attribute-edit-actions">
                        <button 
                          className="edit-action-button save-button"
                          onClick={() => handleSave(index)}
                          title="Save"
                        >
                          <i className="bi bi-check"></i>
                        </button>
                        <button 
                          className="edit-action-button cancel-button"
                          onClick={() => setEditingIndex(null)}
                          title="Cancel"
                        >
                          <i className="bi bi-x"></i>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="attribute-value-container">
                      <span className="attribute-display-value">
                        {updatingAttributeId === attr.id ? "Updating..." : attr.value}
                      </span>
                      <button
                        className="edit-action-button"
                        onClick={() => handleEditClick(index, attr.value)}
                        title="Edit"
                        disabled={updatingAttributeId === attr.id}
                      >
                        <i className="bi bi-pencil" style={{
                          opacity: updatingAttributeId === attr.id ? 0.5 : 1
                        }}/>
                      </button>
                    </div>
                  )}
                </td>
                <td className="text-center">{attr.page_number}</td>
                <td className="text-center">{attr.justification}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default AttributesModal;