import { useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useDeleteComment = (token) => {
    const deleteComment = useCallback(async (commentId, onDelete) => {
        if (!token) return;

        try {
            await api.delete(API_ROUTES.DELETE_COMMENT(commentId));
            if (onDelete) {
                onDelete();
            }
        } catch (err) {
            console.error("Failed to delete comment:", err);
        }
    }, [token]);

    return deleteComment;
};
