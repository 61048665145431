import React from 'react';
import { Badge, Card } from 'react-bootstrap';
import './ProcedureCard.css';

function ProcedureCard({ procedure, onClick }) {
    const getStatusInfo = (status) => {
        switch (status.toLowerCase()) {
            case 'completed':
                return { color: '#28a745', text: 'Completed' };
            case 'in progress':
                return { color: '#007bff', text: 'In Progress' };
            case 'for review':
                return { color: '#ffc107', text: 'For Review' };
            case 'ready':
                return { color: '#17a2b8', text: 'Ready' };
            case 'missing documents':
                return { color: '#dc3545', text: 'Missing Documents' };
            default:
                return { color: '#6c757d', text: status };
        }
    };

    const getPriorityLabel = (priority) => {
        return priority || 'Unknown';
    };

    const statusInfo = getStatusInfo(procedure.status);

    return (
        <Card className="procedure-card" onClick={onClick} style={{ borderTopColor: statusInfo.color }}>
            <Card.Body>
                <div className="procedure-header">
                    <Card.Title>
                        <i className="bi bi-clipboard-data me-2"></i>
                        {procedure.name}
                    </Card.Title>
                    <span 
                        className="status-badge"
                        style={{ backgroundColor: statusInfo.color }}
                    >
                        {statusInfo.text}
                    </span>
                </div>
                <Card.Subtitle className="mb-2 text-muted">
                    <i className="bi bi-tags me-2"></i>
                    {procedure.assertions && procedure.assertions.length > 0 
                        ? procedure.assertions.map(assertion => assertion.title).join(', ')
                        : 'No assertions'}
                </Card.Subtitle>
                <div className="procedure-footer">
                    <Badge bg="secondary" className="priority-badge">
                        <i className="bi bi-flag-fill me-1"></i>
                        Priority: {getPriorityLabel(procedure.priority)}
                    </Badge>
                </div>
            </Card.Body>
        </Card>
    );
}

export default ProcedureCard;