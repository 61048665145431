import { useState } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useAddDataroomRole = (token) => {
  const [adding, setAdding] = useState(false);
  const [error, setError] = useState(null);

  const addDataroomRole = async (dataroomId, userId, roleId) => {
    if (!token) return;

    setAdding(true);
    setError(null);

    try {
      const response = await api.post(
        `${API_ROUTES.ADMIN_ADD_DATAROOM_ROLE}/${dataroomId}/roles`,
        { user_id: userId, role_id: roleId },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setAdding(false);
      return response.data;
    } catch (error) {
      setError(error.message || "Error adding dataroom role");
      setAdding(false);
      throw error;
    }
  };

  return { addDataroomRole, adding, error };
};