import { useState, useCallback, useEffect, useContext } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';
import { useUser } from '../../general/UserProvider';
import { DataRoomContext } from '../../dataroom/DisplayDataRoom';

export const useFetchFileDetails = (fileUuid) => {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);
    const [fileDetails, setFileDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchFileDetails = useCallback(async (fileUuid, callback) => {
        if (!fileUuid) {
            setFileDetails(null);
            return;
        }

        setIsLoading(true);
        setError(null);

        console.log(API_ROUTES.GET_FILE_BY_UUID(dataroom_id, fileUuid))

        try {
            const response = await api.get(API_ROUTES.GET_FILE_BY_UUID(dataroom_id, fileUuid), {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.status === 200) {
                setFileDetails(response.data);
                if (callback && typeof callback === 'function') {
                    callback(null, response.data); 
                }
            } else {
                throw new Error('Failed to fetch file details');
            }
        } catch (error) {
            setError(error);
            if (callback && typeof callback === 'function') {
                callback(error, null); 
            }
        } finally {
            setIsLoading(false);
        }
    }, [token, dataroom_id]);

    useEffect(() => {
        fetchFileDetails(fileUuid);
    }, [fetchFileDetails, fileUuid]);

    return { 
        fileDetails, 
        isLoading, 
        error,
        refetchFileDetails: fetchFileDetails
    };
};
