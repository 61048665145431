import React, { useState, useCallback } from 'react';
import { Card, Table, Button, Spinner, Alert } from 'react-bootstrap';
import AssertionEditModal from './AssertionEditModal';
import { useAddAssertion, useUpdateAssertion, useDeleteAssertion, useFetchAssertions } from '../../../hooks/admin/assertionsHooks';

const AssertionsInfo = ({ token }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAssertion, setSelectedAssertion] = useState(null);
  const { addAssertion, adding, addError } = useAddAssertion(token);
  const { updateAssertion, updating, updateError } = useUpdateAssertion(token);
  const { deleteAssertion, deleting } = useDeleteAssertion(token);
  const { assertions, loading, error, refetch } = useFetchAssertions(token);

  const handleShowModal = useCallback((assertion = null) => {
    setSelectedAssertion(assertion);
    setShowEditModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowEditModal(false);
    setSelectedAssertion(null);
  }, []);

  const handleSaveAssertion = useCallback(async (assertionData) => {
    try {
      if (selectedAssertion) {
        await updateAssertion({ ...assertionData, id: selectedAssertion.id });
      } else {
        await addAssertion(assertionData);
      }
      refetch();
      handleCloseModal();
    } catch (error) {
      console.error("Failed to save assertion:", error);
    }
  }, [selectedAssertion, updateAssertion, addAssertion, refetch, handleCloseModal]);

  const handleDeleteAssertion = useCallback(async (assertionId) => {
    try {
      await deleteAssertion(assertionId);
      refetch();
    } catch (error) {
      console.error("Failed to delete assertion:", error);
    }
  }, [deleteAssertion, refetch]);

  const TruncatedText = ({ text, maxLength = 100 }) => {
    const truncated = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    return <span title={text}>{truncated}</span>;
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Card className="admin_card shadow-sm">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Assertion Management</h3>
          <Button variant="primary" onClick={() => handleShowModal()}>
            + Add Assertion
          </Button>
        </div>
        {assertions.length === 0 ? (
          <Alert variant="info">No assertions found.</Alert>
        ) : (
          <Table hover responsive className="admin_table-nowrap">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {assertions.map((assertion) => (
                <tr key={assertion.id}>
                  <td><b>{assertion.title}</b></td>
                  <td><TruncatedText text={assertion.description} /></td>
                  <td>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="me-2"
                      onClick={() => handleShowModal(assertion)}
                      title="Edit task type"
                    >
                      <i className="bi bi-pencil"></i>
                    </Button>
                    <Button 
                      variant="outline-danger" 
                      size="sm" 
                      onClick={() => handleDeleteAssertion(assertion.id)}
                      disabled={deleting}
                      title="Delete task type"
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>
  
      <AssertionEditModal
        show={showEditModal}
        handleClose={handleCloseModal}
        assertion={selectedAssertion}
        onSave={handleSaveAssertion}
        isProcessing={updating || adding}
        error={updateError || addError}
      />
    </Card>
  );
};

export default AssertionsInfo;