import React from 'react';
import { Button } from 'react-bootstrap';

function RemoveUserFromGroups( {userId, setModalState, removeUserFromGroups} ) {

    const submitDelete = async (userId) => {
        try {
            await removeUserFromGroups(userId);
        } catch (error) {
            console.error("There was an error removing the user from all groups:", error);
        } finally {
            setModalState(prevState => ({
                ...prevState,
                isOpen: false
            }));
        }
    };

    return (
        <>
            <p>Are you sure you want to remove <i>{userId}</i> from all groups?</p>
            <Button className="mt-3" variant="secondary" onClick={() => setModalState(prevState => ({ ...prevState, isOpen: false }))}>
                Close
            </Button>
            <Button className="mt-3 mx-3" variant="danger" onClick={() => submitDelete(userId)}>
                Yes, delete
            </Button>
        </>
    );
};

export default RemoveUserFromGroups;
