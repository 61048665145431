// hooks/useGenerateAuditDocument.js
import { useState, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useGenerateAuditDocument = (token, dataroomId) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [generatedDocument, setGeneratedDocument] = useState(null);

    const generateDocument = useCallback(async (documentType) => {
        setLoading(true);
        setError(null);
        setGeneratedDocument(null);

        try {
            const response = await api.get(
                API_ROUTES.GENERATE_AUDIT_DOCUMENT(dataroomId, documentType),
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setGeneratedDocument(response.data);
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred while generating the document';
            setError(errorMessage);
            throw new Error(errorMessage);
        } finally {
            setLoading(false);
        }
    }, [token, dataroomId]);

    return {
        generateDocument,
        loading,
        error,
        generatedDocument
    };
};