import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useRef, useEffect } from 'react';

import Bubble from './bubble/Bubble';

import './Discussion.css';

function Discussion({ content, social_url, folders, setModalProperties }) {

    const scrollToRef = useRef(null);

    useEffect(() => {
        if (scrollToRef.current) {
            scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [content]);

    return (
        <div className="flex-grow-1 text-light p-3 Discussion">
            <Container>
                {content.map((message, index) => (
                    <Row key={index}>
                        <Col>
                            <Bubble 
                                key={index} 
                                side={message.side} 
                                text={message.text} 
                                sources={message.sources} 
                                isLoaded={message.isLoaded}
                                social_url={social_url}
                                folders={folders}
                                setModalProperties={setModalProperties}
                            />
                        </Col>
                    </Row>
                ))}
                <div ref={scrollToRef}></div>
            </Container>
        </div>
    );
};

export default Discussion;