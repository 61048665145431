import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFetchTeamData = (token, dataroomId) => {
    const [team, setTeam] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchTeamData = useCallback(async (isMounted = true) => {
        if (!token || !dataroomId) return;

        setLoading(true);
        setError(null);

        try {
            const response = await api.get(API_ROUTES.USERS_BY_DATAROOM(dataroomId), {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (isMounted) {
                setTeam(response.data);
            }
        } catch (error) {
            if (isMounted) {
                setError(error.message || "Error fetching data");
            }
        } finally {
            if (isMounted) {
                setLoading(false);
            }
        }
    }, [token, dataroomId]);

    useEffect(() => {
        let isMounted = true;
        fetchTeamData(isMounted);

        return () => {
            isMounted = false;
        };
    }, [fetchTeamData]);

    const refetchTeamData = useCallback(() => {
        fetchTeamData(true);
    }, [fetchTeamData]);

    return { team, loading, error, refetchTeamData };
};