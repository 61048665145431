import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Card, Table, Button, Spinner, Alert, ButtonGroup } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ControlEditModal from './ControlEditModal';
import { useAddControl, useUpdateControl, useDeleteControl, useFetchControls, useReorderControls } from '../../../hooks/admin/controlsHooks';

import './TemplateControls.css';

const ControlsInfo = ({ token }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedControl, setSelectedControl] = useState(null);
  const [currentAssertions, setCurrentAssertions] = useState([]);
  const [selectedCycle, setSelectedCycle] = useState('all');
  const [cycles, setCycles] = useState(['all']);
  const { addControl, adding, addError } = useAddControl(token);
  const { updateControl, updating, updateError } = useUpdateControl(token);
  const { deleteControl, deleting } = useDeleteControl(token);
  const { controls, setControls, loading, error, refetch } = useFetchControls(token, selectedCycle);  
  const { reorderControls } = useReorderControls(token);

  useEffect(() => {
    if (controls.length > 0) {
      const cycleSet = new Set(controls.map(control => control.cycle?.name).filter(Boolean));
      setCycles(['all', ...Array.from(cycleSet)]);
    }
  }, [controls]);

  const handleShowModal = useCallback((control = null) => {
    setSelectedControl(control);
    setCurrentAssertions(control ? (control.assertions || []).map(a => a.id.toString()) : []);
    setShowEditModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowEditModal(false);
    setSelectedControl(null);
  }, []);

  const handleSaveControl = useCallback(async (controlData, shouldCloseModal = true) => {
    try {
      if (selectedControl) {

        await updateControl({ ...controlData, id: selectedControl.id });
        // Mettre à jour les assertions courantes si la sauvegarde réussit
        if (!shouldCloseModal) {
          setCurrentAssertions(controlData.assertion_ids);
        }
      } else {
        await addControl(controlData);
      }
      refetch();
      if (shouldCloseModal) {
        handleCloseModal();
      }
    } catch (error) {
      console.error("Failed to save control:", error);
    }
  }, [selectedControl, updateControl, addControl, refetch, handleCloseModal]);

  const handleDeleteControl = useCallback(async (controlId) => {
    try {
      await deleteControl(controlId);
      refetch();
    } catch (error) {
      console.error("Failed to delete control:", error);
    }
  }, [deleteControl, refetch]);

  const filteredControls = useMemo(() => {
    if (selectedCycle === 'all') {
      return controls;
    }
    return controls.filter(control => control.cycle?.name === selectedCycle);
  }, [controls, selectedCycle]);

  const sortedFilteredControls = useMemo(() => {
    return [...filteredControls].sort((a, b) => a.order - b.order);
  }, [filteredControls]);

  const handleDragEnd = useCallback(async (result) => {
    if (!result.destination) {
      return;
    }
  
    const items = Array.from(sortedFilteredControls);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    const newOrder = items.map((item) => item.id);
    
    try {
      if (selectedCycle !== 'all') {
        const cycleId = items[0].cycle.id;
        const updatedControls = await reorderControls(cycleId, newOrder);
        
        const sortedUpdatedControls = updatedControls.sort((a, b) => a.order - b.order);
        
        setControls(prevControls => {
          const updatedPrevControls = prevControls.map(control => {
            const updatedControl = sortedUpdatedControls.find(uc => uc.id === control.id);
            return updatedControl || control;
          });
          return updatedPrevControls.sort((a, b) => a.order - b.order);
        });
      }
    } catch (error) {
      console.error("Failed to reorder controls:", error);
    }
  }, [sortedFilteredControls, reorderControls, setControls, selectedCycle]);

  const TruncatedText = ({ text, maxLength = 60 }) => {
    const truncated = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    return <span title={text}>{truncated}</span>;
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Card className="admin_card shadow-sm">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Control Management</h3>
          <Button variant="primary" onClick={() => handleShowModal()}>
            + Add Control
          </Button>
        </div>
        <div className="mb-3">
          <ButtonGroup>
            {cycles.map((cycle) => (
              <Button
                key={cycle}
                variant={selectedCycle === cycle ? "primary" : "outline-primary"}
                onClick={() => setSelectedCycle(cycle)}
              >
                {cycle === 'all' ? 'All Cycles' : cycle}
              </Button>
            ))}
          </ButtonGroup>
        </div>
        {sortedFilteredControls.length === 0 ? (
          <Alert variant="info">No controls found for the selected cycle.</Alert>
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="controls">
              {(provided) => (
                <Table hover responsive className="admin_table-nowrap" {...provided.droppableProps} ref={provided.innerRef}>
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Cycle</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedFilteredControls.map((control, index) => (
                      <Draggable key={control.id} draggableId={control.id.toString()} index={index}>
                        {(provided) => (
                          <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <td>{control.order}</td>
                            <td>{control.name}</td>
                            <td><TruncatedText text={control.description} /></td>
                            <td>{control.cycle ? control.cycle.name : 'N/A'}</td>
                            <td>
                              <Button
                                variant="outline-secondary"
                                size="sm"
                                className="me-2"
                                onClick={() => handleShowModal(control)}
                                title="Edit control"
                              >
                                <i className="bi bi-pencil"></i>
                              </Button>
                              <Button 
                                variant="outline-danger" 
                                size="sm" 
                                onClick={() => handleDeleteControl(control.id)}
                                disabled={deleting}
                                title="Delete control"
                              >
                                <i className="bi bi-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Card.Body>
  
      <ControlEditModal
        show={showEditModal}
        handleClose={handleCloseModal}
        control={selectedControl}
        onSave={handleSaveControl}
        isProcessing={updating || adding}
        error={updateError || addError}
        token={token}  
        currentAssertions={currentAssertions}
        onAssertionsChange={setCurrentAssertions}
      />
    </Card>
  );
};

export default ControlsInfo;