import React, { useState, useEffect } from 'react';
import { Modal, Table, Card, Form, Pagination, Row, Col, Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';

const BankStatementViewer = ({ show, onHide, bankStatementData }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [exportFunction, setExportFunction] = useState(() => {});
    const [sortConfig, setSortConfig] = useState({ key: 'transaction_date', direction: 'asc' });
    const ROWS_PER_PAGE = 50;

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };

    const formatAmount = (amount) => {
        if (amount === null || amount === undefined) return '';
        return amount.toLocaleString('fr-FR', { 
            style: 'currency', 
            currency: 'EUR',
            minimumFractionDigits: 2 
        });
    };

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: 
                prevConfig.key === key && prevConfig.direction === 'asc'
                    ? 'desc'
                    : 'asc',
        }));
        setCurrentPage(1);
    };

    const getSortIcon = (columnName) => {
        if (sortConfig.key !== columnName) {
            return <i className="bi bi-arrow-down-up ms-1"></i>;
        }
        return sortConfig.direction === 'asc' 
            ? <i className="bi bi-arrow-up ms-1"></i>
            : <i className="bi bi-arrow-down ms-1"></i>;
    };

    useEffect(() => {
        if (bankStatementData?.bank_statements) {
            const exportToExcel = () => {
                const excelData = bankStatementData.bank_statements.map(transaction => ({
                    Date: formatDate(transaction.transaction_date),
                    Type: transaction.transaction_type,
                    Details: transaction.transaction_details,
                    Reference: transaction.reference || '',
                    Debit: transaction.debit || '',
                    Credit: transaction.credit || '',
                    Balance: transaction.balance || ''
                }));

                const worksheet = XLSX.utils.json_to_sheet(excelData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Bank Statement");
                XLSX.writeFile(workbook, `Bank_Statement_${bankStatementData.fiscal_year}.xlsx`);
            };
            setExportFunction(() => exportToExcel);
        }
    }, [bankStatementData]);

    const sortData = (data) => {
        return [...data].sort((a, b) => {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            // Handle date columns
            if (sortConfig.key.includes('date')) {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
            }
            // Handle numeric columns (debit, credit)
            else if (sortConfig.key === 'debit' || sortConfig.key === 'credit') {
                aValue = aValue || 0;
                bValue = bValue || 0;
            }

            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    const filteredAndSortedData = bankStatementData?.bank_statements
        ? sortData(
            bankStatementData.bank_statements.filter(transaction =>
                Object.values(transaction).some(value =>
                    value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                )
            )
        )
        : [];

    const totalPages = Math.ceil(filteredAndSortedData.length / ROWS_PER_PAGE);
    const currentData = filteredAndSortedData.slice(
        (currentPage - 1) * ROWS_PER_PAGE,
        currentPage * ROWS_PER_PAGE
    );

    return (
        <Modal show={show} onHide={onHide} fullscreen>
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center gap-3">
                    Bank Statement Viewer -
                    <Button
                        variant="outline-primary"
                        onClick={exportFunction}
                        className="d-flex align-items-center gap-2"
                        size="sm"
                    >
                        <i className="bi bi-filetype-xls"></i>
                        Export
                    </Button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="h-100 d-flex flex-column p-2">
                    <Card className="mb-3">
                        <Card.Body>
                            <Row className="align-items-center">
                                <Col md={8}>
                                    <div className="d-flex align-items-center mb-2">
                                        <h5 className="mb-0 me-3">Bank Statement Analysis</h5>
                                        <span className="badge bg-secondary">FY {bankStatementData?.fiscal_year}</span>
                                    </div>
                                    <Row className="g-3">
                                        <Col md={4}>
                                            <div className="stat-item">
                                                <div className="stat-label">Period</div>
                                                <div className="stat-value">
                                                    {formatDate(bankStatementData?.start_date)} - {formatDate(bankStatementData?.end_date)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="stat-item">
                                                <div className="stat-label">Total Entries</div>
                                                <div className="stat-value">{bankStatementData?.number_of_entries?.toLocaleString()}</div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="stat-item">
                                                <div className="stat-label">Total Balance</div>
                                                <div className="stat-value">
                                                    {formatAmount(bankStatementData?.statistics?.net_amount)}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <Form.Control
                                        type="search"
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="w-100"
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <div className="flex-grow-1 overflow-auto">
                        <div className="table-responsive">
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('transaction_date')} style={{ cursor: 'pointer' }}>
                                            Transaction date {getSortIcon('transaction_date')}
                                        </th>
                                        <th onClick={() => handleSort('value_date')} style={{ cursor: 'pointer' }}>
                                            Value date {getSortIcon('value_date')}
                                        </th>
                                        <th>Details</th>
                                        <th>Reference</th>
                                        <th 
                                            onClick={() => handleSort('debit')} 
                                            style={{ cursor: 'pointer' }}
                                            className="text-end"
                                        >
                                            Debit {getSortIcon('debit')}
                                        </th>
                                        <th 
                                            onClick={() => handleSort('credit')} 
                                            style={{ cursor: 'pointer' }}
                                            className="text-end"
                                        >
                                            Credit {getSortIcon('credit')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.map((transaction) => (
                                        <tr key={transaction.id}>
                                            <td>{formatDate(transaction.transaction_date)}</td>
                                            <td>{formatDate(transaction.value_date)}</td>
                                            <td>{transaction.transaction_details}</td>
                                            <td>{transaction.reference}</td>
                                            <td className="text-end">{transaction.debit ? formatAmount(transaction.debit) : ''}</td>
                                            <td className="text-end">{transaction.credit ? formatAmount(transaction.credit) : ''}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="border-top p-2 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <small>
                                Showing entries {((currentPage - 1) * ROWS_PER_PAGE) + 1} - {Math.min(currentPage * ROWS_PER_PAGE, filteredAndSortedData.length)} of {filteredAndSortedData.length}
                            </small>
                            <Pagination className="mb-0">
                                <Pagination.First
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage(1)}
                                />
                                <Pagination.Prev
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                />
                                <Pagination.Item active>{currentPage}</Pagination.Item>
                                <Pagination.Next
                                    disabled={currentPage === totalPages}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                />
                                <Pagination.Last
                                    disabled={currentPage === totalPages}
                                    onClick={() => setCurrentPage(totalPages)}
                                />
                            </Pagination>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default BankStatementViewer;