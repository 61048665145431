import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ControlPoint = ({ cp, level, onContentClick, isControlPoint }) => {
  const getStatusVariant = (status) => {
    switch(status?.toLowerCase()) {
      case 'agree':
        return 'success';
      case 'disagree':
        return 'danger';
      case 'cannot determine':
        return 'secondary';
      default:
        return 'primary';
    }
  };

  const getStatusText = (status) => {
    return status?.status || 'Unknown';
  };

  if (!isControlPoint) {
    return null;
  }

  return (
    <tr className="control-point-row">
      <td colSpan="2" className="control-point-cell">
        <div className="tree-item-container" style={{ paddingLeft: `${level * 20}px` }}>
          <div className="tree-item-wrapper">
            <div className="tree-item-header">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-${cp.id}`}>Click to view description</Tooltip>}
              >
                <span className="control-point item-name" onClick={() => onContentClick(cp)}>
                  {cp.name}
                </span>
              </OverlayTrigger>
            </div>
            <div className="status-container">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-status-${cp.id}`}>{getStatusText(cp.status)}</Tooltip>}
              >
                <span 
                  className={`status-badge status-${getStatusVariant(getStatusText(cp.status))}`}
                >
                  {getStatusText(cp.status)}
                </span>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ControlPoint;