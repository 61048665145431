import React, {useEffect, useContext } from 'react';
import { Table, OverlayTrigger } from 'react-bootstrap';
import { DataRoomContext } from '../DisplayDataRoom';
import AddUserToGroupForm from './AddUserToGroupForm';
import RemoveUserFromGroups from './RemoveUserFromGroups';
import { useUser } from '../../general/UserProvider';

import { useUserGroups } from '../../hooks/groups/useUserGroups';
import { useGroups } from '../../hooks/groups/useGroups';

function GroupsPerUser( {groupItemsByFolderName, setModalState, setError, renderTooltip } ) {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);

    const { allUsers, loadUsers, removeUserFromGroups } = useUserGroups(token, dataroom_id, setError);
    const { groupsWithUsers, loadUserGroups, updateGroupUser } = useGroups(token, dataroom_id, setError);

    useEffect(() => {
        loadUserGroups();
        loadUsers();
    }, [loadUserGroups, loadUsers]);

    const openModalAddUserToGroup = () => {
        setModalState(prevState => ({
            ...prevState,
            isOpen: true,
            title: "Add a user to a group",
            content: <AddUserToGroupForm setModalState={setModalState} loadUsers={loadUsers} loadUserGroups={loadUserGroups} />
        }));
    };

    const handleRemoveUserFromAllGroups = (userId) => {
        setModalState(prevState => ({
            ...prevState,
            isOpen: true,
            title: "Remove user from all groups",
            content: <RemoveUserFromGroups 
                userId={userId} 
                setModalState={setModalState} 
                removeUserFromGroups={removeUserFromGroups} 
            />
        }));
    };

    return (
        <>
            <h2 className="my-3">Groups per user</h2>
            <Table bordered responsive className="custom-bordered-table">
                <thead>
                    <tr>
                        <th rowSpan="2"></th>
                        {Object.entries(groupItemsByFolderName(groupsWithUsers)).map(([categoryName, groups]) => {
                            const folderHierarchyTooltip = groups[0].folder_hierarchy; 
                            if (folderHierarchyTooltip.length !== 0) {
                                return (
                                    <OverlayTrigger
                                        key={categoryName}
                                        placement="top"
                                        overlay={props => renderTooltip(props, folderHierarchyTooltip)}
                                    >
                                        <th colSpan={groups.length} className="text-center">
                                            {categoryName !== "null" ? categoryName : ''}
                                        </th>
                                    </OverlayTrigger>
                                );
                            }
                            else {
                                return (
                                    <th key={categoryName} colSpan={groups.length} className="text-center">
                                        {categoryName !== "null" ? categoryName : ''}
                                    </th>
                                );
                            }
                        })}
                        <th rowSpan="2" className="text-center"></th>
                    </tr>
                    <tr>
                        {groupsWithUsers.map(group => (
                            <th key={group.group_id} className="text-center">{group.group_name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {allUsers.map(user => (
                        <tr key={user.user_id}>
                            <td className="custom-border fw-bold text-center align-middle">{user.username}</td>
                            {groupsWithUsers.map(group => {
                                const isMember = group.users.includes(user.user_id); 

                                if (group.group_name === "Default") {
                                    return (
                                        <td 
                                            key={group.group_id} 
                                            className={`text-center ${isMember ? 'green-cell' : 'red-cell'}`}>
                                            {isMember ? (
                                                <i className="bi bi-check" style={{ color: 'green' }}></i>
                                            ) : (
                                                <i className="bi bi-x" style={{ color: 'red' }}></i>
                                            )}
                                        </td>
                                    );
                                }

                                return (
                                    <td 
                                        key={group.group_id} 
                                        className={`text-center ${isMember ? 'green-cell' : 'red-cell'} cursor-pointer cell-hover-opacity`}
                                        onClick={() => updateGroupUser(user.user_id, group.group_id, isMember)}
                                    >
                                        {isMember ? (
                                            <i className="bi bi-check" style={{ color: 'green' }}></i>
                                        ) : (
                                            <i className="bi bi-x" style={{ color: 'red' }}></i>
                                        )}
                                    </td>
                                );
                            })}
                            <td 
                                className="text-center deleteButton p-0 cursor-pointer align-middle" 
                                onClick={() => handleRemoveUserFromAllGroups(user.user_id)}
                            >
                                <i className="bi bi-trash"></i>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <button className="btn btn-dark mb-2" onClick={openModalAddUserToGroup}>Add a user to the dataroom</button>
        </>
    );
};

export default GroupsPerUser;
