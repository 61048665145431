import { useState } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useDeactivateUser = (token) => {
  const [deactivating, setDeactivating] = useState(false);
  const [error, setError] = useState(null);

  const deactivateUser = async (userId) => {
    if (!token) return;

    setDeactivating(true);
    setError(null);

    try {
      await api.post(
        API_ROUTES.ADMIN_DEACTIVATE_USER(userId),
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setDeactivating(false);
      return true;
    } catch (error) {
      setError(error.message || "Error deactivating user");
      setDeactivating(false);
      throw error;
    }
  };

  return { deactivateUser, deactivating, error };
};