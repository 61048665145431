import React from 'react';

function SortableHeader({ label, field, currentSortField, sortDirection, handleSort }) {
    const isCentered = ['creator', 'assignee', 'deadline', 'status'].includes(field); 
    return (
        <th 
            onClick={() => handleSort(field)} 
            className={`cursor-pointer ${isCentered ? 'text-center' : ''}`}>
            {label} {currentSortField === field && (sortDirection === 'asc' ? '↑' : '↓')}
        </th>
    );
}


export default SortableHeader;