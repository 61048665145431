import React, { useState, useContext, useMemo } from 'react';
import { Table, Badge } from 'react-bootstrap';
import LayoutPageHeader from '../../general/LayoutPageHeader';
import ActivityCard from './ActivityCard';
import { DataRoomContext } from '../DisplayDataRoom';
import { useFetchEvents } from '../../hooks/activities/useFetchEvents';

import './Activities.css';

function Activities() {
    const dataroomId = useContext(DataRoomContext);
    const { eventsToday, eventsThisWeek, eventsEarlier, isLoading, error } = useFetchEvents(dataroomId);
    const [selectedUser, setSelectedUser] = useState(null);

    const users = useMemo(() => {
        const allEvents = [...eventsToday, ...eventsThisWeek, ...eventsEarlier];
        const userSet = new Set(allEvents.map(event => event.triggered_by_username));
        return Array.from(userSet);
    }, [eventsToday, eventsThisWeek, eventsEarlier]);

    const filteredEvents = useMemo(() => {
        if (!selectedUser) return { eventsToday, eventsThisWeek, eventsEarlier };
        return {
            eventsToday: eventsToday.filter(event => event.triggered_by_username === selectedUser),
            eventsThisWeek: eventsThisWeek.filter(event => event.triggered_by_username === selectedUser),
            eventsEarlier: eventsEarlier.filter(event => event.triggered_by_username === selectedUser)
        };
    }, [selectedUser, eventsToday, eventsThisWeek, eventsEarlier]);

    const renderContent = () => (
        <>
            <div className="user-filter">
                <span className="filter-label">Filter by user:</span>
                {users.map(user => (
                    <Badge
                        key={user}
                        bg={selectedUser === user ? "primary" : "secondary"}
                        className="user-badge"
                        onClick={() => setSelectedUser(selectedUser === user ? null : user)}
                    >
                        {user}
                    </Badge>
                ))}
            </div>

            {error ? (
                <div className="error-message">Error: {error}</div>
            ) : (
                <div className="table-container">
                    <Table hover className="custom-table">
                        <thead>
                            <tr>
                                <th>Event</th>
                                <th>User</th>
                                <th>Description</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <div className="loading-spinner">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    {filteredEvents.eventsToday.length > 0 && (
                                        <Section title="Today" events={filteredEvents.eventsToday} />
                                    )}
                                    {filteredEvents.eventsThisWeek.length > 0 && (
                                        <Section title="This week" events={filteredEvents.eventsThisWeek} />
                                    )}
                                    {filteredEvents.eventsEarlier.length > 0 && (
                                        <Section title="Earlier" events={filteredEvents.eventsEarlier} />
                                    )}
                                    {filteredEvents.eventsToday.length === 0 && filteredEvents.eventsThisWeek.length === 0 && filteredEvents.eventsEarlier.length === 0 && (
                                        <tr>
                                            <td colSpan="4">
                                                <div className="empty-message">
                                                    No activities found.
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </Table>
                </div>
            )}
        </>
    );

    return (
        <LayoutPageHeader title="Activities">
            {renderContent()}
        </LayoutPageHeader>
    );
}

function Section({ title, events }) {
    return (
        <>
            <tr className="section-title">
                <td colSpan="4">{title}</td>
            </tr>
            {events.map(event => (
                <ActivityCard key={event.event_id} activity={event} />
            ))}
        </>
    );
}

export default Activities;