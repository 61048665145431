import React from 'react';
import { Breadcrumb } from "react-bootstrap";

function DocumentBreadcrumb({ breadcrumbItems, handleBreadcrumbClick }) {

    const addIconForRoot = (name) => {
        if (name === "Root") {
            return <i className="bi bi-house-fill"></i>;
        } else {
            return name;
        }
    };

    return (
        <Breadcrumb className="breadcrumbDoc">
            {breadcrumbItems.map((folder, index) => (
                <Breadcrumb.Item 
                    key={"BC_"+folder.id} 
                    onClick={() => {
                        if (index < breadcrumbItems.length - 1) {
                            handleBreadcrumbClick(folder);
                        }
                    }}
                >
                    {addIconForRoot(folder.name)}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};

export default DocumentBreadcrumb;
