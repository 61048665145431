import React from 'react';
import { useState, useEffect } from 'react';
import { Form, Button, FormGroup, FormCheck } from 'react-bootstrap';

function RenameForm({ item, setModalProperties, submitAction }) {
    const [localName, setLocalName] = useState(item.name || "");
    const [isCategory, setIsCategory] = useState(item.is_category || false);

    useEffect(() => {
        setLocalName(item.name || "");
        setIsCategory(item.is_category !== undefined ? item.is_category : false);
    }, [item]);

    const isModified = 
        (localName.trim() !== (item.name || "").trim() || isCategory !== (item.is_category || false)) 
        && localName.trim() !== "";

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (localName.trim() === "") {
            alert("Name cannot be empty!");
            return;
        }
        submitAction(item.id, {localName, isCategory});
    };

    const setIsRenameModalOpen = (value) => {
        setModalProperties(prevProps => ({
            ...prevProps,
            isOpen: value
        }));
    };

    return (
        <Form onSubmit={handleFormSubmit}>
            <FormGroup>
                <Form.Label>New name</Form.Label>
                <Form.Control
                    type="text"
                    value={localName}
                    onChange={e => setLocalName(e.target.value)}
                />
            </FormGroup>
            {'is_category' in item && ( 
                <FormGroup>
                    <FormCheck 
                        type="checkbox"
                        id="isCategoryCheckbox"
                        label="Is a category"
                        checked={isCategory}
                        onChange={e => setIsCategory(e.target.checked)}
                        className="mt-3"
                    />
                </FormGroup>
            )}
            <Button className="mt-3" variant="secondary" onClick={() => setIsRenameModalOpen(false)}>
                Close
            </Button>
            <Button className="mt-3 mx-3" variant="primary" type="submit" disabled={!isModified}>
                Save
            </Button>
        </Form>
    );
};

export default RenameForm;
