import React from 'react';
import { Button } from 'react-bootstrap';

const PageHeader = ({ title, showButton, buttonText }) => (
  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2">{title}</h1>
    {showButton && (
      <Button variant="primary" className="admin_action-button">
        {buttonText}
      </Button>
    )}
  </div>
);

export default PageHeader;