import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './UserMessage.css';

const UserMessage = ({ content }) => (
    <div className="chat-message justify-content-end">
        <div className="user-message markdown-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {content}
            </ReactMarkdown>
        </div>
    </div>
);

export default UserMessage;