import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';

const TemplateFolderNode = ({ 
  folder, 
  level = 0, 
  onEdit, 
  onDelete 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const paddingLeft = `${level * 2}rem`;

  return (
    <>
      <div
        className="folder-item"
        style={{ paddingLeft }}
      >
        <div className="d-flex align-items-center w-100 py-2">
          {folder.children?.length > 0 && (
            <Button 
              variant="link" 
              className="p-0 me-2" 
              onClick={() => setIsOpen(!isOpen)}
            >
              <i className={`bi bi-chevron-${isOpen ? 'down' : 'right'}`}></i>
            </Button>
          )}

          <i className={`bi bi-folder${isOpen ? '-open' : ''} text-primary me-2`}></i>
          <span className="flex-grow-1 fw-medium">{folder.name}</span>

          <Dropdown align="end">
            <Dropdown.Toggle variant="link" size="sm" className="p-0">
              <i className="bi bi-three-dots-vertical"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => onEdit(folder)}>
                <i className="bi bi-pencil me-2"></i>
                Edit
              </Dropdown.Item>
              <Dropdown.Item 
                onClick={() => onDelete(folder.id)}
                className="text-danger"
              >
                <i className="bi bi-trash me-2"></i>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {isOpen && folder.children?.length > 0 && (
        <div>
          {folder.children.map((child) => (
            <TemplateFolderNode
              key={child.id}
              folder={child}
              level={level + 1}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default TemplateFolderNode;