import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Modal, Button, Form, Spinner, Alert, Container, Row, Col, Table, Badge } from 'react-bootstrap';
import { useFetchRoles } from '../../../../hooks/admin/useFetchRole';
import { useFetchDataroomRoles } from '../../../../hooks/admin/useFetchDataroomRoles';
import { useAddDataroomRole } from '../../../../hooks/admin/useAddDataroomRole';
import { useRemoveDataroomRole } from '../../../../hooks/admin/useRemoveDataroomRole';
import '../../../EditModal.css';

const DataroomRoleEditModal = ({ show, handleClose, dataroom, token }) => {
  const { fetchRoles, roles, loading: rolesLoading, error: rolesError } = useFetchRoles(token);
  const { fetchDataroomRoles, users: allUsers, loading: usersLoading, error: usersError } = useFetchDataroomRoles(token);
  const { addDataroomRole, adding, error: addError } = useAddDataroomRole(token);
  const { removeDataroomRole, removing, error: removeError } = useRemoveDataroomRole(token);
  const [filter, setFilter] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [localUsers, setLocalUsers] = useState([]);

  const fetchRolesRef = useRef(fetchRoles);
  const fetchDataroomRolesRef = useRef(fetchDataroomRoles);
  const dataroomRef = useRef(dataroom);

  useEffect(() => {
    fetchRolesRef.current = fetchRoles;
    fetchDataroomRolesRef.current = fetchDataroomRoles;
    dataroomRef.current = dataroom;
  }, [fetchRoles, fetchDataroomRoles, dataroom]);

  useEffect(() => {
    if (show && dataroomRef.current) {
      fetchRolesRef.current();
      fetchDataroomRolesRef.current(dataroomRef.current.dataroom_id);
    }
  }, [show]);

  useEffect(() => {
    setLocalUsers(allUsers);
  }, [allUsers]);

  const handleRoleChange = useCallback(async (userId, roleId, isChecked) => {
    if (!dataroomRef.current) return;

    try {
      if (isChecked) {
        await addDataroomRole(dataroomRef.current.dataroom_id, userId, roleId);
      } else {
        await removeDataroomRole(dataroomRef.current.dataroom_id, userId, roleId);
      }
      
      const updatedRole = roles.find(r => r.role_id === roleId);
      
      setLocalUsers(prevUsers => 
        prevUsers.map(user => {
          if (user.id === userId) {
            const updatedRoles = isChecked
              ? [...user.roles, { ...updatedRole, id: updatedRole.role_id }]
              : user.roles.filter(r => r.id !== roleId);
            return { ...user, roles: updatedRoles };
          }
          return user;
        })
      );

      setSelectedUser(prevUser => {
        if (prevUser && prevUser.id === userId) {
          const updatedRoles = isChecked
            ? [...prevUser.roles, { ...updatedRole, id: updatedRole.role_id }]
            : prevUser.roles.filter(r => r.id !== roleId);
          return { ...prevUser, roles: updatedRoles };
        }
        return prevUser;
      });

    } catch (err) {
      console.error("Error updating role:", err);
    }
  }, [addDataroomRole, removeDataroomRole, roles, dataroomRef]);

  const isLoading = rolesLoading || usersLoading || adding || removing;
  const error = rolesError || usersError || addError || removeError;

  const filteredUsers = localUsers.filter(user => 
    user.username.toLowerCase().includes(filter.toLowerCase())
  );

  const usersWithRoles = filteredUsers.filter(user => user.roles.length > 0);
  const usersWithoutRoles = filteredUsers.filter(user => user.roles.length === 0);

  return (
    <Modal show={show} onHide={handleClose} fullscreen={true} className="edit-modal modal-fullscreen">
      <Modal.Header closeButton>
        <Modal.Title>Manage Roles - {dataroom?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          <Row>
            <Col md={5}>
              <h5 className="mb-3">Users</h5>
              <Form.Control
                type="text"
                placeholder="Search users..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="mb-3"
              />
              <div style={{maxHeight: '70vh', overflowY: 'auto'}}>
                <Table hover>
                  <tbody>
                    {usersWithRoles.map(user => (
                      <tr 
                        key={user.id} 
                        onClick={() => setSelectedUser(user)}
                        className={selectedUser?.id === user.id ? 'table-primary' : ''}
                        style={{cursor: 'pointer'}}
                      >
                        <td>{user.username}</td>
                        <td>
                          {user.roles.map(role => (
                            <Badge key={role.id} bg="secondary" className="me-1">
                              {role.name || role.role_name}
                            </Badge>
                          ))}
                        </td>
                      </tr>
                    ))}
                    {filter && usersWithoutRoles.map(user => (
                      <tr 
                        key={user.id} 
                        onClick={() => setSelectedUser(user)}
                        className={selectedUser?.id === user.id ? 'table-primary' : ''}
                        style={{cursor: 'pointer'}}
                      >
                        <td>{user.username}</td>
                        <td><Badge bg="light" text="dark">No roles</Badge></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col md={7}>
              <h5 className="mb-3">Role Management</h5>
              {selectedUser ? (
                <>
                  <h6>Roles for {selectedUser.username}</h6>
                  <Form>
                    {roles.map(role => (
                      <Form.Check 
                        key={role.role_id}
                        type="checkbox"
                        id={`role-${selectedUser.id}-${role.role_id}`}
                        label={role.role_name}
                        checked={selectedUser.roles.some(r => r.id === role.role_id)}
                        onChange={(e) => handleRoleChange(selectedUser.id, role.role_id, e.target.checked)}
                        disabled={isLoading}
                      />
                    ))}
                  </Form>
                </>
              ) : (
                <Alert variant="info">Select a user to manage their roles.</Alert>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="me-auto">
          {isLoading && (
            <span className="text-muted">
              <Spinner animation="border" size="sm" className="me-2" />
              Processing...
            </span>
          )}
        </div>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataroomRoleEditModal;