import { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useFetchActivitiesList = (dataroomId, objectType, objectId) => {
    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchActivities = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await api.get(API_ROUTES.OBJECT_EVENTS(dataroomId, objectType, objectId));
            setActivities(response.data);
        } catch (error) {
            setError(error.message || "Failed to fetch activities");
        } finally {
            setIsLoading(false);
        }
    }, [dataroomId, objectType, objectId]);

    useEffect(() => {
        fetchActivities();
    }, [fetchActivities]);

    return { activities, isLoading, error };
};
