import React, { useState } from 'react';
import { Card, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useAudit } from '../../../hooks/workflow/useAudit';
import AuditPreparationModal from '../../../common/AuditPreparationModal';
import AuditDocumentsMenu from './AuditDocumentsMenu';
import './AuditOverview.css';

const AuditOverview = ({ dataroomId, token, workflowData, onFileOpen }) => {
    const [showDetails, setShowDetails] = useState(false);
    const [showPreparationModal, setShowPreparationModal] = useState(false);
    const { 
        auditData, 
        loading, 
        error,
        preparingAudit,
        prepareError,
        prepareAudit,
        preparationStatus,
        refetch
    } = useAudit(token, dataroomId);

    const toggleDetails = () => setShowDetails(!showDetails);

    const handlePrepareAudit = async () => {
        setShowPreparationModal(true);
        try {
            await prepareAudit(); 
            refetch();
        } catch (error) {
            console.error('Error preparing audit:', error);
        }
    };

    const handlePreparationComplete = () => {
        setShowPreparationModal(false);
        refetch();
    };

    const formatKey = (key) => {
        return key
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    // Calculate workflow statistics
    const totalControls = workflowData?.reduce((sum, cycle) => sum + cycle.controls.length, 0) || 0;
    const completedControls = workflowData?.reduce((sum, cycle) => 
        sum + cycle.controls.filter(control => control.status === "Completed").length, 0) || 0;
    const completionPercentage = totalControls > 0 ? Math.round((completedControls / totalControls) * 100) : 0;

    // Format workflow data to match other sections
    const workflowStats = {
        total_controls: totalControls,
        completed_controls: completedControls,
        completion_percentage: `${completionPercentage}%`,
        total_cycles: workflowData?.length || 0
    };

    if (loading) return (
        <div className="text-center p-4">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );

    if (error || prepareError) return (
        <Card className="audit-overview-card mb-4">
            <Card.Body>
                <div className="alert alert-danger">
                    {error ? `Error loading audit data: ${error}` : `Error preparing audit: ${prepareError}`}
                </div>
            </Card.Body>
        </Card>
    );

    if (!auditData) return null;

    return (
        <>
            <Card className="audit-overview-card mb-4">
                <Card.Body>
                    <div className="d-flex justify-content-between align-items-start mb-3">
                        <Card.Title className="audit-overview-title">
                            {auditData.client_name} - Fiscal Year {auditData.fiscal_year}
                        </Card.Title>
                        <button
                            onClick={toggleDetails}
                            className="details-toggle-btn"
                        >
                            {showDetails ? 'Hide details' : 'Show details'}
                            <i className={`bi ${showDetails ? 'bi-chevron-up' : 'bi-chevron-down'} ms-1`}></i>
                        </button>
                    </div>
                    <Row className="audit-overview-info">
                        <Col md={6}>
                            <p>
                                <i className="bi bi-calendar-event icon"></i>
                                <strong>Created</strong>
                                <span>{new Date(auditData.created_at).toLocaleDateString()}</span>
                            </p>
                            <p>
                                <i className="bi bi-person-badge icon"></i>
                                <strong>Lead Auditor</strong>
                                <span>{auditData.global_auditor_name}</span>
                            </p>
                        </Col>
                        <Col md={6}>
                            <p>
                                <i className="bi bi-building icon"></i>
                                <strong>Client</strong>
                                <span>{auditData.client_name}</span>
                            </p>
                            <p>
                                <i className="bi bi-briefcase icon"></i>
                                <strong>Activity</strong>
                                <span>{auditData.activity_type}</span>
                            </p>
                        </Col>
                    </Row>
                    
                    <div className={`audit-details ${showDetails ? 'show' : ''}`}>
                        <Card.Title className="mt-4">Risk Assessment</Card.Title>
                        <Row className="audit-risk-assessment mb-3">
                            {Object.entries(auditData.global_risks || {}).map(([key, value]) => (
                                <Col md={6} key={key}>
                                    <p>
                                        <strong>{formatKey(key)}</strong>
                                        <span>{value}</span>
                                    </p>
                                </Col>
                            ))}
                        </Row>
                        
                        <Card.Title>Financial Highlights</Card.Title>
                        <Row className="audit-financial-highlights mb-3">
                            {Object.entries(auditData.financial_highlights || {}).map(([key, value]) => (
                                <Col md={6} key={key}>
                                    <p>
                                        <strong>{formatKey(key)}</strong>
                                        <span>{value}</span>
                                    </p>
                                </Col>
                            ))}
                        </Row>
                        
                        <Card.Title>Audit Thresholds</Card.Title>
                        <Row className="audit-thresholds mb-3">
                            {Object.entries(auditData.thresholds || {}).map(([key, value]) => (
                                <Col md={6} key={key}>
                                    <p>
                                        <strong>{formatKey(key)}</strong>
                                        <span>{value}</span>
                                    </p>
                                </Col>
                            ))}
                        </Row>

                        <Card.Title>Additional Information</Card.Title>
                        <Row className="additional-info mb-3">
                            {Object.entries(auditData.other_information || {}).map(([key, value]) => (
                                <Col md={6} key={key}>
                                    <p>
                                        <strong>{formatKey(key)}</strong>
                                        <span>{value}</span>
                                    </p>
                                </Col>
                            ))}
                        </Row>

                        <Card.Title>Workflow Progress</Card.Title>
                        <Row className="workflow-progress mb-3">
                            {Object.entries(workflowStats).map(([key, value]) => (
                                <Col md={6} key={key}>
                                    <p>
                                        <strong>{formatKey(key)}</strong>
                                        <span>{value}</span>
                                    </p>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    
                    <div className="mt-2 d-flex flex-wrap gap-2">
                    <AuditDocumentsMenu 
                        dataroomId={dataroomId}
                        token={token}
                        onFileOpen={onFileOpen}
                        onRefetchAudit={refetch}
                        documents={{
                            // Audit Planning Documents
                            auditPlanUrl: auditData.audit_plan_presigned_url,
                            engagementLetterUrl: auditData.engagement_letter_presigned_url,
                            
                            fecUrl: auditData.fec_presigned_url,
                            generalLedgerUrl: auditData.general_ledger_presigned_url,
                            balanceSheetUrl: auditData.balance_sheet_presigned_url,
                            subsidiaryLedgersUrl: auditData.subsidiary_ledgers_presigned_url,
                            incomeStatementUrl: auditData.income_statement_presigned_url
                        }}
                    />
                        <Button
                            variant="success"
                            onClick={handlePrepareAudit}
                            disabled={preparingAudit}
                        >
                            {preparingAudit ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-2"
                                    />
                                    Processing...
                                </>
                            ) : (
                                <>
                                    <i className="bi bi-robot me-2"></i>Prepare Audit
                                </>
                            )}
                        </Button>
                        <Button 
                            variant="success" 
                            onClick={() => {}} 
                            disabled
                        >
                            <i className="bi bi-file-earmark-text me-2"></i>Generate Audit Report
                        </Button>
                    </div>
                </Card.Body>
            </Card>

            <AuditPreparationModal
                show={showPreparationModal}
                preparationStatus={preparationStatus}
                onHide={() => {
                    if (!preparingAudit) {
                        setShowPreparationModal(false);
                        refetch();
                    }
                }}
                onComplete={handlePreparationComplete}
            />
        </>
    );
};

export default AuditOverview;